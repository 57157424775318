<div *ngIf="!loader">
  <div class="img-wrapper">
    <div class="lable-block">
      <!-- <span class="lable3" *ngIf="product.new">new</span>
      <span class="lable4" *ngIf="product.sale">on sale</span> -->

      <!-- <span class="lable5" *ngIf="product.dateDiffrenceInHour <= 24">Early<br/>Bird</span> -->
    </div>
    <div class="front">
      <a [routerLink]="'/item/'+[product.URLKey]">
        <img 
          [defaultImage]="ImageSrc ? ImageSrc : getThumbnailImagePathLink(product.img)"
          [lazyLoad]="ImageSrc ? ImageSrc : getThumbnailImagePathLink(product.img)" 
          class="product-box-image img-fluid lazy-loading" 
          alt="{{ product.articlename }}" />
        <!-- <img 
          [defaultImage]="ImageSrc ? ImageSrc : 'assets/images/product/placeholder.jpg'" 
          [lazyLoad]="ImageSrc ? ImageSrc : product.images[0].src" 
          class="img-fluid lazy-loading" 
          alt="{{ product.images[0].alt }}" /> -->
      </a>
    </div>
    <!-- <div class="back" *ngIf="onHowerChangeImage">
      <a [routerLink]="['/product/left/sidebar/', product.articlename.replace(' ', '-')]">
        <img [src]="ImageSrc ? ImageSrc : product.images[1].src" class="img-fluid lazy-loading" alt="{{ product.images[1].alt }}">
      </a>
    </div> -->
    <!-- <ul class="product-thumb-list" *ngIf="thumbnail">
      <li class="grid_thumb_img" [class.active]="ImageSrc == image.src" *ngFor="let image of product.images">
        <a href="javascript:void(0)" (mouseover)="ChangeVariantsImage(image.src)">
          <img [lazyLoad]="image.src">
        </a>
      </li>
    </ul> -->
    <div class="cart-info cart-wrap">
      <a href="javascript:void(0)" title="Quick View" (click)="QuickView.openModal()">
        <i class="ti-eye" aria-hidden="true"></i>
        <!-- <i class="ti-search" aria-hidden="true"></i> -->
      </a>
      <!-- <a href="javascript:void(0)" title="Compare" (click)="addToCompare(product)">
        <i class="ti-reload" aria-hidden="true"></i>
      </a> -->
      <a href="javascript:void(0)" title="Add to Wishlist" (click)="addToWishlist(product)">
        <i class="ti-heart" aria-hidden="true"></i>
      </a>
      <!-- <a href="javascript:void(0)" title="Add to cart" (click)="CartModal.openModal(product)"
        *ngIf="cartModal">
        <i class="ti-shopping-cart"></i>
      </a> -->
      <!-- <a href="javascript:void(0)" title="Add to cart" (click)="addToCart(product)"
        *ngIf="!cartModal">
        <i class="ti-shopping-cart"></i>
      </a> -->
      <a href="javascript:void(0);" *ngIf="product.qty > product.sold" title="Add to cart" (click)="addToCart(product)">
        <i class="ti-shopping-cart"></i>
      </a>
    </div>
  </div>
  <div class="product-detail">
    <div class="margin-top-bottom">
      <!-- <bar-rating [rate]="5" [readOnly]="true"></bar-rating> -->
      <a [routerLink]="['/item/', product?.URLKey]" class="text-center">
        <h4>{{ getShortTitle(product?.articlename) }}</h4>
        <!-- <h6>{{ getShortTitle(product?.articlename) | titlecase }}</h6> -->
      </a>
      <p>{{ product?.description }}</p>
      <!-- <h4>
        {{ product?.price * currency?.price | discount:product | currency:currency?.currency:'symbol' }}
        <del *ngIf="product?.discount"><span class="money"> {{ product?.price * currency?.price | currency:currency?.currency:'symbol' }}</span></del>
      </h4> -->
      <!-- <h2 *ngIf="product.qty <= product.sold" class="text-center color-red">
        SOLD
      </h2> -->
      <!-- <h2 *ngIf="product.qty > product.sold" class="text-center">
        <hr class="kay-hr">
        <button class="btn btn-solid" (click)="addToCart(product)">Add to Cart</button>
      </h2> -->
      <table class="table table-striped" style="margin-bottom: unset;">
        <tbody>
          <tr *ngIf="product.qty > product.sold" style="width: 100%;">
            <td style="padding: 2px;width:50%;">
              <h4 style="font-size: 22px;">
                {{ product?.price * currency?.price | discount:product | currency:currency?.currency:'symbol' }}
                <del *ngIf="product?.discount"><span class="money" style="font-size: 16px;"> {{ product?.price * currency?.price | currency:currency?.currency:'symbol' }}</span></del>
              </h4>
            </td>
            <td style="padding: 2px;float: right;">
              <button style="border-radius: 2px;padding: 2px 5px;" class="btn btn-solid" (click)="addToCart(product)">Add to Cart</button>
            </td>
          </tr>
          <tr *ngIf="product.qty <= product.sold">
            <!-- <td style="padding: 2px;width:50%;">
              <h4 style="font-size: 22px;">
                {{ product?.price * currency?.price | discount:product | currency:currency?.currency:'symbol' }}
                <del *ngIf="product?.discount"><span class="money" style="font-size: 16px;"> {{ product?.price * currency?.price | currency:currency?.currency:'symbol' }}</span></del>
              </h4>
            </td>
            <td style="padding: 2px;float: right;">
              <h2 *ngIf="product.qty <= product.sold" style="margin-bottom: 0px;">
                <b class="color-red">SOLD</b>
              </h2>
            </td> -->
            <td colspan="2" style="padding: 2px;"> 
              <h2 *ngIf="product.qty <= product.sold" class="text-center color-red">
                SOLD
              </h2>
            </td>
          </tr>
        </tbody>
      </table>
      
      <!-- <ul class="color-variant" *ngIf="Color(product?.variants).length">
        <li [class]="color" *ngFor="let color of Color(product?.variants)" [ngStyle]="{'background-color': color}"
          (click)="ChangeVariants(color, product)">
        </li>
      </ul> -->
    </div>
  </div>
</div>
<app-skeleton-product-box *ngIf="loader"></app-skeleton-product-box>
<app-quick-view #quickView [product]="product" [currency]="currency"></app-quick-view>
<app-cart-modal #cartModal [product]="product" [currency]="currency" *ngIf="cartModal"></app-cart-modal>

