import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, startWith, toArray } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Products, CurrentDayEvents, CBBanners, ProductSeller, Categories, ProductCount, ProductImages, Cnsdata, ProductSellerCategories } from '../classes/product';

import { GoogleAnalyticsService } from '../services/google-analytics.service';

import { CookieService } from 'ngx-cookie-service';

import * as uuid from 'uuid';
import { Observable } from 'rxjs';



// define window for razorpay
function _window() : any {
  // return the global native browser window object
  return window;
}

const state = {
  products: JSON.parse(localStorage['products'] || '[]'),
  wishlist: JSON.parse(localStorage['wishlistItems'] || '[]'),
  compare: JSON.parse(localStorage['compareItems'] || '[]'),
  cart: JSON.parse(localStorage['cartItems'] || '[]')
}

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  // define native window...
  get nativeWindow() : any {
    return _window();
  }

  // public Currency = { name: 'Dollar', currency: 'USD', price: 1 } // Default Currency
  public ThemeColor = 'light'; // Default Currency
  public Currency = { name: 'Rupees', currency: 'INR', price: 1 }; // Default Currency
  public OpenCart: boolean = false;
  public Products;
  public CurrentDayEvents;
  public CBBanners;

  public live_website = "https://beta.collectorbazar.com";
  public baseUrl = 'https://betaapi.collectorbazar.com';
  
  // public baseUrl = "http://localhost:2000";
  // public live_website = "http://localhost:4400";
  // public razorpay_key_id = "rzp_live_VTdOk90misZRzg"; // for live
  public razorpay_key_id = "rzp_test_Zser9ZzQkGPrPI"; // for beta
  
  public httpOptions = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin':'*',
      'Content-Type':  'application/json',
      'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjoxLCJ1c2VybmFtZSI6ImNvbGxlY3RvcmJhemFyIiwiZW1haWwiOiJjc0Bjb2xsZWN0b3JiYXphci5jb20ifSwiaWF0IjoxNjI0ODg2NTY0fQ.nkiytP7XjS2Uu2aLS9u_hFj9YvOGgZ1330OopiuGg-c'
    })
  };

  constructor(private http: HttpClient, private toastrService: ToastrService, public cookieService: CookieService, public googleAnalyticsService: GoogleAnalyticsService) { 
    if(this.getCookie('theme-color') == 'dark' || this.getCookie('theme-color') == 'light') {
      this.ThemeColor = this.getCookie('theme-color'); 
    }
    document.body.classList.add(this.ThemeColor); // Default color
  }
  
  // public isMobileDevice() {
  //     var check = false;
  //     if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
  //       check = true;
  //     }
  //     return check;
  // };

  /*
    ---------------------------------------------
    ---------------  Product  -------------------
    ---------------------------------------------
  */
  // // Product
  // private get products(): Observable<Products[]> {
  //   this.Products = this.http.get<Products[]>('assets/data/products.json').pipe(map(data => data));
  //   this.Products.subscribe(next => { localStorage['products'] = JSON.stringify(next) });
  //   return this.Products = this.Products.pipe(startWith(JSON.parse(localStorage['products'] || '[]')));
  // }
  // // Get Products
  // public get getProducts(): Observable<Products[]> {
  //   return this.products;
  // }

  // // Get Products By Slug
  // public getProductBySlug(slug: string): Observable<Products> {
  //   return this.Products.pipe(map(items => { 
  //     return items.find((item: any) => { 
  //       return item.title.replace(' ', '-') === slug; 
  //     }); 
  //   }));
  // }

  public jsEncode(originalMsg: string){
    // let bufferObj = Buffer.from(originalMsg, "utf8");
    // let base64String = bufferObj.toString("base64");
    var encoded_text = window.btoa(originalMsg);
    encoded_text = encodeURIComponent(encoded_text);
    console.log("encoded_text:::", encoded_text);
    return encoded_text;
    // return encodeURIComponent(originalMsg);
  }

  public jsDecode(base64String: any){
    var decodedString = decodeURIComponent(base64String);
    decodedString = window.atob(decodedString);
    console.log("decode_text:::", decodedString);
    return decodedString;
    // return decodeURIComponent(base64String);
  }

  public getCustomerID() {
    let CustomerUUID = this.getCookie("CustomerUUID");
    let CustomerID = this.getCookie("CustomerID");
    let isLogin = this.getCookie("isLogin");
    // console.log(CustomerID +"=="+ CustomerUUID);
    if(isLogin == "0") {
      return CustomerUUID;
    }
    else {
      return CustomerID;
    }
  }

  /*
    ---------------------------------------------
    ---------------  Wish List  -----------------
    ---------------------------------------------
  */

  // Get Wishlist Items
  public get wishlistItems(): Observable<Products[]> {
    const itemsStream = new Observable(observer => {
      observer.next(state.wishlist);
      observer.complete();
    });
    return <Observable<Products[]>>itemsStream;
  }

  // Add to Wishlist
  public addToWishlistsNew(product: any): any {
    let CustomerID = this.getCustomerID();
    let wishlist_status = 1;
    let wishlist_data = {
      customer: CustomerID,
      product_id: product.articleno,
      wishlist_status: wishlist_status
    };
    return this.http.post(`${this.baseUrl}/customer_product_wishlist`, wishlist_data, this.httpOptions);
  }

  public addToWishlist(product): any {
    const wishlistItem = state.wishlist.find(item => item.id === product.id)
    if (!wishlistItem) {
      state.wishlist.push({
        ...product
      });

      let CustomerID = this.getCustomerID();
      let wishlist_status = 1;
      let wishlist_data = {
        customer: CustomerID,
        product_id: product.articleno,
        wishlist_status: wishlist_status
      };
      this.http.post(`${this.baseUrl}/customer_product_wishlist`, wishlist_data, this.httpOptions).subscribe((jsonWishlistResponse: any) => {
        // console.log(jsonWishlistResponse);
        if(jsonWishlistResponse.status == true) {
          // gtag start google analytics gtag
          this.googleAnalyticsService.gtagAddToWishlistMethod(product);
          // end google analytics gtag
          this.toastrService.success('Product has been added in wishlist.');
        }
        else {
          this.toastrService.error('Failed to add in wishlist !');
        }

        localStorage.setItem("wishlistItems", JSON.stringify(state.wishlist));
        return true;
      });
    }
    else {
      this.toastrService.success('Product has been added in wishlist.');
      localStorage.setItem("wishlistItems", JSON.stringify(state.wishlist));
      return true
    }
  }
  public moveToWishlist(product): any {
    const wishlistItem = state.wishlist.find(item => item.id === product.id);
    if (wishlistItem) {
      // remove item from cart
      // this.removeCartItem(product);
      this.deleteCartItem(product).subscribe((jsonDeleteResponse: any) => {
        localStorage.setItem("wishlistItems", JSON.stringify(state.wishlist));
        return true;
      });
    } else {
      this.deleteCartItem(product).subscribe((jsonDeleteResponse: any) => {
        state.wishlist.push({
          ...product
        });
  
        let CustomerID = this.getCustomerID();
        let wishlist_status = 1;
        let wishlist_data = {
          customer: CustomerID,
          product_id: product.articleno,
          wishlist_status: wishlist_status
        };
        this.http.post(`${this.baseUrl}/customer_product_wishlist`, wishlist_data, this.httpOptions).subscribe((jsonWishlistResponse: any) => {
          if(jsonWishlistResponse.status == true) {
            // gtag start google analytics gtag
            this.googleAnalyticsService.gtagAddToWishlistMethod(product);
            // end google analytics gtag
            this.toastrService.success('Product has been added in wishlist.');
          }
          else {
            this.toastrService.error('Failed to add in wishlist !');
          }
          
          // remove item from cart
          this.removeCartItem(product);
          localStorage.setItem("wishlistItems", JSON.stringify(state.wishlist));
          return true;
        });
      });
    }

    // const wishlistItem = state.wishlist.find(item => item.id === product.id);
    // console.log(wishlistItem);
    // if (!wishlistItem) {
    //   state.wishlist.push({
    //     ...product
    //   });

    //   console.log("In");
    //   let CustomerID = this.getCustomerID();
    //   let wishlist_status = 1;
    //   let wishlist_data = {
    //     customer: CustomerID,
    //     product_id: product.id,
    //     wishlist_status: wishlist_status
    //   };
    //   this.http.post(`${this.baseUrl}/customer_product_wishlist`, wishlist_data, this.httpOptions).subscribe((jsonWishlistResponse: any) => {
    //     if(jsonWishlistResponse.status == true) {
    //       this.toastrService.success('Product has been added in wishlist.');
    //     }
    //     else {
    //       this.toastrService.error('Failed to add in wishlist !');
    //     }
    //     this.removeCartItem(product);
    //     localStorage.setItem("wishlistItems", JSON.stringify(state.wishlist));
    //     return true;
    //   });
    // }
    // else {
    //   console.log("Out");
    //   this.toastrService.success('Product has been added in wishlist.');
    //   localStorage.setItem("wishlistItems", JSON.stringify(state.wishlist));
    //   return true
    // }
  }

  // Remove Wishlist items
  public removeWishlistItem(product: any): any {
    const index = state.wishlist.indexOf(product);
    console.log("index::",index);
    state.wishlist.splice(index, 1);
    console.log("state.wishlist::", state.wishlist);
    localStorage.setItem("wishlistItems", JSON.stringify(state.wishlist));
    
    let CustomerID = this.getCustomerID();
    let productId = product.articleno;
    this.http.delete(`${this.baseUrl}/customer_product_wishlist_delete_wishlist_with_customer_and_productid/${encodeURIComponent(CustomerID)}/${encodeURIComponent(productId)}`, this.httpOptions).subscribe((jsonWishlistDeleteResponse: any) => {
      console.log("jsonWishlistResponse", jsonWishlistDeleteResponse);
      if(jsonWishlistDeleteResponse.status == true) {
        this.googleAnalyticsService.gtagRemoveFromWishlistMethod(product);
        // this.toastrService.success('Product has been removed from wishlist.');
      }
      else {
        // this.toastrService.error('Failed to removed from wishlist !');
      }
      return true;
    });
  }

  /*
    ---------------------------------------------
    -------------  Compare Product  -------------
    ---------------------------------------------
  */

  // Get Compare Items
  public get compareItems(): Observable<Products[]> {
    const itemsStream = new Observable(observer => {
      observer.next(state.compare);
      observer.complete();
    });
    return <Observable<Products[]>>itemsStream;
  }

  // Add to Compare
  public addToCompare(product): any {
    const compareItem = state.compare.find(item => item.id === product.id)
    if (!compareItem) {
      state.compare.push({
        ...product
      })
    }
    this.toastrService.success('Product has been added in compare.');
    localStorage.setItem("compareItems", JSON.stringify(state.compare));
    return true
  }

  // Remove Compare items
  public removeCompareItem(product: Products): any {
    const index = state.compare.indexOf(product);
    state.compare.splice(index, 1);
    localStorage.setItem("compareItems", JSON.stringify(state.compare));
    return true
  }

  /*
    ---------------------------------------------
    -----------------  Cart  --------------------
    ---------------------------------------------
  */

  // Get Cart Items
  public get cartItems(): Observable<Products[]> {
    const itemsStream = new Observable(observer => {
      observer.next(state.cart);
      observer.complete();
    });
    return <Observable<Products[]>>itemsStream;
  }
  
  // public get cartItems(): Observable<Cnsdata[]> {
  //   let CustomerID = this.getCustomerID();
  //   return this.http.get<Cnsdata[]>(`${this.baseUrl}/cnsdata_with_customer_on_cart_page/${encodeURIComponent(CustomerID)}`, this.httpOptions);
  //   // const itemsStream = new Observable(observer => {
  //   //   observer.next(state.cart);
  //   //   observer.complete();
  //   // });
  //   // return <Observable<Cnsdata[]>>itemsStream;
  // }

  // Move to Cart
  public moveToCart(product): any {
    const cartItem = state.cart.find(item => item.id === product.id);
    const qty = product.quantity ? product.quantity : 1;
    const items = cartItem ? cartItem : product;
    
    var stock;
    if(cartItem == undefined) {
      stock = this.calculateStockCounts(items, 0);
    }
    else {
      stock = this.calculateStockCounts(items, qty);
    }
    
    if(!stock) return false

    if (cartItem) {
      cartItem.quantity += qty;

      // update if quantity is available
      let CustomerID = this.getCustomerID();
      var CartQuantityData = {
        qty: cartItem.quantity
      };
      this.http.put(`${this.baseUrl}/cnsdata_update_cart_with_customer_and_articleno/${encodeURIComponent(CustomerID)}/${encodeURIComponent(product.articleno)}`, CartQuantityData, this.httpOptions).subscribe((jsonCartUpdateResponse: any) => {
        if(jsonCartUpdateResponse.status == true) {
          // gtag start google analytics gtag
          this.googleAnalyticsService.gtagAddToCartMethod(product);
          // end google analytics gtag
          this.toastrService.success('Product has been updated in cart.');
        }
        else {
          this.toastrService.error('Failed to update in cart quantity !');
        }
        
        // remove item from cart
        this.removeWishlistItem(product);

        this.OpenCart = false; // If we use cart variation modal
        localStorage.setItem("cartItems", JSON.stringify(state.cart));
        return true;
      });
    } else {
      state.cart.push({
        ...product,
        quantity: qty
      });

      let CustomerID = this.getCustomerID();
      var price = product.discount ? product.price - (product.price * product.discount / 100) : product.price;
      let CartData = {
        ipaddress: this.getCookie("CustomerUUID"),
        customerId: CustomerID,
        dtype: "cart",
        articleno: product.articleno,
        qty: qty,
        prodid: product.id,
        descpt: product.articlename,
        curr: "INR",
        amount: price,
      };

      this.http.post(`${this.baseUrl}/cnsdata`, CartData, this.httpOptions).subscribe((jsonCartResponse: any) => {
        // console.log(jsonCartResponse);
        if(jsonCartResponse.status == true) {
          // gtag start google analytics gtag
          this.googleAnalyticsService.gtagAddToCartMethod(product);
          // end google analytics gtag
          this.toastrService.success('Product has been added in cart.');
        }
        else {
          this.toastrService.error('Failed to add in cart !');
        }
        
        // remove item from cart
        this.removeWishlistItem(product);

        // this.toastrService.success('Product has been added in cart.');
        this.OpenCart = false; // If we use cart variation modal
        localStorage.setItem("cartItems", JSON.stringify(state.cart));
        return true;
      });
    }
  }

  // Add to Cart for  occasion notes
  public addToCartOccasionNotes(product: any) {
    var isLogin = this.getCookie("isLogin");
    if(isLogin == '0') {
      window.location.href = '/customer-login';
    }
    else {
      // console.log(product);
      state.cart.push({
        ...product,
        quantity: 1
      });

      let CustomerID = this.getCustomerID();
      let CartData = {
        ipaddress: this.getCookie("CustomerIP"),
        customerId: CustomerID,
        dtype: "cart",
        articleno: product.articleno,
        qty: 1,
        prodid: product.id,
        descpt: product.articlename,
        curr: "INR",
        amount: product.price,
      };
      // gtag start google analytics gtag
      this.googleAnalyticsService.gtagAddToCartMethod(product);
      // end google analytics gtag
      localStorage.setItem("cartItems", JSON.stringify(state.cart));
      return this.http.post(`${this.baseUrl}/cnsdata`, CartData, this.httpOptions);

      // this.http.post(`${this.baseUrl}/cnsdata`, CartData, this.httpOptions).subscribe((jsonCartResponse: any) => {
      //   if(jsonCartResponse.status == true) {
      //     this.toastrService.success('Product has been added in cart.');
      //   }
      //   else {
      //     this.toastrService.error('Failed to add in cart !');
      //   }
      //   this.OpenCart = false; // If we use cart variation modal
      //   localStorage.setItem("cartItems", JSON.stringify(state.cart));
      //   return true;
      // });
    }
  }
  // Add to Cart
  public addToCart(product: any): any {
    // console.log(product);
    // let CustomerID = this.getCustomerID();
    // let articleno = product.articleno;
    // const qty = product.quantity ? product.quantity : 1;
    // var stock;
    // this.http.get<Cnsdata[]>(`${this.baseUrl}/cnsdata_with_customer_and_articleno/${encodeURIComponent(CustomerID)}/${encodeURIComponent(articleno)}`, this.httpOptions).subscribe((jsonCheckExistResponse: any) => {
    //   if(jsonCheckExistResponse.status == true) {
    //     stock = this.calculateStockCounts(product, 0);
    //   }
    //   else {
    //     stock = this.calculateStockCounts(product, qty);
    //   }

    //   if(!stock) return false;
    //   var price = product.discount ? product.price - (product.price * product.discount / 100) : product.price;
    //   let CartData = {
    //     ipaddress: this.getCookie("CustomerUUID"),
    //     customerId: CustomerID,
    //     dtype: "cart",
    //     articleno: product.articleno,
    //     qty: qty,
    //     prodid: product.id,
    //     descpt: product.articlename,
    //     curr: "INR",
    //     amount: price,
    //   };

    //   this.http.post(`${this.baseUrl}/cnsdata`, CartData, this.httpOptions).subscribe((jsonCartResponse: any) => {
    //     if(jsonCartResponse.status == true) {
    //       this.toastrService.success('Product has been added in cart.');
    //     }
    //     else {
    //       this.toastrService.error('Failed to add in cart !');
    //     }
    //     this.OpenCart = false; // If we use cart variation modal
    //     return true;
    //   });
    // });

    var isLogin = this.getCookie("isLogin");
    
    const cartItem = state.cart.find(item => item.id === product.id);
    const qty = product.quantity ? product.quantity : 1;
    const items = cartItem ? cartItem : product;
    
    var stock;
    if(cartItem == undefined) {
      stock = this.calculateStockCounts(items, 0);
    }
    else {
      stock = this.calculateStockCounts(items, qty);
    }
    
    if(!stock) return false

    if (cartItem) {
      cartItem.quantity += qty;

      // update if quantity is available
      let CustomerID = this.getCustomerID();
      var CartQuantityData = {
        qty: cartItem.quantity
      };
      this.http.put(`${this.baseUrl}/cnsdata_update_cart_with_customer_and_articleno/${encodeURIComponent(CustomerID)}/${encodeURIComponent(product.articleno)}`, CartQuantityData, this.httpOptions).subscribe((jsonCartUpdateResponse: any) => {
        if(jsonCartUpdateResponse.status == true) {
          // gtag start google analytics gtag
          this.googleAnalyticsService.gtagAddToCartMethod(product);
          // end google analytics gtag

          this.toastrService.success('Product has been updated in cart.');
        }
        else {
          this.toastrService.error('Failed to update in cart quantity !');
        }

        this.OpenCart = false; // If we use cart variation modal
        localStorage.setItem("cartItems", JSON.stringify(state.cart));

        if(isLogin == '0') {
          window.location.href = '/customer-login';
        }
        return true;
      });
    } else {
      state.cart.push({
        ...product,
        quantity: qty
      });

      let CustomerID = this.getCustomerID();
      // var price = product.discount ? product.price - (product.price * product.discount / 100) : product.price;
      var price = product.price;
      let CartData = {
        ipaddress: this.getCookie("CustomerUUID"),
        customerId: CustomerID,
        dtype: "cart",
        articleno: product.articleno,
        qty: qty,
        prodid: product.id,
        descpt: product.articlename,
        curr: "INR",
        amount: price,
      };
      this.http.post(`${this.baseUrl}/cnsdata`, CartData, this.httpOptions).subscribe((jsonCartResponse: any) => {
        // console.log(jsonCartResponse);
        if(jsonCartResponse.status == true) {
          // gtag start google analytics gtag
          this.googleAnalyticsService.gtagAddToCartMethod(product);
          // end google analytics gtag
          this.toastrService.success('Product has been added in cart.');
        }
        else {
          this.toastrService.error('Failed to add in cart !');
        }
        // this.toastrService.success('Product has been added in cart.');
        this.OpenCart = false; // If we use cart variation modal
        localStorage.setItem("cartItems", JSON.stringify(state.cart));
        
        if(isLogin == '0') {
          window.location.href = '/customer-login';
        }
        return true;
      });
    }
  }

  // Update Cart Quantity
  public updateCartQtyOnDB(articleno: string, quantity: number) {
    let CustomerID = this.getCustomerID();
    var CartQuantityData = {
      qty: quantity
    };
    return this.http.put(`${this.baseUrl}/cnsdata_update_cart_qty_on_single_update/${encodeURIComponent(CustomerID)}/${encodeURIComponent(articleno)}/${quantity}`, CartQuantityData, this.httpOptions);
  }

  public updateCartQuantity(product: Products, quantity: number): Products | boolean {
    return state.cart.find((items, index) => {
      if (items.id === product.id) {
        const qty = state.cart[index].quantity + quantity
        const stock = this.calculateStockCounts(state.cart[index], quantity)
        if (qty !== 0 && stock) {
          state.cart[index].quantity = qty;

          let CustomerID = this.getCustomerID();
          var CartQuantityData = {
            qty: qty
          };
          this.http.put(`${this.baseUrl}/cnsdata_update_cart_with_customer_and_articleno/${encodeURIComponent(CustomerID)}/${encodeURIComponent(product.articleno)}`, CartQuantityData, this.httpOptions).subscribe((jsonCartUpdateResponse: any) => {
            if(jsonCartUpdateResponse.status == true) {
              localStorage.setItem("cartItems", JSON.stringify(state.cart));
              return true;
            }
          });
        }
        else {
          localStorage.setItem("cartItems", JSON.stringify(state.cart));
          return true
        }
      }
    })
  }

    // Calculate Stock Counts
  public calculateStockCounts(product, quantity) {
    const qty = product.quantity + quantity;
    const stock = product.qty - product.sold;
    if (stock < qty || stock == 0) {
      this.toastrService.error('You can not add more items than available. In stock '+ stock +' items.');
      return false
    }
    return true
  }

  // Remove Cart items
  public deleteCartItem(product: Products): any {
    let CustomerID = this.getCustomerID();
    return this.http.delete(`${this.baseUrl}/cnsdata_remove_cart_with_customer_and_articleno/${encodeURIComponent(CustomerID)}/${encodeURIComponent(product.articleno)}`, this.httpOptions);
  }
  public removeCartItem(product: Products): any {
    let CustomerID = this.getCustomerID();
    this.http.delete(`${this.baseUrl}/cnsdata_remove_cart_with_customer_and_articleno/${encodeURIComponent(CustomerID)}/${encodeURIComponent(product.articleno)}`, this.httpOptions).subscribe((jsonCartDeleteResponse: any) => {
      const index = state.cart.indexOf(product);
      state.cart.splice(index, 1);
      localStorage.setItem("cartItems", JSON.stringify(state.cart));
      if(jsonCartDeleteResponse.status == true) {
        this.googleAnalyticsService.gtagRemoveFromCartMethod(product);
        return true;
      }
      else {
        return false;
      }
    });
  }

  // Total amount 
  public cartTotalAmount(): Observable<number> {
    // return this.cartItems.pipe(map((cnsdata: Cnsdata[]) => {
    //   return cnsdata.reduce((prev, curr: Cnsdata) => {
    //     let price = curr.amount;
    //     // if(curr.discount) {
    //     //   price = curr.price - (curr.price * curr.discount / 100)
    //     // }
    //     return (prev + price * curr.qty) * this.Currency.price;
    //   }, 0);
    // }));

    return this.cartItems.pipe(map((product: Products[]) => {
      return product.reduce((prev, curr: any) => {
        // console.log(curr);
        let price = curr.price;
        if(curr.discount) {
          price = curr.price - (curr.price * curr.discount / 100)
        }
        return prev + ((price * curr.quantity) * this.Currency.price);
      }, 0);
    }));
  }

  // Sub Total amount 
  public cartSubTotalAmount(): Observable<number> {
    return this.cartItems.pipe(map((product: Products[]) => {
      return product.reduce((prev, curr: any) => {
        // console.log(curr);
        let price = curr.price;
        if(curr.discount) {
          price = curr.price - (curr.price * curr.discount / 100)
        }
        return prev + (price * curr.quantity);
      }, 0);
    }));
  }

  /*
    ---------------------------------------------
    ------------  Filter Product  ---------------
    ---------------------------------------------
  */
  // // Get Product Filter
  // public filterProducts(filter: any): Observable<Products[]> {
  //   return this.Products.pipe(map(product => 
  //     product.filter((item: Products) => {
  //       if (!filter.length) return true
  //       const Tags = filter.some((prev) => { // Match Tags
  //         if (item.seller) {
  //           if (item.seller.includes(prev)) {
  //             return prev
  //           }
  //         }
  //       });
  //       return Tags
  //     })
  //   ));
  // }
  
  // // Get Sellers Products Filter
  // public filterProductsBySeller(filterTag: any): Observable<Products[]> {
  //   return this.Products.pipe(map(product => 
  //     product.filter((item: Products) => {
  //       if (!filter.length) return true
  //       const Tags = filter.some((prev) => { // Match Tags
  //         if (item.seller) {
  //           if (item.seller.includes(prev)) {
  //             return prev
  //           }
  //         }
  //       });
  //       return Tags
  //     })
  //   ));
  // }

  // Sorting Filter
  public sortProducts(products: Products[], payload: string): any {

    if(payload === 'ascending') {
      return products.sort((a, b) => {
        if (a.id < b.id) {
          return -1;
        } else if (a.id > b.id) {
          return 1;
        }
        return 0;
      })
    } else if (payload === 'a-z') {
      return products.sort((a, b) => {
        if (a.articlename < b.articlename) {
          return -1;
        } else if (a.articlename > b.articlename) {
          return 1;
        }
        return 0;
      })
    } else if (payload === 'z-a') {
      return products.sort((a, b) => {
        if (a.articlename > b.articlename) {
          return -1;
        } else if (a.articlename < b.articlename) {
          return 1;
        }
        return 0;
      })
    } else if (payload === 'low') {
      return products.sort((a, b) => {
        if (a.price < b.price) {
          return -1;
        } else if (a.price > b.price) {
          return 1;
        }
        return 0;
      })
    } else if (payload === 'high') {
      return products.sort((a, b) => {
        if (a.price > b.price) {
          return -1;
        } else if (a.price < b.price) {
          return 1;
        }
        return 0;
      })
    } 
  }

  /*
    ---------------------------------------------
    ------------- Product Pagination  -----------
    ---------------------------------------------
  */
  public getPager(totalItems: number, currentPage: number = 1, pageSize: number = 30) {
    // calculate total pages
    let totalPages: number = Math.ceil(totalItems / pageSize);

    // Paginate Range
    let paginateRange: number = 5;

    // ensure current page isn't out of range
    if (currentPage < 1) { 
      currentPage = 1; 
    } else if (currentPage > totalPages) { 
      currentPage = totalPages; 
    }
    
    let startPage: number, endPage: number;
    if (totalPages <= 5) {
      startPage = 1;
      endPage = totalPages;
    } else if(currentPage < paginateRange - 1){
      startPage = 1;
      endPage = startPage + paginateRange - 1;
    } else {
      startPage = currentPage - 2;
      endPage =  currentPage + 2;
    }

    // calculate start and end item indexes
    let startIndex: number = (currentPage - 1) * pageSize;
    let endIndex: number = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    let pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);
    
    // return object with all pager properties required by the view
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages
    };
  }


  // below services written by avnish yadav
  checkAndSetCustomerUUID() {
    var CustomerUUID = this.getCookie("CustomerUUID");
    if(CustomerUUID == undefined || CustomerUUID == null || CustomerUUID.length <= 0) {
      const my_uuid = uuid.v4();
      this.setCookie("CustomerUUID", my_uuid, 30);
    }
    
    var CustomerID = this.getCookie("CustomerID");
    if(CustomerID == undefined || CustomerID == null || CustomerID.length == 0) {
      this.setCookie("isLogin", '0', 30);
    }
  }
  
  getCustomerIP() {
    var CustomerIP = this.getCookie("CustomerIP");
    if(CustomerIP == undefined || CustomerIP == null || CustomerIP.length == 0) {
      this.http.get("https://api.ipify.org/?format=json").subscribe((value: any) => {
        this.setCookie("CustomerIP", value.ip, 30);
      });
    }
  }
  public setCookie(name: string, value: string, daysToLive: number) {
    // const loc = window.location;
    // if (loc.hostname === 'resources.collectorbazar.com' || loc.hostname === 'auction.collectorbazar.com') {
    //   this.cookieService.set(name, value, null, '/', '.collectorbazar.com', true);
    // } else {
    //   this.cookieService.set(name, value, null, '/', '.collectorbazar.com', true);
    // }
    // return true;

    localStorage.setItem(name, value);
    return true;
  }
  public getCookie(name: string) {
    // const cookieExists: boolean = this.cookieService.check(name);
    // if(cookieExists) {
    //   return this.cookieService.get(name);
    // }
    // else {
    //   return undefined;
    // }


    // old localstorage logic
    let value = localStorage.getItem(name);
    return value;
  }
  
  public deleteCookie(name: string) {
    // this.cookieService.delete(name, '/', '.collectorbazar.com');

    localStorage.removeItem(name);

    return true;
  }

  // public checkLoginUser() {
  //   return this.http.get(`${this.baseUrl}/check_user_login_session`, this.httpOptions);
  // }
  // public saveUserLoginSession(CustomerID: string, CustomerName: string, CustomerEmail: string, CustomerCountry: string, CustomerIsBlogUser: string) {
  //   return this.http.get(`${this.baseUrl}/save_user_login_session/${encodeURIComponent(CustomerID)}/${encodeURIComponent(CustomerName)}/${encodeURIComponent(CustomerEmail)}/${encodeURIComponent(CustomerCountry)}/${encodeURIComponent(CustomerIsBlogUser)}`, this.httpOptions);
  // }

  public getProductLink(URLKey: string) {
    return `/item/${URLKey}`;
  }
  
  public getShortTitle(title: string) {
    if(title.length > 80) {
      return title.slice(0, 80)+"...";
    } 
    else {
      return title;
    }
  }

  public getIndianFormateDateTime(DateTime: string) {
    let current_date_time = new Date(DateTime);
    let year = current_date_time.getFullYear();
    let month: string | number = current_date_time.getMonth() + 1;
    if(month <= 9) {
      month = "0"+month;
    }
    let day: string | number = current_date_time.getDate();
    if(day <= 9) {
      day = "0"+day;
    }
    let hours: string | number = current_date_time.getHours();
    let minutes: string | number = current_date_time.getMinutes();
    let seconds: string | number = current_date_time.getSeconds();

    if(hours <= 9) {
      hours = "0"+hours;
    }
    if(minutes <= 9) {
      minutes = "0"+minutes;
    }
    if(seconds <= 9) {
      seconds = "0"+seconds;
    }
    var CurrentDateTime_YYYY_MM_DD = year+"-"+month+"-"+day+" "+hours+":"+minutes+":"+seconds;
    return CurrentDateTime_YYYY_MM_DD;
  }

  logoutCustomer() {
    this.setCookie("isLogin", "0", 30);
    this.deleteCookie("CustomerID");
    this.deleteCookie("CustomerName");
    this.deleteCookie("CustomerEmail");
    this.deleteCookie("CustomerCountry");
    this.deleteCookie("CustomerIsBlogUser");
    localStorage.setItem("cartItems", JSON.stringify([]));
    window.location.href = "https://auction.collectorbazar.com/customer-login-verification?isLogin=0";
    // window.location.href = '/';
  }

  public getThumbnailImagePathLink(img: string) {
    return `https://cdn.collectorbazar.com/productst/${img}`;
  }
  public getActualImagePathLink(img: string) {
    return `https://cdn.collectorbazar.com/products/${img}`;
  }
  public getAssetsFullLink(link: string) {
    return `https://cdna.collectorbazar.com${link}`;
  }
  public getCurrentDayEventImageLink(link: string) {
    return `https://cdna.collectorbazar.com${link}`;
  }

  public getCurrentDate() {
    let current_date_time = new Date();
    let year = current_date_time.getFullYear();
    let month: string | number = current_date_time.getMonth() + 1;
    if(month <= 9) {
      month = "0"+month;
    }
    let day: string | number = current_date_time.getDate();
    if(day <= 9) {
      day = "0"+day;
    }
    var CurrentDate_DD_MM_YYYY = day+"-"+month+"-"+year;
    return CurrentDate_DD_MM_YYYY;
  }

  public getDateTime() {
    let current_date_time = new Date();
    let year = current_date_time.getFullYear();
    let month: string | number = current_date_time.getMonth() + 1;
    if(month <= 9) {
      month = "0"+month;
    }
    let day: string | number = current_date_time.getDate();
    if(day <= 9) {
      day = "0"+day;
    }
    let hours: string | number = current_date_time.getHours();
    let minutes: string | number = current_date_time.getMinutes();
    let seconds: string | number = current_date_time.getSeconds();

    if(hours <= 9) {
      hours = "0"+hours;
    }
    if(minutes <= 9) {
      minutes = "0"+minutes;
    }
    if(seconds <= 9) {
      seconds = "0"+seconds;
    }
    var CurrentDateTime_DD_MM_YYYY = day+"-"+month+"-"+year+" "+hours+":"+minutes+":"+seconds;
    // var CurrentDateTime_YYYY_MM_DD = year+"-"+month+"-"+day+" "+hours+":"+minutes+":"+seconds;
    return CurrentDateTime_DD_MM_YYYY;
  }

  public getTransactionDateTimeForDB() {
    let current_date_time = new Date();
    let year = current_date_time.getFullYear();
    let month: string | number = current_date_time.getMonth() + 1;
    if(month <= 9) {
      month = "0"+month;
    }
    let day: string | number = current_date_time.getDate();
    if(day <= 9) {
      day = "0"+day;
    }
    let hours: string | number = current_date_time.getHours();
    let minutes: string | number = current_date_time.getMinutes();
    let seconds: string | number = current_date_time.getSeconds();

    if(hours <= 9) {
      hours = "0"+hours;
    }
    if(minutes <= 9) {
      minutes = "0"+minutes;
    }
    if(seconds <= 9) {
      seconds = "0"+seconds;
    }
    // var CurrentDateTime_DD_MM_YYYY = day+"-"+month+"-"+year+" "+hours+":"+minutes+":"+seconds;
    var CurrentDateTime_YYYY_MM_DD = year+"-"+month+"-"+day+" "+hours+":"+minutes+":"+seconds;
    return CurrentDateTime_YYYY_MM_DD;
  }

  public getIndianFormateDate(StringDate: string) {
    let current_date_time = new Date(StringDate);
    let year = current_date_time.getFullYear();
    let month: string | number = current_date_time.getMonth() + 1;
    if(month <= 9) {
      month = "0"+month;
    }
    let day: string | number = current_date_time.getDate();
    if(day <= 9) {
      day = "0"+day;
    }
    var CurrentDateTime_YYYY_MM_DD = day+"-"+month+"-"+year;
    return CurrentDateTime_YYYY_MM_DD;
  }

  hideMobileNumber(str: string)
  {
      var pattern = /([0-9]+[\- ]?[0-9]+){4,}/;
      var replacement = "[-Mobile Number removed-]";
      var final_string = str.replace(pattern, replacement);
      return final_string;
  }

  hideEmail(str: string)
  {
      var pattern = /[^@\s]*@[^@\s]*\.[^@\s]*/;
      var replacement = "[-Email removed-]";
      var final_string = str.replace(pattern, replacement);
      return final_string;
  }

  SendCounterOfferToSeller(counter_offer_id: string, articleno: string, sellerid: string, sellername: string, actual_price: number, amount: number, counter_offer_status: string) {
    let customer = this.getCustomerID();
    if(amount > 0)
    {
      let counter_offer_data = {
        counter_offer_id: counter_offer_id,
        customer_id: customer,
        seller_id: sellerid,
        seller_name: sellername,
        articleno: articleno,
        actual_price: actual_price,
        previous_counter_price: amount,
        current_counter_price: amount,
        counter_offer_status: counter_offer_status,
        remarks: "Counter Offer",
        created_by: "customer"
      };
      return this.http.post(`${this.baseUrl}/cb_counter_offers_add`, counter_offer_data, this.httpOptions);
    }
    else
    {
      return null;
    }
  }

  updateCounterOfferFromCustomer(status: string, id: string, articleno: string, sellerid: string, sellername: string, actual_price: number, previous_counter_price: number, current_counter_price: number, amount: number) {
    let customer = this.getCustomerID();
    if(amount > 0)
    {
      let counter_offer_data = {
        id: id,
        counter_offer_id: false,
        customer_id: customer,
        seller_id: sellerid,
        seller_name: sellername,
        articleno: articleno,
        actual_price: actual_price,
        previous_counter_price: current_counter_price,
        current_counter_price: amount,
        counter_offer_status: status,
        remarks: "Counter Offer"
      };
      console.log("counter_offer_data", counter_offer_data);
      return this.http.post(`${this.baseUrl}/cb_counter_offers_add`, counter_offer_data, this.httpOptions);
    }
    else
    {
      return null;
    }
  }

  sendMessageToSeller(articleno: string, seller: string, msg: any) {
    let customer = this.getCustomerID();
    if(msg.trim() != "")
    {
      var msg1 = msg.replaceAll('"', '``');
      var originalMsg = msg1.replaceAll("'", "`");
      var displayMsg = this.hideEmail(this.hideMobileNumber(originalMsg));
      let UserMessageData = {
        senderID: customer,
        senderType: 'Customer',
        originalMsg: originalMsg,
        displayMsg: displayMsg,
        msgDateTime: this.getTransactionDateTimeForDB(),
        recieverID: seller,
        recieverType: "Seller",
        msgType: "Product Details",
        articalno: articleno,
        orderid: "",
        referenceMsgID: "",
        status: "1"
      };
      return this.http.post(`${this.baseUrl}/customer_seller_chats`, UserMessageData, this.httpOptions);
    }
    else
    {
      return null;
    }
  }

  sendMessageToSellerFromMyOrders(orderid: string, seller: string, msg: any) {
    let customer = this.getCookie("CustomerID");
    if(msg.trim() != "")
    {
      var msg1 = msg.replaceAll('"', '``');
      var originalMsg = msg1.replaceAll("'", "`");
      var displayMsg = this.hideEmail(this.hideMobileNumber(originalMsg));
      let UserMessageData = {
        senderID: customer,
        senderType: 'Customer',
        originalMsg: originalMsg,
        displayMsg: displayMsg,
        msgDateTime: this.getDateTime(),
        recieverID: seller,
        recieverType: "Seller",
        msgType: "Product Details",
        articalno: "",
        orderid: orderid,
        referenceMsgID: "",
        status: "1"
      };
      return this.http.post(`${this.baseUrl}/customer_seller_chats`, UserMessageData, this.httpOptions);
    }
    else
    {
      return null;
    }
  }

  // all sms function here
  public sendSMSSellerOrderRecieved(mobile_number: any, orderid: string) {
    return this.http.get(`${this.baseUrl}/collectorbazar_send_sms/SellerOrderRecieved/${mobile_number}/${orderid}`, this.httpOptions);
  }
  public sendSMSBuyerOrderRecieved(mobile_number: any, orderid: string) {
    return this.http.get(`${this.baseUrl}/collectorbazar_send_sms/BuyerOrderRecieved/${mobile_number}/${orderid}`, this.httpOptions);
  }
  

  public sendEmail(from_email: any, from_name: any, to_email: any, to_name: any, email_subject: any, body_html: any)
  {
    // send email via api
    var EmailPostData = {
        fromEmail: encodeURIComponent(from_email),
        fromName: encodeURIComponent(from_name),
        toEmail: encodeURIComponent(to_email),
        toName: encodeURIComponent(to_name),
        subject: encodeURIComponent(email_subject),
        bodyHtml: encodeURIComponent(body_html)
    };

    this.http.post(`${this.baseUrl}/collectorbazar_send_email`, EmailPostData, this.httpOptions).subscribe((jsonResponse: any) => {
      if(jsonResponse.status == true) {
        return true;
      }
      else {
        return false;
      }
    });
  }

  getLastHourAddedProductCount() {
    return this.http.get(`${this.baseUrl}/product_get_last_hour_added_product_count`, this.httpOptions);
  }

  // Get Sellers Products Filter
  public filterProductsBySeller(filterTag: any): Observable<Products[]> {
    return this.Products;
    // return this.Products.pipe(map(product => 
    //   product.filter((item: Products) => {
    //     if (!filter.length) return true
    //     const Tags = filter.some((prev) => { // Match Tags
    //       if (item.seller) {
    //         if (item.seller.includes(prev)) {
    //           return prev
    //         }
    //       }
    //     });
    //     return Tags
    //   })
    // ));
  }

  public getAllRandomProduct(CustomerID: string): Observable<Products[]> {
    return this.http.get<Products[]>(`${this.baseUrl}/product_random/${encodeURIComponent(CustomerID)}/0/30`, this.httpOptions);
    
    // this.Products = this.http.get<Products[]>(`${this.baseUrl}/product_random/${encodeURIComponent(CustomerID)}/0/50`, this.httpOptions).pipe(map(data => data));
    // this.Products.subscribe(next => { localStorage['products'] = JSON.stringify(next) });
    // return this.Products = this.Products.pipe(startWith(JSON.parse(localStorage['products'] || '[]')));
  }
  
  public getCurrentDayEventWithDayAndMonth(DayNo: any, MonthNo: any): Observable<CurrentDayEvents[]> {
    return this.http.get<CurrentDayEvents[]>(`${this.baseUrl}/current_day_event_all_with_day_and_month/${encodeURIComponent(DayNo)}/${encodeURIComponent(MonthNo)}`, this.httpOptions);
    // this.CurrentDayEvents = this.http.get<CurrentDayEvents[]>(`${this.baseUrl}/current_day_event_all_with_day_and_month/${encodeURIComponent(DayNo)}/${encodeURIComponent(MonthNo)}`, this.httpOptions).pipe(map(data => data));
    // this.CurrentDayEvents.subscribe(next => { localStorage['current_day_events'] = JSON.stringify(next) });
    // return this.CurrentDayEvents = this.CurrentDayEvents.pipe(startWith(JSON.parse(localStorage['current_day_events'] || '[]')));
  }

  getCBBanner(page: any, status: any): Observable<CBBanners[]> {
    return this.http.get<CBBanners[]>(`${this.baseUrl}/cb_banner_with_page_and_status/${encodeURIComponent(page)}/${encodeURIComponent(status)}`, this.httpOptions).pipe(map(data => data));
    // this.CBBanners = this.http.get<CBBanners[]>(`${this.baseUrl}/cb_banner_with_page_and_status/${encodeURIComponent(page)}/${encodeURIComponent(status)}`, this.httpOptions).pipe(map(data => data));
    // this.CBBanners.subscribe(next => { localStorage['cb_banners'] = JSON.stringify(next) });
    // return this.CBBanners = this.CBBanners.pipe(startWith(JSON.parse(localStorage['cb_banners'] || '[]')));
  }


  // category services
  public getAllUniqueSellersFromProductsWithCatID(CatID: string, filterBy: string): Observable<ProductSeller[]> {
    filterBy = '6';
    return this.http.get<ProductSeller[]>(`${this.baseUrl}/product_get_all_unique_sellers/${encodeURIComponent(CatID)}/${encodeURIComponent(filterBy)}`, this.httpOptions);
  }
  public loadCategoryPage(furl: any): Observable<Categories[]> {
    return this.http.get<Categories[]>(`${this.baseUrl}/categories_data_from_furl/${encodeURIComponent(furl)}`, this.httpOptions);
  }


  public loadCategoryFilterSellerWisePaginationData(cid: string, SortBy: number, FilterByType: number, FilterByPageSize: number, StartLimit: number, CustomerID: string, selectedSellers: any): Observable<ProductCount[]> {
    FilterByType = 6;
    return this.http.get<ProductCount[]>(`${this.baseUrl}/product_only_category_wise_filter_sellers_listing_products_pagination_data/${encodeURIComponent(cid)}/${SortBy}/${FilterByType}/${FilterByPageSize}/${StartLimit}/${encodeURIComponent(selectedSellers)}`, this.httpOptions);
  }
  public loadProductCategoryFilterSellerWiseData(cid: string, SortBy: number, FilterByType: number, FilterByPageSize: number, StartLimit: number, CustomerID: string, selectedSellers: any): Observable<Products[]> {
    FilterByType = 6;
    return this.http.get<Products[]>(`${this.baseUrl}/product_only_category_wise_filter_sellers_listing_product_data/${encodeURIComponent(cid)}/${encodeURIComponent(SortBy)}/${encodeURIComponent(FilterByType)}/${encodeURIComponent(FilterByPageSize)}/${encodeURIComponent(StartLimit)}/${encodeURIComponent(CustomerID)}/${encodeURIComponent(selectedSellers)}`, this.httpOptions);
    
    // this.Products = this.http.get<Products[]>(`${this.baseUrl}/product_only_category_wise_filter_sellers_listing_product_data/${encodeURIComponent(cid)}/${encodeURIComponent(SortBy)}/${encodeURIComponent(FilterByType)}/${encodeURIComponent(FilterByPageSize)}/${encodeURIComponent(StartLimit)}/${encodeURIComponent(CustomerID)}/${encodeURIComponent(selectedSellers)}`, this.httpOptions).pipe(map(data => data));
    // this.Products.subscribe(next => { localStorage['products'] = JSON.stringify(next) });
    // return this.Products = this.Products.pipe(startWith(JSON.parse(localStorage['products'] || '[]')));
  }

  public loadCategoryWisePaginationData(cid: string, SortBy: number, FilterByType: number, FilterByPageSize: number, StartLimit: number, CustomerID: string): Observable<ProductCount[]> {
    FilterByType = 6;
    return this.http.get<ProductCount[]>(`${this.baseUrl}/product_only_category_wise_filter_products_pagination_data/${encodeURIComponent(cid)}/${SortBy}/${FilterByType}/${FilterByPageSize}/${StartLimit}`, this.httpOptions);
  }
  public loadProductCategoryWiseData(cid: string, SortBy: number, FilterByType: number, FilterByPageSize: number, StartLimit: number, CustomerID: string): Observable<Products[]> {
    FilterByType = 6;
    return this.http.get<Products[]>(`${this.baseUrl}/product_only_category_wise_filter_product_data/${encodeURIComponent(cid)}/${encodeURIComponent(SortBy)}/${encodeURIComponent(FilterByType)}/${encodeURIComponent(FilterByPageSize)}/${encodeURIComponent(StartLimit)}/${encodeURIComponent(CustomerID)}`, this.httpOptions);
    
    // this.Products = this.http.get<Products[]>(`${this.baseUrl}/product_only_category_wise_filter_product_data/${encodeURIComponent(cid)}/${encodeURIComponent(SortBy)}/${encodeURIComponent(FilterByType)}/${encodeURIComponent(FilterByPageSize)}/${encodeURIComponent(StartLimit)}/${encodeURIComponent(CustomerID)}`, this.httpOptions).pipe(map(data => data));
    // this.Products.subscribe(next => { localStorage['products'] = JSON.stringify(next) });
    // return this.Products = this.Products.pipe(startWith(JSON.parse(localStorage['products'] || '[]')));
  }

  public loadSellerWithCategoryWiseData(seller: string, seller_category_id: any, SortBy: number, FilterByType: number, FilterByPageSize: number, StartLimit: number, CustomerID: string, csearch: string): Observable<Products[]> {
    FilterByType = 6;
    if(csearch == "" || csearch == undefined || csearch == null)
    {
      csearch = "-1";
    }
    return this.http.get<Products[]>(`${this.baseUrl}/product_seller_and_category_wise_filter_product_data/${encodeURIComponent(seller)}/${encodeURIComponent(seller_category_id)}/${encodeURIComponent(SortBy)}/${encodeURIComponent(FilterByType)}/${encodeURIComponent(FilterByPageSize)}/${encodeURIComponent(StartLimit)}/${encodeURIComponent(csearch)}/${encodeURIComponent(CustomerID)}`, this.httpOptions);
  }

  public loadSellerWithCategoryWisePaginationData(seller: string, seller_category_id: any, SortBy: number, FilterByType: number, FilterByPageSize: number, StartLimit: number, CustomerID: string, csearch: string): Observable<ProductCount[]> {
    FilterByType = 6;
    if(csearch == "" || csearch == undefined || csearch == null)
    {
      csearch = "-1";
    }
    return this.http.get<ProductCount[]>(`${this.baseUrl}/product_seller_and_category_wise_filter_product_data_for_pagination/${encodeURIComponent(seller)}/${encodeURIComponent(seller_category_id)}/${SortBy}/${FilterByType}/${FilterByPageSize}/${StartLimit}/${encodeURIComponent(csearch)}/${encodeURIComponent(CustomerID)}`, this.httpOptions);
  }

  public loadSellerProductWisePaginationData(cid: string, SortBy: number, FilterByType: number, FilterByPageSize: number, StartLimit: number, CustomerID: string, csearch: string): Observable<Products[]> {
    FilterByType = 6;
    if(csearch == "")
    {
      csearch = "-1";
    }
    return this.http.get<Products[]>(`${this.baseUrl}/product_only_seller_wise_filter_product_for_pagination_data/${encodeURIComponent(cid)}/${encodeURIComponent(SortBy)}/${encodeURIComponent(FilterByType)}/${encodeURIComponent(FilterByPageSize)}/${encodeURIComponent(StartLimit)}/${encodeURIComponent(csearch)}`, this.httpOptions);
    
    // this.Products = this.http.get<Products[]>(`${this.baseUrl}/product_only_seller_wise_filter_product_for_pagination_data/${encodeURIComponent(cid)}/${encodeURIComponent(SortBy)}/${encodeURIComponent(FilterByType)}/${encodeURIComponent(FilterByPageSize)}/${encodeURIComponent(StartLimit)}/${encodeURIComponent(csearch)}`, this.httpOptions).pipe(map(data => data));
    // this.Products.subscribe(next => { localStorage['products'] = JSON.stringify(next) });
    // return this.Products = this.Products.pipe(startWith(JSON.parse(localStorage['products'] || '[]')));
  }

  public loadProductSellerWiseData(cid: string, SortBy: number, FilterByType: number, FilterByPageSize: number, StartLimit: number, CustomerID: string, csearch: string): Observable<Products[]> {
    FilterByType = 6;
    if(csearch == "")
    {
      csearch = "-1";
    }
    return this.http.get<Products[]>(`${this.baseUrl}/product_only_seller_wise_filter_product_data/${encodeURIComponent(cid)}/${encodeURIComponent(SortBy)}/${encodeURIComponent(FilterByType)}/${encodeURIComponent(FilterByPageSize)}/${encodeURIComponent(StartLimit)}/${encodeURIComponent(csearch)}/${encodeURIComponent(CustomerID)}`, this.httpOptions);
    
    // this.Products = this.http.get<Products[]>(`${this.baseUrl}/product_only_seller_wise_filter_product_data/${encodeURIComponent(cid)}/${encodeURIComponent(SortBy)}/${encodeURIComponent(FilterByType)}/${encodeURIComponent(FilterByPageSize)}/${encodeURIComponent(StartLimit)}/${encodeURIComponent(csearch)}/${encodeURIComponent(CustomerID)}`, this.httpOptions).pipe(map(data => data));
    // this.Products.subscribe(next => { localStorage['products'] = JSON.stringify(next) });
    // return this.Products = this.Products.pipe(startWith(JSON.parse(localStorage['products'] || '[]')));
    // // return this.http.get(`${this.baseUrl}/product_only_seller_wise_filter_product_data/${encodeURIComponent(cid)}/${SortBy}/${FilterByType}/${FilterByPageSize}/${StartLimit}/${encodeURIComponent(csearch)}/${CustomerID}`, this.httpOptions);
  }

  public loadLeftSideCategoryData(type: string, value: string): Observable<Categories[]> {
    if(type == "category") {
      return this.http.get<Categories[]>(`${this.baseUrl}/categories_data_from_parent_id/${encodeURIComponent(value)}`, this.httpOptions);
    }
    else {
      return this.http.get<Categories[]>(`${this.baseUrl}/categories_data_from_seller/${encodeURIComponent(value)}`, this.httpOptions);
    }
  }
  public getAllSubCategoriesFromParentCategory(catid: string, filterBy: string) {
    filterBy = '6';
    return this.http.get<Categories[]>(`${this.baseUrl}/product_get_all_sub_categories_from_parent_category/${encodeURIComponent(catid)}/${encodeURIComponent(filterBy)}`, this.httpOptions);
  }

  // search page
  public getCategoryIDByCatName(cat_name: string): Observable<Categories[]> {
    return this.http.get<Categories[]>(`${this.baseUrl}/categories_get_catid_with_cat_name/${encodeURIComponent(cat_name)}`, this.httpOptions);
  }

  public getAllUniqueSellersFromProductsWithSearchKeywords(cat_name: string, sq: string): Observable<ProductSeller[]> {
    return this.http.get<ProductSeller[]>(`${this.baseUrl}/product_get_all_unique_sellers_with_search_text/${encodeURIComponent(cat_name)}/${encodeURIComponent(sq)}`, this.httpOptions);
  }

  loadProductSearchPaginationData(search_cat: string, sq: string, seller: string, SortBy: number, FilterByType: number, FilterByPageSize: number, StartLimit: number, CustomerID: string): Observable<Products[]> {
    FilterByType = 6;
    return this.http.get<Products[]>(`${this.baseUrl}/product_search_page_filter_product_pagination_data/${encodeURIComponent(search_cat)}/${encodeURIComponent(sq)}/${encodeURIComponent(seller)}/${SortBy}/${FilterByType}/${FilterByPageSize}/${StartLimit}`, this.httpOptions);
  }

  loadProductSearchData(search_cat: string, sq: string, seller: string, SortBy: number, FilterByType: number, FilterByPageSize: number, StartLimit: number, CustomerID: string): Observable<Products[]> {
    FilterByType = 6;
    return this.http.get<Products[]>(`${this.baseUrl}/product_search_page_filter_product_data/${encodeURIComponent(search_cat)}/${encodeURIComponent(sq)}/${encodeURIComponent(seller)}/${SortBy}/${FilterByType}/${FilterByPageSize}/${StartLimit}/${CustomerID}`, this.httpOptions);
    
    // this.Products = this.http.get<Products[]>(`${this.baseUrl}/product_search_page_filter_product_data/${encodeURIComponent(search_cat)}/${encodeURIComponent(sq)}/${SortBy}/${FilterByType}/${FilterByPageSize}/${StartLimit}/${CustomerID}`, this.httpOptions).pipe(map(data => data));
    // this.Products.subscribe(next => { localStorage['products'] = JSON.stringify(next) });
    // return this.Products = this.Products.pipe(startWith(JSON.parse(localStorage['products'] || '[]')));
    // // return this.http.get(`${this.baseUrl}/product_search_page_filter_product_data/${encodeURIComponent(search_cat)}/${encodeURIComponent(sq)}/${SortBy}/${FilterByType}/${FilterByPageSize}/${StartLimit}/${CustomerID}`, this.httpOptions);
  }
  
  loadSearchFilteredBySellerPaginationData(search_cat: string, sq: string, SortBy: number, FilterByType: number, FilterByPageSize: number, StartLimit: number, CustomerID: string, selectedSellers: any): Observable<ProductCount[]> {
    FilterByType = 6;
    return this.http.get<ProductCount[]>(`${this.baseUrl}/product_search_page_sellers_filtered_option_product_pagination_data/${encodeURIComponent(search_cat)}/${encodeURIComponent(sq)}/${SortBy}/${FilterByType}/${FilterByPageSize}/${StartLimit}/${encodeURIComponent(selectedSellers)}`, this.httpOptions);
  }

  loadProductSearchFilteredBySellerData(search_cat: string, sq: string, SortBy: number, FilterByType: number, FilterByPageSize: number, StartLimit: number, CustomerID: string, selectedSellers: any): Observable<Products[]> {
    FilterByType = 6;
    return this.http.get<Products[]>(`${this.baseUrl}/product_search_page_sellers_filtered_option_product_data/${encodeURIComponent(search_cat)}/${encodeURIComponent(sq)}/${SortBy}/${FilterByType}/${FilterByPageSize}/${StartLimit}/${CustomerID}/${encodeURIComponent(selectedSellers)}`, this.httpOptions);
    
    // this.Products = this.http.get<Products[]>(`${this.baseUrl}/product_search_page_sellers_filtered_option_product_data/${encodeURIComponent(search_cat)}/${encodeURIComponent(sq)}/${SortBy}/${FilterByType}/${FilterByPageSize}/${StartLimit}/${CustomerID}/${encodeURIComponent(selectedSellers)}`, this.httpOptions).pipe(map(data => data));
    // this.Products.subscribe(next => { localStorage['products'] = JSON.stringify(next) });
    // return this.Products = this.Products.pipe(startWith(JSON.parse(localStorage['products'] || '[]')));
    // // return this.http.get(`${this.baseUrl}/product_search_page_filter_product_data/${encodeURIComponent(search_cat)}/${encodeURIComponent(sq)}/${SortBy}/${FilterByType}/${FilterByPageSize}/${StartLimit}/${CustomerID}`, this.httpOptions);
  }

  // pd services
  public get getProducts(): Observable<Products[]> {
    return this.Products;
  }
  loadSingleProductPdData(URLKey: string, CustomerID: string): Observable<Products[]> {
    return this.http.get<Products[]>(`${this.baseUrl}/product_pd_page_single_product_data/${encodeURIComponent(URLKey)}/${encodeURIComponent(CustomerID)}`, this.httpOptions);
  }

  loadSingleProductImagesPdData(articleno: string): Observable<ProductImages[]> {
    return this.http.get<ProductImages[]>(`${this.baseUrl}/article_images_all/${encodeURIComponent(articleno)}`, this.httpOptions);
  }

  loadRelatedProductPdData(URLKey: string, CustomerID: string, cat1: string, cat2: string, cat3: string): Observable<Products[]> {
    // this.Products = this.http.get<Products[]>(`${this.baseUrl}/product_pd_page_related_product_data/${encodeURIComponent(URLKey)}/${encodeURIComponent(CustomerID)}/${encodeURIComponent(cat1)}/${encodeURIComponent(cat2)}/${encodeURIComponent(cat3)}/0/6`, this.httpOptions).pipe(map(data => data));
    // this.Products.subscribe(next => { localStorage['related_products'] = JSON.stringify(next) });
    // return this.Products = this.Products.pipe(startWith(JSON.parse(localStorage['related_products'] || '[]')));
    
    return this.http.get<Products[]>(`${this.baseUrl}/product_pd_page_related_product_data/${encodeURIComponent(URLKey)}/${encodeURIComponent(CustomerID)}/${encodeURIComponent(cat1)}/${encodeURIComponent(cat2)}/${encodeURIComponent(cat3)}/0/6`, this.httpOptions);
  }

  loadBreadCrumbCategory(comma_seperated_ids: string): Observable<Categories[]> {
    return this.http.get<Categories[]>(`${this.baseUrl}/categories_get_limited_data/${encodeURIComponent(comma_seperated_ids)}`, this.httpOptions);
  }
  updateProductViews(productID: string, TotalViewsData: any) {
    return this.http.put(`${this.baseUrl}/product_UpdateTotalViews/${encodeURIComponent(productID)}`, TotalViewsData, this.httpOptions);
  }

  postUserOrders(UserOrderPostData: any) {
    return this.http.post(`${this.baseUrl}/user_order_post_checkout`, UserOrderPostData, this.httpOptions);
  }
  
  getShippingAmountDetails(seller_unique_name: string) {
    let current_date_time = new Date();
    let year = current_date_time.getFullYear();
    let month: string | number = current_date_time.getMonth() + 1;
    if(month <= 9) {
      month = "0"+month;
    }
    let day: string | number = current_date_time.getDate();
    if(day <= 9) {
      day = "0"+day;
    }
    let hours: string | number = current_date_time.getHours();
    let minutes: string | number = current_date_time.getMinutes();
    let seconds: string | number = current_date_time.getSeconds();

    if(hours <= 9) {
      hours = "0"+hours;
    }
    if(minutes <= 9) {
      minutes = "0"+minutes;
    }
    if(seconds <= 9) {
      seconds = "0"+seconds;
    }

    var currentDate_YYYY_MM_DD = year+"-"+month+"-"+day;
    return this.http.get(`${this.baseUrl}/shipping_amount_with_seller_and_date/${encodeURIComponent(seller_unique_name)}/${encodeURIComponent(currentDate_YYYY_MM_DD)}`, this.httpOptions);
  }

  getCBWalletLeftBalanceInCustomerAccount(CustomerID: string) {
    return this.http.get(`${this.baseUrl}/cb_wallet_left_balance_with_customer/${encodeURIComponent(CustomerID)}`, this.httpOptions);
  }

  createRazorpayOrder(finalAmount: number, CurrencyType: string, OrderID: string) {
    return this.http.get(`${this.baseUrl}/razorpay_create_order_id/${encodeURIComponent(finalAmount)}/${encodeURIComponent(CurrencyType)}/${encodeURIComponent(OrderID)}`, this.httpOptions);
  }

  validateUserOrderItems(OrderID: string) {
    return this.http.get(`${this.baseUrl}/user_order_items_remove_sold_products/${encodeURIComponent(OrderID)}`, this.httpOptions);
  }
  
  getUserOrderData(OrderID: string) {
    return this.http.get(`${this.baseUrl}/user_order_single_with_orderid/${encodeURIComponent(OrderID)}`, this.httpOptions);
  }
  
  updateRazorpayIDOnUserOrder(OrderID: string, RazorpayID: string) {
    var emptyData = {
      value: null
    };
    return this.http.put(`${this.baseUrl}/user_order_update_razorpay_id/${encodeURIComponent(OrderID)}/${encodeURIComponent(RazorpayID)}`, emptyData, this.httpOptions);
  }

  verifyRazorpayOrder(razorpay_payment_id: string, razorpay_order_id: string, razorpay_signature: string) {
    return this.http.get(`${this.baseUrl}/razorpay_verify_signature/${encodeURIComponent(razorpay_payment_id)}/${encodeURIComponent(razorpay_order_id)}/${encodeURIComponent(razorpay_signature)}`, this.httpOptions);
  }

  makePaypalOrder(PaypalData: any) {
    return this.http.post(`${this.baseUrl}/make_payment_via_paypal`, PaypalData, this.httpOptions);
  }

  verifyPaypalOrder(PaypalVerifyData: any) {
    return this.http.post(`${this.baseUrl}/verify_payment_via_paypal`, PaypalVerifyData, this.httpOptions);
  }

  getRazorpayPaymentsData(razorpay_payment_id: string) {
    return this.http.get(`${this.baseUrl}/razorpay_get_payments_details/${encodeURIComponent(razorpay_payment_id)}`, this.httpOptions);
  }
  
  updateRazorpayPaymentsCapturedFromAuthorized(razorpay_payment_id: string, amount: number, currency: string) {
    return this.http.get(`${this.baseUrl}/razorpay_payments_captured_from_authorized/${encodeURIComponent(razorpay_payment_id)}/${encodeURIComponent(amount)}/${encodeURIComponent(currency)}`, this.httpOptions);
  }

  addPaymentsData(paymentsPostData: any) {
    return this.http.post(`${this.baseUrl}/payments`, paymentsPostData, this.httpOptions);
  }

  getCBWalletMaxID(Type: string) {
    return this.http.get(`${this.baseUrl}/cb_wallet_max_id/${encodeURIComponent(Type)}`, this.httpOptions);
  }

  postCBWalletTransaction(CBWalletAddData: any) {
    return this.http.post(`${this.baseUrl}/cb_wallet`, CBWalletAddData, this.httpOptions);
  }

  updateCBWalletTransaction(CBWalletID: string, CBWalletUpdateData: any) {
    return this.http.put(`${this.baseUrl}/cb_wallet/${encodeURIComponent(CBWalletID)}`, CBWalletUpdateData, this.httpOptions);
  }

  updateCustomerAuctionBidLimit(CustomerID: string, OnlineAuctionBidLimit: number) {
    var jsonUpdateData = {
      OnlineAuctionBidLimit: OnlineAuctionBidLimit
    };
    return this.http.put(`${this.baseUrl}/customer_update_auction_bidlimit/${encodeURIComponent(CustomerID)}`, jsonUpdateData, this.httpOptions);
  }

  updateUserOrderStatus(OrderID: string, CustomerID: string) {
    var emptyData = {
      value: null
    };
    return this.http.put(`${this.baseUrl}/user_order_update_payment_status/${encodeURIComponent(OrderID)}/${encodeURIComponent(CustomerID)}`, emptyData, this.httpOptions);
  }

  updateProductSoldQuantity(OrderID: string) {
    var emptyData = {
      value: null
    };
    return this.http.put(`${this.baseUrl}/product_update_sold_quantity_with_orderid/${encodeURIComponent(OrderID)}`, emptyData, this.httpOptions);
  }

  deleteCartItems(CustomerID: string) {
    return this.http.delete(`${this.baseUrl}/cnsdata_delete_with_customer/${encodeURIComponent(CustomerID)}`, this.httpOptions);
  }

  // get all seller id of a user order.
  getDistinctSellerAndTrackingDataFromUserOrderItems(OrderID: string) {
    return this.http.get(`${this.baseUrl}/user_order_items_distinct_seller_and_tracking_data_with_orderid/${encodeURIComponent(OrderID)}`, this.httpOptions);
  }
  
  getDistinctSellerFromUserOrderItems(OrderID: string) {
    return this.http.get(`${this.baseUrl}/user_order_items_distinct_seller_with_orderid/${encodeURIComponent(OrderID)}`, this.httpOptions);
  }

  // get all user order items data of single seller with order id
  getSellerWiseUserItems(OrderID: string, SellerID: string) {
    return this.http.get(`${this.baseUrl}/user_order_items_with_orderid_and_seller/${encodeURIComponent(OrderID)}/${encodeURIComponent(SellerID)}`, this.httpOptions);
  }

  getAllUserOrderItems(OrderID: string) {
    return this.http.get(`${this.baseUrl}/user_order_all_items_with_orderid/${encodeURIComponent(OrderID)}`, this.httpOptions);
  }

  getCustomerPersonalDetails(CustomerID: any) {
    return this.http.get(`${this.baseUrl}/customer_personal_data/${encodeURIComponent(CustomerID)}`, this.httpOptions);
  }

  // whatsapp notification
  sendWhatsappNotification(WhatsappData: any) {
    const headersObject = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Basic U2tGd1lTQ1h5VVZvaXhBeXc2MnpZWW9ZN0VfaU83dllUOHVtcEwzbktQSTo='
      })
    };
    return this.http.post(`https://api.interakt.ai/v1/public/track/events/`, WhatsappData, headersObject);
  }

  addInvoice(InvoicePostData: any) {
    return this.http.post(`${this.baseUrl}/invoice`, InvoicePostData, this.httpOptions);
  }

  // invoice
  getNextInvoiceNumberOfCurrentYear(Seller: string, CurrentYear: number) {
    return this.http.get(`${this.baseUrl}/invoice_nextid_in_current_year/${encodeURIComponent(Seller)}/${encodeURIComponent(CurrentYear)}`, this.httpOptions);
  }

  // login page registration process
  getCartDataAndSyncCartAndwishlist(CustomerUUID: string, CustomerID: string) {
    return this.http.get(`${this.baseUrl}/cnsdata_sync_with_cnsdata_and_wishlist/${encodeURIComponent(CustomerID)}/${encodeURIComponent(CustomerUUID)}`, this.httpOptions);
  }

  getCartData(CustomerID: string) {
    return this.http.get(`${this.baseUrl}/cnsdata_with_customer_active_products/${encodeURIComponent(CustomerID)}`, this.httpOptions);
  }

  getAllCartData(CustomerID: string) {
    return this.http.get(`${this.baseUrl}/cnsdata_with_customer_on_cart_page/${encodeURIComponent(CustomerID)}`, this.httpOptions);
  }

  countUserRegistrationExistsEmailAndMobile(emailAddress: string, mobile: string) {
    return this.http.get(`${this.baseUrl}/customer_count_email_or_mobile/${encodeURIComponent(emailAddress)}/${encodeURIComponent(mobile)}`, this.httpOptions);
  }

  getCustomerMaxID() {
    return this.http.get(`${this.baseUrl}/customer_max_id`, this.httpOptions);
  }

  generateTempCustomer() {
    return this.http.get(`${this.baseUrl}/user_order_temp_customer_id`, this.httpOptions);
  }

  insertCustomer(NewCustomerRegisterData: any) {
    return this.http.post(`${this.baseUrl}/customer`, NewCustomerRegisterData, this.httpOptions);
  }

  insertCustomerLogin(NewCustomerLoginTableData: any) {
    return this.http.post(`${this.baseUrl}/customer_login_data`, NewCustomerLoginTableData, this.httpOptions);
  }
  
  loginCustomerAuthorization(username: string, password: string) {
    return this.http.get(`${this.baseUrl}/customer_login/${encodeURIComponent(username)}/${encodeURIComponent(password)}`, this.httpOptions);
  }

  cartUpdateCustomerIP(user_ip: any, customerId: string) {
    var CustomerCartID_Data = {
      customerId: customerId
    };
    return this.http.put(`${this.baseUrl}/cnsdata_update_with_customer_ip/${encodeURIComponent(user_ip)}`, CustomerCartID_Data, this.httpOptions);
  }

  deleteDuplicateEntryFromCart(customerId: string) {
    return this.http.delete(`${this.baseUrl}/cnsdata_delete_duplicate_product/${encodeURIComponent(customerId)}`, this.httpOptions);
  }

  wishlistUpdateCustomerIP(user_ip: any, customerId: string) {
    var CustomerWishlistID_Data = {
      customer: customerId
    };
    return this.http.put(`${this.baseUrl}/customer_product_wishlist_update_with_customer_ip/${encodeURIComponent(user_ip)}`, CustomerWishlistID_Data, this.httpOptions);
  }

  deleteDuplicateEntryFromWishlist(customerId: string) {
    return this.http.delete(`${this.baseUrl}/customer_product_wishlist_delete_duplicate_wislist/${encodeURIComponent(customerId)}`, this.httpOptions);
  }
  
  getCustomerWishlistItems(customerId: string) {
    return this.http.get(`${this.baseUrl}/customer_product_wishlist_with_product_details_using_customer/${encodeURIComponent(customerId)}/0/20000`, this.httpOptions);
  }

  public getAllUniqueCategoriesFromSeller(seller: string, seller_category_id: string, filterBy: string): Observable<ProductSellerCategories[]> {
    filterBy = '6';
    return this.http.get<ProductSellerCategories[]>(`${this.baseUrl}/product_get_all_unique_seller_categories/${encodeURIComponent(seller)}/${encodeURIComponent(seller_category_id)}/${encodeURIComponent(filterBy)}`, this.httpOptions);
  }
  
  public getAllCategoriesFromSearchText(search_text: string, search_parent_category: string, filterBy: string): Observable<ProductSellerCategories[]> {
    filterBy = '6';
    return this.http.get<ProductSellerCategories[]>(`${this.baseUrl}/product_get_all_search_categories/${encodeURIComponent(search_text)}/${encodeURIComponent(search_parent_category)}/${encodeURIComponent(filterBy)}`, this.httpOptions);
  }
  
  public getAllSellersFromSearchText(search_text: string, search_parent_category: string, filterBy: string): Observable<ProductSeller[]> {
    filterBy = '6';
    return this.http.get<ProductSeller[]>(`${this.baseUrl}/product_get_all_search_sellers_data/${encodeURIComponent(search_text)}/${encodeURIComponent(search_parent_category)}/${encodeURIComponent(filterBy)}`, this.httpOptions);
  }

  // my-account
  getCustomerCheckoutPage(CustomerID: string) {
    return this.http.get(`${this.baseUrl}/cust_address_for_checkout/${encodeURIComponent(CustomerID)}/0/4`, this.httpOptions);
  }
  getCustomerCheckoutAddressWithID(ID: string) {
    return this.http.get(`${this.baseUrl}/customer_address/${encodeURIComponent(ID)}`, this.httpOptions);
  }
  insertCustomerDefaultAddress(CustomerAddressData: any) {
    return this.http.post(`${this.baseUrl}/customer_address`, CustomerAddressData, this.httpOptions);
  }
  updateCustomerDefaultAddress(ID: string, CustomerAddressData: any) {
    return this.http.put(`${this.baseUrl}/customer_address/${encodeURIComponent(ID)}`, CustomerAddressData, this.httpOptions);
  }
  deleteCustomerDefaultAddress(ID: string) {
    return this.http.delete(`${this.baseUrl}/customer_address/${encodeURIComponent(ID)}`, this.httpOptions);
  }
  getYear() {
    let current_date_time = new Date();
    let year = current_date_time.getFullYear();
    return year;
  }
  getMyWalletTransaction(CustomerID: string) {
    return this.http.get(`${this.baseUrl}/cb_wallet_with_customer/${encodeURIComponent(CustomerID)}/0/500`, this.httpOptions);
  }
  changeCustomerPassword(CustomerID: any, new_password: any) {
    var CustomerPasswordData = {
      passwd: new_password
    };
    return this.http.put(`${this.baseUrl}/customer_update_password/${encodeURIComponent(CustomerID)}`, CustomerPasswordData, this.httpOptions);
  }
  updateCustomerPersonalDetails(CustomerID: any, CustomerPersonalDetails: any) {
    return this.http.put(`${this.baseUrl}/customer_personal_data/${encodeURIComponent(CustomerID)}`, CustomerPersonalDetails, this.httpOptions);
  }

  // customer-chats services
  showInboxMessages(CustomerID: string) {
    return this.http.get(`${this.baseUrl}/customer_seller_chats_get_inbox_data/${encodeURIComponent(CustomerID)}`, this.httpOptions);
  }

  showChatsMessages(CustomerID: string, SellerID: string, Status: string) {
    return this.http.get(`${this.baseUrl}/customer_seller_chats_get_chats_messages/${encodeURIComponent(CustomerID)}/${encodeURIComponent(SellerID)}/${encodeURIComponent(Status)}`, this.httpOptions);
  }

  showArchiveMessages(CustomerID: string) {
    return this.http.get(`${this.baseUrl}/customer_seller_chats_get_archieve_inbox_data/${encodeURIComponent(CustomerID)}`, this.httpOptions);
  }

  setConversationDone(CustomerID: string, SellerID: string) {
    var ChatsStatusData = {
      status: 0
    };
    return this.http.put(`${this.baseUrl}/customer_seller_chats_set_conversation_status/${encodeURIComponent(CustomerID)}/${encodeURIComponent(SellerID)}`, ChatsStatusData, this.httpOptions);
  }

  setConversationOpen(CustomerID: string, SellerID: string) {
    var ChatsStatusData = {
      status: 1
    };
    return this.http.put(`${this.baseUrl}/customer_seller_chats_set_conversation_status/${encodeURIComponent(CustomerID)}/${encodeURIComponent(SellerID)}`, ChatsStatusData, this.httpOptions);
  }

  sendMessegeToSelllerViaCustomer(CustomerSellerChatsPostData: any) {
    return this.http.post(`${this.baseUrl}/customer_seller_chats`, CustomerSellerChatsPostData, this.httpOptions);
  }

  // my-orders page
  getMyOrdersData(Customer: any, Page: number, FilterByPageSize: number) {
    return this.http.get(`${this.baseUrl}/user_order_all_data_with_customer/${encodeURIComponent(Customer)}/${encodeURIComponent(Page)}/${encodeURIComponent(FilterByPageSize)}`, this.httpOptions);
  }

  loadMyOrderPaginationData(Customer: string) {
    return this.http.get(`${this.baseUrl}/user_order_all_pagination_data_with_customer/${encodeURIComponent(Customer)}`, this.httpOptions);
  }

  // counter-offer page
  getCounterOffersData(Customer: any, Page: number, FilterByPageSize: number) {
    var counter_offer_data = {
      customer_id: Customer,
      page: Page,
      limit: FilterByPageSize,
    };
    return this.http.post(`${this.baseUrl}/cb_counter_offers_filters`, counter_offer_data, this.httpOptions);
  }
  getFilterCounterOffersData(counter_offer_data: any) {
    console.log(counter_offer_data);
    return this.http.post(`${this.baseUrl}/cb_counter_offers_filters`, counter_offer_data, this.httpOptions);
  }

  loadMyCounterOffersPaginationData(Customer: string) {
    var counter_offer_data = {
      customer_id: Customer
    };
    return this.http.post(`${this.baseUrl}/cb_counter_offers_row_counts`, counter_offer_data, this.httpOptions);
  }

  getInvoiceShippingFeedback(OrderID: string, Seller: string) {
    return this.http.get(`${this.baseUrl}/invoice_shipping_customer_feedback_with_orderid_and_seller/${encodeURIComponent(OrderID)}/${encodeURIComponent(Seller)}`, this.httpOptions);
  }
  checkCustomerFeedbackExistsrRecord(CustomerID: string, SellerID: string, OrderID: string) {
    return this.http.get(`${this.baseUrl}/customer_feedback_check_exists_record/${encodeURIComponent(CustomerID)}/${encodeURIComponent(SellerID)}/${encodeURIComponent(OrderID)}`, this.httpOptions);
  }
  postCustomerFeedback(CustomerFeedbackData: any) {
    return this.http.post(`${this.baseUrl}/customer_feedback`, CustomerFeedbackData, this.httpOptions);
  }
  updateCustomerFeedback(FeedbackID: string, CustomerFeedbackData: any) {
    return this.http.put(`${this.baseUrl}/customer_feedback/${encodeURIComponent(FeedbackID)}`, CustomerFeedbackData, this.httpOptions);
  }

  // seller-shops page service
  getSellersShopsData() {
    return this.http.get(`${this.baseUrl}/seller_master_seller_shops_data`, this.httpOptions);
  }
  // special notes services
  getSingleProductDataWithID(id: any) {
    return this.http.get(`${this.baseUrl}/product_active_single_product_with_id/${id}`, this.httpOptions);
  }

  getSpecialNotes(NoteID: string, NotesNumber: string) {
    return this.http.get(`${this.baseUrl}/special_notes_with_productid_and_note_number/${NoteID}/${NotesNumber}`, this.httpOptions);
  }

  getNoteCoverDataWithCategory(cat1: any, cat2: any) {
    return this.http.get(`${this.baseUrl}/product_get_all_note_cover_with_category/${encodeURIComponent(cat1)}/${encodeURIComponent(cat2)}`, this.httpOptions);
  }

  getAllNoteProductWithCategoryID(CategoryID: string) {
    return this.http.get(`${this.baseUrl}/product_get_all_note_product_with_category/${CategoryID}`, this.httpOptions);
  }
  
  // search link table get data for main search
  getSearchLinkData(SearchText: string) {
    return this.http.get(`${this.baseUrl}/search_link_with_options/${SearchText}`, this.httpOptions);
  }
  saveCustomerSearchData(searchData: any) {
    return this.http.post(`${this.baseUrl}/save_customer_search_data`, searchData, this.httpOptions);
  }

  // pages page services
  getPageData(PageID: any) {
    return this.http.get(`${this.baseUrl}/pages/${encodeURIComponent(PageID)}`, this.httpOptions);
  }

  // ongoing-offer page service
  getDiscountDistinctSellerWithMinMaxDiscount() {
    return this.http.get(`${this.baseUrl}/discount_active_distinct_seller`, this.httpOptions);
  }

  getActiveSellerMinMaxDiscountValue(SellerID: any) {
    return this.http.get(`${this.baseUrl}/discount_get_min_max_discount_from_seller/${encodeURIComponent(SellerID)}`, this.httpOptions);
  }

  getAllSellerCategoryActiveDiscountWithProductCountData(SellerID: any) {
    return this.http.get(`${this.baseUrl}/discount_active_from_seller/${encodeURIComponent(SellerID)}`, this.httpOptions);
  }

  showMainSearchSuggestion(search_cat: string, sq: string) {
    return this.http.get(`${this.baseUrl}/save_customer_search_data_main_title/${encodeURIComponent(search_cat)}/${encodeURIComponent(encodeURIComponent(sq))}`, this.httpOptions);
  }
  getSellerLeaveMessage(seller: string) {
    return this.http.get(`${this.baseUrl}/seller_leave_message_with_seller_and_today_date/${encodeURIComponent(seller)}`, this.httpOptions);
  }

  getRandomString(length: number) {
    var randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var result = '';
    for ( var i = 0; i < length; i++ ) {
      result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
    }
    return result;
  }
  getCustomerPersonalDetailsFromEmailAddres(emailAddress: string) {
    return this.http.get(`${this.baseUrl}/customer_personal_details_from_email/${encodeURIComponent(btoa(emailAddress))}`, this.httpOptions);
  }


  completeCBUserOrder(user_order_data: any) {
    return this.http.post(`${this.baseUrl}/user_order_complete_order_placed`, user_order_data, this.httpOptions);
  }
}
