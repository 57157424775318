import {Injectable} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';

declare var gtag: Function;
@Injectable({
	providedIn: 'root'
})

export class GoogleAnalyticsService {
	constructor(public router: Router) {
		this.router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
			  gtag('config', 'UA-137584772-1', {'page_path': event.urlAfterRedirects});
			}
		});
	}

	public gtagViewItemMethod(product: any) {
		gtag("event", "view_item", {
            currency: "INR",
            value: product.price,
            items: [
              {
                item_id: product.articalno,
                item_name: product.articlename,
                affiliation: " ",
                coupon: " ",
                currency: "INR",
                discount: product.product_discount,
                index: product.articalno,
                item_brand: "CollectorBazar",
                item_category: product.cat1,
                item_category2: product.cat2,
                item_category3: product.cat3,
                item_list_id: "view_item",
                item_list_name: "View Product Details",
                item_variant: "NA",
                location_id: product.articalno,
                price: product.price,
                quantity: product.quantity
              }
            ]
        });
	}

	public gtagAddToCartMethod(product: any) {
		gtag("event", "add_to_cart", {
            currency: "INR",
            value: product.price,
            items: [
              {
                item_id: product.articalno,
                item_name: product.articlename,
                affiliation: " ",
                coupon: " ",
                currency: "INR",
                discount: product.discount,
                index: product.articalno,
                item_brand: "CollectorBazar",
                item_category: product.cat1,
                item_category2: product.cat2,
                item_category3: product.cat3,
                item_list_id: "add_to_cart",
                item_list_name: "Add To Cart",
                item_variant: "NA",
                location_id: product.articalno,
                price: product.price,
                quantity: product.quantity
              }
            ]
        });
	}

	public gtagRemoveFromCartMethod(product: any) {
		gtag("event", "remove_from_cart", {
            currency: "INR",
            value: product.price,
            items: [
              {
                item_id: product.articalno,
                item_name: product.articlename,
                affiliation: " ",
                coupon: " ",
                currency: "INR",
                discount: product.discount,
                index: product.articalno,
                item_brand: "CollectorBazar",
                item_category: product.cat1,
                item_category2: product.cat2,
                item_category3: product.cat3,
                item_list_id: "remove_from_cart",
                item_list_name: "Remove From Cart",
                item_variant: "NA",
                location_id: product.articalno,
                price: product.price,
                quantity: product.quantity
              }
            ]
        });
	}

	public gtagAddToWishlistMethod(product: any) {
		gtag("event", "add_to_wishlist", {
            currency: "INR",
            value: product.price,
            items: [
              {
                item_id: product.articalno,
                item_name: product.articlename,
                affiliation: " ",
                coupon: " ",
                currency: "INR",
                discount: product.discount,
                index: product.articalno,
                item_brand: "CollectorBazar",
                item_category: product.cat1,
                item_category2: product.cat2,
                item_category3: product.cat3,
                item_list_id: "add_to_wishlist",
                item_list_name: "Add To Wishlist",
                item_variant: "NA",
                location_id: "CB-"+product.articalno,
                price: product.price,
                quantity: product.quantity
              }
            ]
        });
	}

	public gtagRemoveFromWishlistMethod(product: any) {
		gtag("event", "remove_from_wishlist", {
            currency: "INR",
            value: product.price,
            items: [
              {
                item_id: product.articalno,
                item_name: product.articlename,
                affiliation: " ",
                coupon: " ",
                currency: "INR",
                discount: product.discount,
                index: product.articalno,
                item_brand: "CollectorBazar",
                item_category: product.cat1,
                item_category2: product.cat2,
                item_category3: product.cat3,
                item_list_id: "remove_from_wishlist",
                item_list_name: "Remove From Wishlist",
                item_variant: "NA",
                location_id: product.articalno,
                price: product.price,
                quantity: product.quantity
              }
            ]
        });
	}

	public gtagSelectItemMethod(product: any) {
		gtag("event", "select_item", {
			item_list_id: "related_products",
			item_list_name: "View Popup item",
			items: [
				{
					item_id: product.articalno,
					item_name: product.articlename,
					affiliation: "CB Online Store",
					coupon: " ",
					currency: "INR",
					discount: product.discount,
					index: product.id,
					item_brand: "CollectorBazar",
					item_category: product.cat1,
					item_category2: product.cat2,
					item_category3: product.cat3,
					item_list_id: "related_products",
					item_list_name: "View Popup item",
					item_variant: " ",
					location_id: product.articalno,
					price: product.price,
					quantity: product.quantity
				}
			]
		});

	}

	public gtagViewItemListMethod(products: any) {
		var items_array = [];
		var total_amount = 0;
		products.forEach(product => {
			var discount_amount = (product.price * product.discount)/100 | 0;
			var product_discount_price = product.price - discount_amount;
			// var product_discount_price = product.price * ((100 - product.discount)/100);
			// console.log(product_discount_price);
			total_amount = total_amount + product_discount_price;
			var product_single_item = {
				item_id: product.articalno,
				item_name: product.articlename,
				affiliation: " ",
				coupon: " ",
				currency: "INR",
				discount: discount_amount,
				index: product.articalno,
				item_brand: "CollectorBazar",
				item_category: product.cat1,
				item_category2: product.cat2,
				item_category3: product.cat3,
				item_list_id: "related_products",
				item_list_name: "every page show products data",
				item_variant: "NA",
				location_id: product.articalno,
				price: product_discount_price,
				quantity: parseInt(product.quantity)
			}
			items_array.push(product_single_item)
		});
		gtag("event", "view_item_list", {
			item_list_id: "related_products",
			item_list_name: "every page show products data",
			items: items_array
		});

	}
	
	public gtagViewCartPageMethod(CartItems: any) {
		var items_array = [];
		var total_amount = 0;
		CartItems.forEach(product => {
			var discount_amount = (product.price * product.discount)/100 | 0;
			var product_discount_price = product.price - discount_amount;
			// var product_discount_price = product.price * ((100 - product.discount)/100);
			// console.log(product_discount_price);
			total_amount = total_amount + product_discount_price;
			var product_single_item = {
				item_id: product.articalno,
				item_name: product.articlename,
				affiliation: " ",
				coupon: " ",
				currency: "INR",
				discount: discount_amount,
				index: product.articalno,
				item_brand: "CollectorBazar",
				item_category: product.cat1,
				item_category2: product.cat2,
				item_category3: product.cat3,
				item_list_id: "view_cart",
				item_list_name: "Cart Items",
				item_variant: "NA",
				location_id: product.articalno,
				price: product_discount_price,
				quantity: parseInt(product.quantity)
			}
			items_array.push(product_single_item)
		});

		gtag("event", "view_cart", {
			currency: "INR",
			value: total_amount,
			items: items_array
		});
	}
	
	public gtagBeginCheckoutMethod(products: any) {
		var items_array = [];
		var total_amount = 0;
		products.forEach(product => {
			var product_discount_price = product.price * ((100 - product.discount)/100);
			total_amount = total_amount + product_discount_price;
			var product_single_item = {
				item_id: product.articalno,
				item_name: product.articlename,
				affiliation: " ",
				coupon: " ",
				currency: "INR",
				discount: product.discount,
				index: product.articalno,
				item_brand: "CollectorBazar",
				item_category: product.cat1,
				item_category2: product.cat2,
				item_category3: product.cat3,
				item_list_id: "begin_checkout",
				item_list_name: "Begin Checkout Page",
				item_variant: "NA",
				location_id: product.articalno,
				price: product_discount_price,
				quantity: product.quantity
			}
			items_array.push(product_single_item)
		});

		gtag("event", "begin_checkout", {
			currency: "INR",
			value: total_amount,
			items: items_array
		});
	}

	public gtagAddPaymentInfoMethod(products: any, payment_type: string) {
		var items_array = [];
		var total_amount = 0;
		products.forEach(product => {
			var product_discount_price = product.price * ((100 - product.discount)/100);
			total_amount = total_amount + product_discount_price;
			var product_single_item = {
				item_id: product.articalno,
				item_name: product.articlename,
				affiliation: " ",
				coupon: " ",
				currency: "INR",
				discount: product.discount,
				index: product.articalno,
				item_brand: "CollectorBazar",
				item_category: product.cat1,
				item_category2: product.cat2,
				item_category3: product.cat3,
				item_list_id: "add_payment_info",
				item_list_name: "Payment Info PG",
				item_variant: "NA",
				location_id: product.articalno,
				price: product_discount_price,
				quantity: product.quantity
			}
			items_array.push(product_single_item)
		});
		gtag("event", "add_payment_info", {
			currency: "INR",
			value: total_amount,
			coupon: "",
			payment_type: payment_type,
			items: items_array
		});
	}

	// not required for CollectorBazar
	public gtagAddShippingInfoMethod(products: any, shipping_tier: string) {
		var items_array = [];
		var total_amount = 0;
		products.forEach(product => {
			var product_discount_price = product.price * ((100 - product.discount)/100);
			total_amount = total_amount + product_discount_price;
			var product_single_item = {
				item_id: product.articalno,
				item_name: product.articlename,
				affiliation: " ",
				coupon: " ",
				currency: "INR",
				discount: product.discount,
				index: product.articalno,
				item_brand: "CollectorBazar",
				item_category: product.cat1,
				item_category2: product.cat2,
				item_category3: product.cat3,
				item_list_id: "add_shipping_info",
				item_list_name: "Shipping Info Checkout",
				item_variant: "NA",
				location_id: product.articalno,
				price: product_discount_price,
				quantity: product.quantity
			}
			items_array.push(product_single_item)
		});
		gtag("event", "add_shipping_info", {
			currency: "INR",
			value: total_amount,
			coupon: "",
			shipping_tier: shipping_tier,
			items: items_array
		});
	}
	public gtagPurchaseMethod(products: any, order_data: any) {
		var items_array = [];
		var total_amount = 0;
		products.forEach(product => {
			var product_discount_price = product.price * ((100 - product.discount)/100);
			total_amount = total_amount + product_discount_price;
			var product_single_item = {
				item_id: product.articalno,
				item_name: product.articlename,
				affiliation: " ",
				coupon: " ",
				currency: "INR",
				discount: product.discount,
				index: product.articalno,
				item_brand: "CollectorBazar",
				item_category: product.cat1,
				item_category2: product.cat2,
				item_category3: product.cat3,
				item_list_id: "purchase",
				item_list_name: "Order Placed Or Purchased",
				item_variant: "NA",
				location_id: product.articalno,
				price: product_discount_price,
				quantity: product.quantity
			}
			items_array.push(product_single_item)
		});

		var tax = 0.18 * order_data.total_cost;
		gtag("event", "purchase", {
			transaction_id: order_data.orderid,
			affiliation: order_data.customer,
			value: order_data.total_cost,
			tax: tax,
			shipping: order_data.shipping_amount,
			currency: "INR",
			coupon: " ",
			items: items_array
		});
	}
}
