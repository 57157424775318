<div class="icon-nav">
  <ul>
    <li class="onhover-div mobile-search search-widgets kay-menu-position" id="search-widgets">
      <!-- <div (click)="searchToggle()">
        <img [src]="isActiveSearch ? 'assets/images/icon/fa-times.png':'assets/images/icon/search.png'" class="img-fluid" alt="Cancel">
        <i class="ti-search"></i>
      </div> -->
      <ul class="search-show-div shopping-search" [class.active]="isActiveSearch">
        <!-- <h5>Search Button here..</h5> -->
        <div class="input-group">
          <div class="input-group-prepend" style="width: 70px;">
            <select style="width:100%;" class="form-control form-select" [(ngModel)]="search_input_cat">
              <option *ngFor="let category of categories" value="{{category.name}}">{{category.name}}</option>
            </select>
          </div>
          <input type="text" [(ngModel)]="search_input_sq" (input)="onSearchChange($event)" list="searchSuggestion" class="form-control" placeholder="Search a Product..." aria-label="Search a Product" aria-describedby="basic-addon2" autocomplete="off">
          <datalist id="searchSuggestion">
            <option *ngFor="let item of searchSuggestionList" [value]="item.SearchText">{{item.SearchText}}</option>
          </datalist>
          <div class="input-group-append d-flex">
            <button type="button" class="btn btn-outline-secondary btn-success" (click)="searchThisKeywords();" title="Search">
              <img src="assets/images/icon/search.png" [alt]="'Search'" class="img-fluid" alt="Search" style="display: block;">
              <!-- <i class="ti-search"></i> -->
              <!-- <i class="fa fa-search"></i> -->
            </button>
          </div>
        </div>
      </ul>
      <!-- <div id="search-overlay" class="search-overlay" [ngStyle]="{'display': search ? 'block' : 'none' }">
        <div> 
          <span class="closebtn" title="Close Overlay" (click)="searchToggle()">×</span>
          <div class="overlay-content">
            <div class="container">
              <div class="row">
                <div class="col-xl-12">
                  <form>
                    <div class="form-group" style="margin: unset;">
                      <input type="text" class="form-control" id="exampleInputPassword1" placeholder="Search Products... ">
                    </div>
                    <button type="submit" class="btn btn-success">
                      <img src="assets/images/icon/search.png" class="img-fluid" alt="">
                      <i class="ti-search"></i>
                      <i class="fa fa-search"></i>
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </li>





    <a href="/cart" title="Cart">
      <li class="onhover-div mobile-cart kay-menu-position">
        <div>
          <img src="assets/images/icon/cart.png" class="img-fluid" alt="Cart">
          <i class="ti-shopping-cart"></i>
        </div>
        <span class="cart_qty_cls">{{ cart_count }}</span>
        <!-- <ul class="show-div shopping-cart" *ngIf='!products.length'>
          <h5>Your cart is currently empty.</h5>
        </ul> -->
        <!-- <ul class="show-div shopping-cart" *ngIf='products.length'>
          <li *ngFor="let product of products">
            <div class="media">
              <a [routerLink]="['/item/', product.URLKey]">
                <img class="mr-3" [src]="getThumbnailImagePathLink(product?.img)" [alt]="product?.articlename">
              </a>
              <div class="media-body">
                <a [routerLink]="['/item/', product.URLKey]">
                  <h4>{{ product?.articlename }}</h4>
                </a>
                <h4>
                  <span>
                    {{ product?.quantity }} x 
                    {{ product?.price * productService?.Currency.price | discount:product | currency:productService?.Currency.currency:'symbol' }}
                  </span>
                </h4>
              </div>
            </div>
            <div class="close-circle" (click)="removeItem(product)">
              <a href="javascript:void(0);"><i class="fa fa-times" aria-hidden="true"></i></a>
            </div>
          </li>
          <li>
            <div class="total">
              <h5>subtotal : <span>{{ getTotal | async | currency:productService?.Currency.currency:'symbol' }}</span></h5>
            </div>
          </li>
          <li>
            <div class="buttons">
              <a [routerLink]="['/cart']" class="view-cart">view cart</a>
              <a [routerLink]="['/checkout']" class="checkout">checkout</a>
            </div>
          </li>
        </ul> -->
      </li>
    </a>
  </ul>
</div>