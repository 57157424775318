<ng-template #ongoingSellerOfferModalComponent let-modal>
    <div class="modal-content">
        <div class="modal-body">
            <button type="button" class="close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="row">
                <div class="col-lg-12 col-xs-12">
                    <div class="table-responsive">
                        <table class="table table-striped table-bordered table-hover" cellpadding="0" cellspacing="0">
                            <tbody>
                                <tr style="border:1px solid red;">
                                    <td colspan="3">
                                        <div class="">
                                            <h2 style="text-align: center; color:greenyellow;" *ngIf="loadingCategoryStatus == 1">Data is Loading ! Please Wait...</h2>
                                            <table class="table table-bordered" cellpadding="0" cellspacing="0" *ngIf="loadingCategoryStatus == 0 && discountSellerCategoryData">
                                                <thead>
                                                    <tr>
                                                        <th>Category</th>
                                                        <th>Discount</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let SellerCategory of discountSellerCategoryData">
                                                        <td>
                                                            <a class="pointer-link" (click)="redirectThisCategory(SellerCategory.catc_name, SellerCategory.catb_name, SellerCategory.cata_name, SellerCategory.catc_id, SellerCategory.catb_id, SellerCategory.cata_id);" target="_blank" style="cursor: pointer;">
                                                                <span *ngIf="SellerCategory.catc_name != 'NONE' && SellerCategory.catb_name != 'NONE'">{{SellerCategory.cata_name}} > {{SellerCategory.catb_name}} > {{SellerCategory.catc_name}}</span>
                                                                <span *ngIf="SellerCategory.catc_name == 'NONE' && SellerCategory.catb_name != 'NONE'">{{SellerCategory.cata_name}} > {{SellerCategory.catb_name}}</span>
                                                                <span *ngIf="SellerCategory.catc_name == 'NONE' && SellerCategory.catb_name == 'NONE'">{{SellerCategory.cata_name}}</span>
                                                                &nbsp;
                                                                ({{SellerCategory.ProductCount}})
                                                            </a>
                                                        </td>
                                                        <td>{{SellerCategory.discount}}%</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>