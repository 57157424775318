<ng-template #imageModal let-modal>
    <div class="modal-content">
        <div class="modal-body">
            <button type="button" class="close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="row">
                <div class="col-lg-12 col-xs-12" appNoRightClick>
                    <!-- <img [src]="product.img ? getActualImagePathLink(product.img): getActualImagePathLink(product.imagenm)" class="img img-responsive" style="max-width: 100%;" > -->
                    <ngb-carousel *ngIf="ProductImages.length">
                        <ng-template ngbSlide *ngFor="let productImage of ProductImages">
                            <img [src]="getActualImagePathLink(productImage.imagenm)" alt="" style="max-width: 100%;">
                            <!-- <div class="carousel-caption">
                                <h3>{{ event.eventLocation }}</h3>
                                <p [innerText]="event.eventDescription"></p>
                            </div> -->
                        </ng-template>
                    </ngb-carousel>
                </div>
            </div>
        </div>
    </div>
</ng-template>
