import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, OnInit, PLATFORM_ID, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductService } from 'src/app/shared/services/products.service';

@Component({
  selector: 'app-ongoing-seller-offer-modal',
  templateUrl: './ongoing-seller-offer-modal.component.html',
  styleUrls: ['./ongoing-seller-offer-modal.component.scss']
})
export class OngoingSellerOfferModalComponent implements OnInit {
  @ViewChild("ongoingSellerOfferModalComponent", { static: false }) OngoingSellerOfferModalComponent: TemplateRef<any>;

  public seller: string = "";
  public discountSellerCategoryData: any;
  loadingCategoryStatus = 1;

  public closeResult: string;
  public modalOpen: boolean = false;

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private modalService: NgbModal,
    private redirectRouter: Router,
    public productService: ProductService) { }

  ngOnInit(): void {
    
  }

  openModal(seller: string) {
    this.seller = seller;
    this.modalOpen = true;
    if (isPlatformBrowser(this.platformId)) { // For SSR 
      this.modalService.open(this.OngoingSellerOfferModalComponent, { 
        size: 'lg',
        ariaLabelledBy: 'Ongoing-Seller-Offer-Modal',
        centered: true,
        windowClass: 'OngoingSellerOfferModalComponent' 
      }).result.then((result) => {
        `Result ${result}`
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
    this.productService.getAllSellerCategoryActiveDiscountWithProductCountData(seller).subscribe((jsonResponse: any) => {
      if(jsonResponse.status == true) {
        this.discountSellerCategoryData = jsonResponse.result;
        this.loadingCategoryStatus = 0;
      }
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnDestroy() {
    if(this.modalOpen){
      this.modalService.dismissAll();
    }
  }

  getActualImagePathLink(name: string) {
    return this.productService.getActualImagePathLink(name);
  }

  redirectThisCategory(catc_name: string, catb_name: string, cata_name: string, catc_id: string, catb_id: string, cata_id: string) {
    var redirectUrl = "/";
    var catid = "0";
    if(catc_name != 'NONE' && catb_name != 'NONE' && cata_name != 'NONE') {
      // var url = catc_name.replace(/[^\w\s]/gi, "");
      // url = url.replace(/  +/g, '-');
      // url = url.replace(/ /gi, '-');
      // redirectUrl = url.toLowerCase( );
      catid = catc_id;
    }
    else if(catc_name == 'NONE' && catb_name != 'NONE' && cata_name != 'NONE') {
      // var url = catb_name.replace(/[^\w\s]/gi, "");
      // url = url.replace(/  +/g, '-');
      // url = url.replace(/ /gi, '-');
      // redirectUrl = url.toLowerCase( );
      catid = catb_id;
    }
    else if(catc_name == 'NONE' && catb_name == 'NONE' && cata_name != 'NONE') {
      // var url = cata_name.replace(/[^\w\s]/gi, "");
      // url = url.replace(/  +/g, '-');
      // url = url.replace(/ /gi, '-');
      // redirectUrl = url.toLowerCase( );
      catid = cata_id;
    }
    window.location.href = '/sellers/'+this.seller+"?category_id="+catid
    // this.redirectRouter.navigate(['/sellers/'+this.seller+"?category_id="+catid]);
  }
}
