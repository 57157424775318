<ng-template class="theme-modal" #viewOrdersSellerItemsComponent let-modal>
    <div class="modal-content quick-view-modal">
        <div class="modal-body">
            <button type="button" class="close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="row">
                <div class="col-md-12">
                    <div class="table-responsive">
                        <table class="table table-bordered table-striped">
                            <thead>
                                <tr>
                                    <th>Image</th>
                                    <th>Product Name</th>
                                    <th>Price</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let OrderItem of UserOrderItemsData">
                                    <td>
                                        <a href="{{getProductLink(OrderItem.URLKey)}}">
                                            <img [src]="getActualImagePathLink(OrderItem.img)" class="img-responsive lazyload" style="height:100px; width:100%;" alt="{{decodeURITextComponent(OrderItem.itemName)}}" />
                                        </a>
                                    </td>
                                    <td>
                                        <a href="{{getProductLink(OrderItem.URLKey)}}">
                                            <h5>{{decodeURITextComponent(OrderItem.itemName)}}</h5>
                                        </a>
                                    </td>
                                    <td>
                                        <h5>{{OrderItem.unitprice * productService?.Currency.price | currency:productService?.Currency.currency:'symbol' }}</h5>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>