import { Component, OnInit, Input, HostListener } from '@angular/core';
import { Cnsdata, Products } from '../../classes/product';
import { ProductService } from '../../services/products.service';

@Component({
  selector: 'app-header-three',
  templateUrl: './header-three.component.html',
  styleUrls: ['./header-three.component.scss']
})
export class HeaderThreeComponent implements OnInit {
  // public cnsdata: Cnsdata[];
  public products: Products[] = [];
  public CustomerID: any = this.productService.getCustomerID();
  // public isLogin: any = "0";
  // public CustomerName: any = "";
  public isLogin: any = this.productService.getCookie("isLogin");
  public CustomerName: any = this.productService.getCookie("CustomerName");
  public lastHourProductAddedCount: number = 0;
  public text_color: string = "#999999";

  @Input() class: string = 'header-2';
  // @Input() themeLogo: string = 'assets/images/icon/logo.png'; // Default Logo
  public themeLogoForWhite: string = '../../../assets/cb-images/logos/logo.png'
  public themeLogoForDark: string = '../../../assets/cb-images/logos/CollectorBazar-Logo-White-Text.png'
  public themeLogo: string = '../../../assets/cb-images/logos/logo.png' // Default Logo
  @Input() topbar: boolean = true; // Default True
  @Input() sticky: boolean = false; // Default false
  
  public stick: boolean = false;
  public mobile_view: boolean = false;

  public currencies = [{
    name: 'Rupees',
    currency: 'INR',
    price: 1 // price of inr
  }
  // ,{
  //   name: 'Dollar',
  //   currency: 'USD',
  //   price: 0.015 // price of usd
  // }, {
  //   name: 'Euro',
  //   currency: 'EUR',
  //   price: 0.014 // price of euro
  // }, {
  //   name: 'Pound',
  //   currency: 'GBP',
  //   price: 0.012 // price of euro
  // }
  ];

  constructor(public productService: ProductService) {
    if(this.productService.ThemeColor == "light") {
      this.text_color = "#000";
    }
    if(this.productService.getCookie("isLogin")) {

    }
    
    // this.productService.checkLoginUser().subscribe((jsonLoginResponse: any) => {
    //   console.log(jsonLoginResponse);
      
    //   if(jsonLoginResponse.login_status == true) {
    //     var LoginData = jsonLoginResponse.result[0];
    //     this.isLogin = 1;
    //     this.CustomerName = LoginData.CustomerName;
    //     this.productService.setCookie("isLogin", LoginData.isLogin, 30);
    //     this.productService.setCookie("CustomerID", LoginData.CustomerID, 30);
    //     this.productService.setCookie("CustomerName", LoginData.CustomerName, 30);
    //     this.productService.setCookie("CustomerEmail", LoginData.CustomerEmail, 30);
    //     this.productService.setCookie("CustomerCountry", LoginData.CustomerCountry, 30);
    //     this.productService.setCookie("CustomerIsBlogUser", LoginData.CustomerIsBlogUser, 30);

    //   }
    //   else{
    //     this.isLogin = 0;
    //     this.productService.setCookie("isLogin", '0', 30);
    //     this.productService.deleteCookie("CustomerID");
    //     this.productService.deleteCookie("CustomerName");
    //     this.productService.deleteCookie("CustomerEmail");
    //     this.productService.deleteCookie("CustomerCountry");
    //     this.productService.deleteCookie("CustomerIsBlogUser");
    //   }
    // });

    // this.productService.checkLoginUser().subscribe((jsonLoginResponse: any) => {
    //   if(jsonLoginResponse.login_status == true) {
    //     var LoginData = jsonLoginResponse.result[0];
    //     console.log(LoginData);
    //     this.isLogin = 1;
    //     this.CustomerName = LoginData.CustomerName;
    //     this.productService.setCookie("isLogin", LoginData.isLogin, 30);
    //     this.productService.setCookie("CustomerID", LoginData.CustomerID, 30);
    //     this.productService.setCookie("CustomerName", LoginData.CustomerName, 30);
    //     this.productService.setCookie("CustomerEmail", LoginData.CustomerEmail, 30);
    //     this.productService.setCookie("CustomerCountry", LoginData.CustomerCountry, 30);
    //     this.productService.setCookie("CustomerIsBlogUser", LoginData.CustomerIsBlogUser, 30);
    //   }
    //   else {
    //     this.isLogin = 0;
    //     this.productService.setCookie("isLogin", '0', 30);
    //     this.productService.deleteCookie("CustomerID");
    //     this.productService.deleteCookie("CustomerName");
    //     this.productService.deleteCookie("CustomerEmail");
    //     this.productService.deleteCookie("CustomerCountry");
    //     this.productService.deleteCookie("CustomerIsBlogUser");
    //   }
    // });

    this.productService.cartItems.subscribe(response => this.products = response);
    if (window.innerWidth > 700) { 
      this.mobile_view = true;
    } else {
      this.mobile_view = false;
    }

    var themeColor = this.productService.getCookie("theme-color");
    if(themeColor == "dark") {
      this.themeLogo = this.themeLogoForDark;
    }
    else {
      this.themeLogo = this.themeLogoForWhite;
    }
  }

  ngOnInit(): void {
    this.getLastHourAddedProductCount();
  }

  getLastHourAddedProductCount() {
    this.productService.getLastHourAddedProductCount().subscribe((jsonResponse: any) => {
      if(jsonResponse.status == true) {
        var ProductCountData = jsonResponse.result[0];
        this.lastHourProductAddedCount = ProductCountData.LastHourAddedProductCount;
      }
    });
  }

  // @HostListener Decorator
  @HostListener("window:scroll", [])
  onWindowScroll() {
    let number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (number >= 150 && window.innerWidth > 400) { 
      this.stick = true;
    } else {
      this.stick = false;
    }
  }

  changeCurrency(currency: any) {
    this.productService.Currency = currency;
  }

  logoutUser() {
    this.productService.logoutCustomer();
  }

}
