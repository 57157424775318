import { Component, Inject, Input, OnInit, PLATFORM_ID, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { ProductService } from 'src/app/shared/services/products.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-view-orders-seller-items',
  templateUrl: './view-orders-seller-items.component.html',
  styleUrls: ['./view-orders-seller-items.component.scss']
})
export class ViewOrdersSellerItemsComponent implements OnInit {
  @Input() UserOrderItemsData: any;
  @Input() orderid: string;
  @Input() seller: string;
  @ViewChild("viewOrdersSellerItemsComponent", { static: false }) ViewOrdersSellerItemsComponent: TemplateRef<any>;

  public closeResult: string;
  public ImageSrc: string;
  public counter: number = 1;
  public modalOpen: boolean = false;

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private router: Router, private modalService: NgbModal,
    public productService: ProductService) { }

  ngOnInit(): void {
    this.getOrderSellerItemsData();
    // console.log(this.orderid+"===="+this.seller);
  }

  openModal() {
    this.modalOpen = true;
    if (isPlatformBrowser(this.platformId)) { // For SSR 
      this.modalService.open(this.ViewOrdersSellerItemsComponent, { 
        size: 'lg',
        ariaLabelledBy: 'modal-basic-title',
        centered: true,
        windowClass: 'ViewOrdersSellerItemsComponent' 
      }).result.then((result) => {
        `Result ${result}`
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  decodeURITextComponent(name: string) {
    return decodeURIComponent(name);
  }

  getProductLink(URLKey: string) {
    return this.productService.getProductLink(URLKey);
  }
  
  getActualImagePathLink(name: string) {
    return this.productService.getActualImagePathLink(name);
  }

  getOrderSellerItemsData() {
    this.productService.getInvoiceShippingFeedback(this.orderid, this.seller).subscribe((jsonInvoiceShippingResponse: any) => {
      if(jsonInvoiceShippingResponse.status == true && jsonInvoiceShippingResponse.result.length >= 1) {
        var ShippingData = jsonInvoiceShippingResponse.result[0];
        this.productService.getSellerWiseUserItems(this.orderid, this.seller).subscribe((jsonItemsResponse: any) => {
          if(jsonItemsResponse.status == true) {
            this.UserOrderItemsData = jsonItemsResponse.result;
          }
          else {
            this.UserOrderItemsData = [];
            console.log("No Order Items Found ! OR Error Occured...");
          }
        });
      }
      else {
        this.productService.getSellerWiseUserItems(this.orderid, this.seller).subscribe((jsonItemsResponse: any) => {
          if(jsonItemsResponse.status == true) {
            this.UserOrderItemsData = jsonItemsResponse.result;
          }
          else {
            this.UserOrderItemsData = [];
            console.log("No Order Items Found ! OR Error Occured...");
          }
        });
      }
    });
  }

  ngOnDestroy() {
    if(this.modalOpen){
      this.modalService.dismissAll();
    }
  }
}
