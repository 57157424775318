import { Component, OnInit } from '@angular/core';
import { ProductService } from '../../services/products.service';

@Component({
  selector: 'app-tap-to-whatsapp',
  templateUrl: './tap-to-whatsapp.component.html',
  styleUrls: ['./tap-to-whatsapp.component.scss']
})
export class TapToWhatsappComponent implements OnInit {
  
  public show: boolean = false;

  constructor(public productService: ProductService) { }

  ngOnInit(): void {
  }

  tapToWhatsapp() {
    var share_text = "Hi. I would like to connect with CollectorBazar";
    var actual_text = "Hi. I would like to connect with CollectorBazar";
    window.open(`https://api.whatsapp.com/send?phone=917715812277&text=`+share_text, actual_text, `toolbar=0, status=0, width=500, height=400, top=0, right=0`);
  }
}
