
<div class="main-navbar">
  <div id="mainnav" class="main-nav">
    <div class="toggle-nav" (click)="mainMenuToggle()">
      <i class="fa fa-bars sidebar-bar"></i>
    </div>
    <ul class="nav-menu" [class.opennav]="navServices?.mainMenuToggle">

      <li class="back-btn">
        <div class="mobile-back text-right" (click)="mainMenuToggle()">
          <span>Back</span>
          <i class="fa fa-angle-right pl-2" aria-hidden="true"></i>
        </div>
      </li>
      <li *ngFor="let menuItem of menuItems" [class.mega-menu]="menuItem.megaMenu">
        <!-- Sub -->
        <h3 class="nav-link" *ngIf="menuItem.type === 'sub'">
          <a [href]="[menuItem.path]">{{ menuItem.title | translate }}</a>
          <div class="lable-nav" *ngIf="menuItem.badge">{{ menuItem.badgeText | translate }}</div>
          <span class="sub-arrow" *ngIf="menuItem.children" (click)="toggletNavActive(menuItem)"></span>
        </h3>
        <!-- Link -->
        <a [href]="menuItem.path" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="nav-link"
          *ngIf="menuItem.type === 'link'">
          {{ menuItem.title }}
          <div class="lable-nav" *ngIf="menuItem.badge">{{ menuItem.badgeText | translate }}</div>
          <span class="sub-arrow" *ngIf="menuItem.children"></span>
        </a>
        <!-- External Link -->
        <a [href]="menuItem.path" class="nav-link" *ngIf="menuItem.type === 'extLink'">
          {{ menuItem.title }}
          <div class="lable-nav" *ngIf="menuItem.badge">{{ menuItem.badgeText | translate }}</div>
          <span class="sub-arrow" *ngIf="menuItem.children"></span>
        </a>
        <!-- External Tab Link -->
        <a [href]="menuItem.path" class="nav-link" *ngIf="menuItem.type === 'extTabLink'">
          {{ menuItem.title }}
          <div class="lable-nav" *ngIf="menuItem.badge">{{ menuItem.badgeText | translate }}</div>
          <span class="sub-arrow" *ngIf="menuItem.children"></span>
        </a>

        <!-- 2nd Level Menu -->
        <!-- <ul class="nav-submenu" [class.opensubmenu]="menuItem.active" *ngIf="menuItem.children && screen_width <= 1199"> -->
        <ul class="nav-submenu" [class.opensubmenu]="menuItem.active" *ngIf="menuItem.children && !menuItem.megaMenu">
          <li *ngFor="let childrenItem of menuItem.children">
            <!-- Sub -->
            <h3 *ngIf="childrenItem.type === 'sub'" >
              <a [href]="childrenItem.path">{{ childrenItem.title | translate }}</a>
              <span class="new-tag" *ngIf="childrenItem.badge">{{ childrenItem.badgeText | translate }}</span>
              <span [class]="childrenItem.children && menuItem.path != '/Categories/Below_Rs50' ? 'sub-arrow':'sub-arrow'" *ngIf="childrenItem.children" (click)="toggletNavActive(childrenItem)"></span>
            </h3>
            <!-- Link -->
            <a [href]="childrenItem.path" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" 
              *ngIf="childrenItem.type === 'link' ">
              <a [href]="childrenItem.path">{{ childrenItem.title | translate }}</a>
              <span class="new-tag" *ngIf="childrenItem.badge">{{ childrenItem.badgeText | translate }}</span>
              <span class="sub-arrow" *ngIf="childrenItem.children"></span>
            </a>
            <!-- External Link -->
            <a [href]="childrenItem.path"
              *ngIf="childrenItem.type === 'extLink' ">
              <a [href]="childrenItem.path">{{ childrenItem.title | translate }}</a>
              <span class="new-tag" *ngIf="childrenItem.badge">{{ childrenItem.badgeText | translate }}</span>
              <span class="sub-arrow" *ngIf="childrenItem.children"></span>
            </a>
            <!-- External Tab Link -->
            <a [href]="childrenItem.path" target="_blank"
              *ngIf="childrenItem.type === 'extTabLink' ">
              <a [href]="childrenItem.path">{{ childrenItem.title | translate }}</a>
              <span class="new-tag" *ngIf="childrenItem.badge">{{ childrenItem.badgeText | translate }}</span>
              <span class="sub-arrow" *ngIf="childrenItem.children"></span>
            </a>
            <!-- bold Link -->
            <a [href]="childrenItem.path" target="_blank" *ngIf="childrenItem.type === 'bold' ">
              <b class="kay-sub-head-menu">{{ childrenItem.title | translate }}</b>
              <span class="new-tag" *ngIf="childrenItem.badge">{{ childrenItem.badgeText | translate }}</span>
              <span class="sub-arrow" *ngIf="childrenItem.children"></span>
            </a>

            <!-- 3rd Level Menu -->
            <ul *ngIf="childrenItem.children" [class.opensubchild]="childrenItem.active" [class]="menuItem.title == 'Books' || menuItem.title == 'Below Rs.50' ? 'nav-sub-childmenu':'nav-sub-childmenu'">
              <li *ngFor="let childrenSubItem of childrenItem.children">
                <!-- Link -->
                <a [href]="childrenSubItem.path" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" 
                  *ngIf="childrenSubItem.type === 'link' ">
                  {{ childrenSubItem.title | translate }}
                  <span class="new-tag" *ngIf="childrenSubItem.badge">{{ childrenSubItem.badgeText | translate }}</span>
                </a>
                <!-- External Link -->
                <a [href]="childrenSubItem.path"
                  *ngIf="childrenSubItem.type === 'extLink' ">
                  {{ childrenSubItem.title | translate }}
                  <span class="new-tag" *ngIf="childrenSubItem.badge">{{ childrenSubItem.badgeText | translate }}</span>
                </a>
                <!-- External Tab Link -->
                <a [href]="childrenSubItem.path" target="_blank"
                  *ngIf="childrenSubItem.type === 'extTabLink' ">
                  {{ childrenSubItem.title | translate }}
                  <span class="new-tag" *ngIf="childrenSubItem.badge">{{ childrenSubItem.badgeText | translate }}</span>
                </a>
              </li>
            </ul>
          </li>
        </ul>
        
        <div class="mega-menu-container" [class.opensubmenu]="menuItem.active" *ngIf="menuItem.megaMenu && menuItem.children">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-3 mega-box" *ngFor="let childrenItem of menuItem.children; let i = index">
                <div class="link-section">
                  <div class="menu-title">
                    <h5>
                        <a [href]="childrenItem.path"><b class="kay-sub-head-menu">{{ childrenItem.title | translate }}</b></a>
                        <!-- <span class="sub-arrow" *ngIf="childrenItem.children"></span> -->
                        <span class="sub-arrow" *ngIf="childrenItem.children" (click)="toggletNavActive(childrenItem)"></span>
                    </h5>
                  </div>
                  <div class="menu-content" *ngIf="childrenItem.children" [class.opensubmegamenu]="childrenItem.active">
                    <ul *ngIf="childrenItem.children">
                      <li *ngFor="let childrenSubItem of childrenItem.children">
                          <!-- Sub -->
                          <a [href]="childrenSubItem.path" *ngIf="childrenSubItem.type === 'sub'">
                            <b>{{ childrenSubItem.title | translate }}</b>
                            <span class="new-tag" *ngIf="childrenSubItem.badge">{{ childrenSubItem.badgeText | translate }}</span>
                          </a>
                          <!-- Link -->
                          <a [href]="childrenSubItem.path" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" *ngIf="childrenSubItem.type === 'link' ">
                            {{ childrenSubItem.title | translate }}
                            <span class="new-tag" *ngIf="childrenSubItem.badge">{{ childrenSubItem.badgeText | translate }}</span>
                          </a>
                          <!-- External Link -->
                          <a [href]="childrenSubItem.path" *ngIf="childrenSubItem.type === 'extLink' ">
                            {{ childrenSubItem.title | translate }}
                            <span class="new-tag" *ngIf="childrenSubItem.badge">{{ childrenSubItem.badgeText | translate }}</span>
                          </a>
                          <!-- External Tab Link -->
                          <a [href]="childrenSubItem.path" target="_blank" *ngIf="childrenSubItem.type === 'extTabLink' ">
                            {{ childrenSubItem.title | translate }}
                            <span class="new-tag" *ngIf="childrenSubItem.badge">{{ childrenSubItem.badgeText | translate }}</span>
                          </a>
                          <!-- Bold Link -->
                          <a [href]="childrenSubItem.path" target="_blank" *ngIf="childrenSubItem.type === 'bold' ">
                            <b class="kay-sub-head-menu">{{ childrenSubItem.title | translate }}</b>
                            <span class="new-tag" *ngIf="childrenSubItem.badge">{{ childrenSubItem.badgeText | translate }}</span>
                          </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</div>




<!-- 
<div class="main-navbar">
  <div id="mainnav">
    <div class="toggle-nav" (click)="mainMenuToggle()">
      <i class="fa fa-bars sidebar-bar"></i>
    </div>
    <ul class="nav-menu" [class.opennav]="navServices?.mainMenuToggle">

      <li class="back-btn">
        <div class="mobile-back text-right" (click)="mainMenuToggle()">
          <span>Back</span>
          <i class="fa fa-angle-right pl-2" aria-hidden="true"></i>
        </div>
      </li>
      <li *ngFor="let menuItem of menuItems" [class.mega-menu]="menuItem.megaMenu">
        
        <a href="javascript:void(0);" class="nav-link" *ngIf="menuItem.type === 'sub'">
          <a [href]="[menuItem.path]">{{ menuItem.title | translate }}</a>
          <div class="lable-nav" *ngIf="menuItem.badge">{{ menuItem.badgeText | translate }}</div>
          <span class="sub-arrow" *ngIf="menuItem.children" (click)="toggletNavActive(menuItem)"></span>
        </a>
        
        <a [href]="menuItem.path" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="nav-link"
          *ngIf="menuItem.type === 'link'">
          {{ menuItem.title }}
          <div class="lable-nav" *ngIf="menuItem.badge">{{ menuItem.badgeText | translate }}</div>
          <span class="sub-arrow" *ngIf="menuItem.children"></span>
        </a>
        
        <a [href]="menuItem.path" class="nav-link" *ngIf="menuItem.type === 'extLink'">
          {{ menuItem.title }}
          <div class="lable-nav" *ngIf="menuItem.badge">{{ menuItem.badgeText | translate }}</div>
          <span class="sub-arrow" *ngIf="menuItem.children"></span>
        </a>
        
        <a [href]="menuItem.path" class="nav-link" *ngIf="menuItem.type === 'extTabLink'">
          {{ menuItem.title }}
          <div class="lable-nav" *ngIf="menuItem.badge">{{ menuItem.badgeText | translate }}</div>
          <span class="sub-arrow" *ngIf="menuItem.children"></span>
        </a>

        
        
        <ul class="nav-submenu" [class.opensubmenu]="menuItem.active" *ngIf="menuItem.children && !menuItem.megaMenu">
          <li *ngFor="let childrenItem of menuItem.children">
            
            <a href="javascript:void(0)" *ngIf="childrenItem.type === 'sub'" >
              <a [href]="childrenItem.path">{{ childrenItem.title | translate }}</a>
              <span class="new-tag" *ngIf="childrenItem.badge">{{ childrenItem.badgeText | translate }}</span>
              <span [class]="childrenItem.children && menuItem.path != '/Below_Rs50' ? 'sub-arrow':'sub-arrow'" *ngIf="childrenItem.children" (click)="toggletNavActive(childrenItem)"></span>
            </a>
            
            <a [href]="childrenItem.path" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" 
              *ngIf="childrenItem.type === 'link' ">
              <a [href]="childrenItem.path">{{ childrenItem.title | translate }}</a>
              <span class="new-tag" *ngIf="childrenItem.badge">{{ childrenItem.badgeText | translate }}</span>
              <span class="sub-arrow" *ngIf="childrenItem.children"></span>
            </a>
            
            <a [href]="childrenItem.path"
              *ngIf="childrenItem.type === 'extLink' ">
              <a [href]="childrenItem.path">{{ childrenItem.title | translate }}</a>
              <span class="new-tag" *ngIf="childrenItem.badge">{{ childrenItem.badgeText | translate }}</span>
              <span class="sub-arrow" *ngIf="childrenItem.children"></span>
            </a>
            
            <a [href]="childrenItem.path" target="_blank"
              *ngIf="childrenItem.type === 'extTabLink' ">
              <a [href]="childrenItem.path">{{ childrenItem.title | translate }}</a>
              <span class="new-tag" *ngIf="childrenItem.badge">{{ childrenItem.badgeText | translate }}</span>
              <span class="sub-arrow" *ngIf="childrenItem.children"></span>
            </a>
            
            <a [href]="childrenItem.path" target="_blank" *ngIf="childrenItem.type === 'bold' ">
              <b class="kay-sub-head-menu">{{ childrenItem.title | translate }}</b>
              <span class="new-tag" *ngIf="childrenItem.badge">{{ childrenItem.badgeText | translate }}</span>
              <span class="sub-arrow" *ngIf="childrenItem.children"></span>
            </a>

            
            <ul *ngIf="childrenItem.children" [class.opensubchild]="childrenItem.active" [class]="menuItem.title == 'Books' || menuItem.title == 'Below Rs.50' ? 'nav-sub-childmenu-left':'nav-sub-childmenu'">
              <li *ngFor="let childrenSubItem of childrenItem.children">
                
                <a [href]="childrenSubItem.path" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" 
                  *ngIf="childrenSubItem.type === 'link' ">
                  {{ childrenSubItem.title | translate }}
                  <span class="new-tag" *ngIf="childrenSubItem.badge">{{ childrenSubItem.badgeText | translate }}</span>
                </a>
                
                <a [href]="childrenSubItem.path"
                  *ngIf="childrenSubItem.type === 'extLink' ">
                  {{ childrenSubItem.title | translate }}
                  <span class="new-tag" *ngIf="childrenSubItem.badge">{{ childrenSubItem.badgeText | translate }}</span>
                </a>
                
                <a [href]="childrenSubItem.path" target="_blank"
                  *ngIf="childrenSubItem.type === 'extTabLink' ">
                  {{ childrenSubItem.title | translate }}
                  <span class="new-tag" *ngIf="childrenSubItem.badge">{{ childrenSubItem.badgeText | translate }}</span>
                </a>
              </li>
            </ul>
          </li>
        </ul>

        <div class="mega-menu-container" *ngIf="menuItem.megaMenu && menuItem.children">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-3 mega-box" *ngFor="let childrenItem of menuItem.children; let i = index">
                <div class="link-section">
                  <div class="menu-title">
                    <h5>
                        <a [href]="childrenItem.path"><b class="kay-sub-head-menu">{{ childrenItem.title | translate }}</b></a>
                        <span class="sub-arrow" *ngIf="childrenItem.children"></span>
                    </h5>
                  </div>
                  <div class="menu-content opensubmegamenu">
                    <ul *ngIf="childrenItem.children">
                      <li *ngFor="let childrenSubItem of childrenItem.children">

                          <a [href]="childrenSubItem.path" *ngIf="childrenSubItem.type === 'sub'">
                            <b>{{ childrenSubItem.title | translate }}</b>
                            <span class="new-tag" *ngIf="childrenSubItem.badge">{{ childrenSubItem.badgeText | translate }}</span>
                          </a>
                          
                          <a [href]="childrenSubItem.path" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" *ngIf="childrenSubItem.type === 'link' ">
                            {{ childrenSubItem.title | translate }}
                            <span class="new-tag" *ngIf="childrenSubItem.badge">{{ childrenSubItem.badgeText | translate }}</span>
                          </a>
                          
                          <a [href]="childrenSubItem.path" *ngIf="childrenSubItem.type === 'extLink' ">
                            {{ childrenSubItem.title | translate }}
                            <span class="new-tag" *ngIf="childrenSubItem.badge">{{ childrenSubItem.badgeText | translate }}</span>
                          </a>
                          
                          <a [href]="childrenSubItem.path" target="_blank" *ngIf="childrenSubItem.type === 'extTabLink' ">
                            {{ childrenSubItem.title | translate }}
                            <span class="new-tag" *ngIf="childrenSubItem.badge">{{ childrenSubItem.badgeText | translate }}</span>
                          </a>
                          
                          <a [href]="childrenSubItem.path" target="_blank" *ngIf="childrenSubItem.type === 'bold' ">
                            <b class="kay-sub-head-menu">{{ childrenSubItem.title | translate }}</b>
                            <span class="new-tag" *ngIf="childrenSubItem.badge">{{ childrenSubItem.badgeText | translate }}</span>
                          </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</div> -->
