<!-- header start -->
<!-- <header class="sticky" [ngClass]="class" [class.fixed]="stick && sticky"> -->
<header class="kay-mobile-search-responsive">
    <div class="mobile-fix-option"></div>
    <div class="top-header" *ngIf="topbar">
      <div class="container-fluid">
          <div class="row">
            <div class="col-lg-6 col-md-6">
              <div class="header-contact">
                <ul>
                  <li><marquee style="color: #b98132;"><h1 style="font-size: unset;text-transform: capitalize;">Total No. of Products Added in Last 24 Hours: {{lastHourProductAddedCount}} Items.</h1></marquee></li>
                  <!-- <li><i class="fa fa-phone" aria-hidden="true"></i>Call Us: 9372725590</li> -->
                </ul>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 text-end padding-top-bottom">
              <ul class="header-dropdown">
                <!-- <li class="onhover-dropdown margin-right-5px" [style.color]="text_color">
                  <i class="fa fa-inr" aria-hidden="true"></i> Currency
                  <ul class="onhover-show-div">
                    <li *ngFor="let currency of currencies">
                      <span (click)="changeCurrency(currency)" [style.color]="text_color">{{ currency.currency }}</span>
                    </li>
                  </ul>
                </li> -->

                <li class="mobile-wishlist">
                  <a [routerLink]="['/wishlist']" [style.color]="text_color"><i class="fa fa-heart" aria-hidden="true" [style.color]="text_color"></i>Wishlist</a>
                </li>
                <li class="mobile-login" *ngIf="isLogin == '0'">
                  <a [routerLink]="['/customer-login']" [style.color]="text_color">
                    <i class="fa fa-user" aria-hidden="true" [style.color]="text_color"></i> Login
                  </a>
                </li>
                <li class="mobile-logged-in" *ngIf="isLogin == '1'">
                  <a [routerLink]="['/dashboard']" class="text-success">
                    <i class="fa fa-user text-success" aria-hidden="true"></i>{{CustomerName}}
                  </a>
                </li>
                <li class="mobile-logout" *ngIf="isLogin == '1'">
                  <span class="text-danger" (click)="logoutUser()">
                    <i class="fa fa-sign-out text-danger" aria-hidden="true"></i>
                  </span>
                </li>
                <!-- <li class="onhover-dropdown mobile-account">
                  <i class="fa fa-user" aria-hidden="true"></i> My Account
                  <ul class="onhover-show-div">
                    <li>
                      <a data-lng="en">
                        Login
                      </a>
                    </li>
                    <li>
                      <a data-lng="es">
                        Logout
                      </a>
                    </li>
                  </ul>
                </li> -->
              </ul>
            </div>
          </div>
        </div>
    </div>
    <!-- <div class="container-fluid sticky" *ngIf="mobile_view">
      <div class="row ">
        <div class="col-lg-12">
          <div class="main-nav-center">
            
          </div>
        </div>
      </div>
    </div> -->
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12 col-sm-12 col-xs-12">
                <div class="main-menu border-section border-top-0">
                    <div class="menu-left">
                      <a [routerLink]="['/']" title="CollectorBazar">
                        <img [src]="themeLogo" class="img-fluid" alt="logo" style="width: 90%;">
                      </a>
                    </div>
                    <div class="menu-center">
                      <!-- <div class="input-group">
                        <input type="text" class="form-control" placeholder="Search Here..." aria-label="Recipient's username" aria-describedby="basic-addon2">
                        <div class="input-group-append">
                          <button class="btn btn-outline-secondary" type="button">
                            <i class="fa fa-search"></i>
                          </button>
                        </div>
                      </div> -->
                    </div>
                    <div class="menu-right pull-right">
                        <app-settings></app-settings>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid sticky" [ngClass]="class" [class.fixed]="stick && sticky">
        <div class="row ">
            <div class="col-lg-12">
                <div class="main-nav-center">
                    <app-menu></app-menu>
                </div>
            </div>
        </div>
    </div>
</header>

<!-- header end -->