export const environment = {
  production: true,
  recaptcha: {
    // siteKey: '6LcgcqkeAAAAAPfAIyaakcP6w8-1tnKHsqg8wsmC', // testb.collectorbazar.com domain
    siteKey: '6LepmKweAAAAANwL02yFG5SU1uD6Ly7JCHvWTmwy', // collectorbazar.com domain
  },
  instagram_token: 'INSTA_TOKEN',
  stripe_token: 'STRIPE_TOKEN',
  paypal_token: 'PAYPAL_TOKEN'
};
