import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'discount'
})
export class DiscountPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    // console.log(args.articlename+"="+value);
    // const price = args.discount ? args.price - (args.price * args.discount / 100) : args.price ;
    const price = args.discount ? value - (value * args.discount / 100) : value ;
    return price
  }
}
