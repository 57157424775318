<!-- services start-->
<div class="container-fluid">
	<section class="service border-section small-section">
		<app-services></app-services>
	</section>
</div>
<!-- services End -->
<!--footer section -->
<footer [class]="class">
	<!-- <div class="light-layout" *ngIf="newsletter">
		<div class="container-fluid">
			<section class="small-section border-section border-top-0">
				<div class="row">
					<div class="col-lg-6">
						<div class="subscribe">
							<div>
								<h4>KNOW IT ALL FIRST!</h4>
								<p>Never Miss Anything From CollectorBazar By Signing Up To Our Newsletter. </p>
							</div>
						</div>
					</div>
					<div class="col-lg-6">
						<form class="form-inline subscribe-form" action="javascript:void(0);" target="_blank" method="post">
							<div class="form-group mx-sm-3">
								<input type="email" #name name="EMAIL" id="mce-EMAIL" class="form-control" placeholder="Enter your email">
							</div>
							<button type="submit" class="btn btn-solid">Subscribe</button>
						</form>
					</div>
				</div>
			</section>
		</div>
	</div> -->
	<section class="light-layout">
		<div class="container-fluid">
			<div class="row footer-theme partition-f padding-top-bottom">
				<div class="col-lg-4 col-md-6">
					<div class="footer-title footer-mobile-title">
						<h4>about</h4>
					</div>
					<div class="footer-contant">
						<div class="footer-logo">
							<img [src]="themeLogo" alt="logo">
						</div>
						<p>CollectorBazar.com is an ecommerce platform to buy and sell items online, particularly for antique and vintage items. The website encourages antique dealers to upload antique collections regularly that are ready to be traded. Dealers can be trusted pertaining to the authenticity of the collections.</p>
						<div class="footer-social">
                            <ul>
                                <li>
                                    <a href="https://www.facebook.com/CollectorBzr/" title="Facebook"><i class="fa fa-facebook" aria-hidden="true"></i></a>
                                </li>
                                <!-- <li>
                                    <a href="javascript:void(0)"><i class="fa fa-google-plus" aria-hidden="true"></i></a>                 
                                </li> -->
                                <li>
                                    <a href="https://twitter.com/bazar_collector" title="Twitter"><i class="fa fa-twitter" aria-hidden="true"></i></a>
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/collectorbazr/" title="Instagram"><i class="fa fa-instagram" aria-hidden="true"></i></a>
                                </li>
                                <li>
                                    <a href="https://www.linkedin.com/company/collectorbazar/" title="Linkedin"><i class="fa fa-linkedin" aria-hidden="true"></i></a>
                                </li>
                                <li>
                                    <a href="https://in.pinterest.com/CollectorBzr/" title="Pinterest"><i class="fa fa-pinterest" aria-hidden="true"></i></a>
                                </li>
                                <li>
                                    <a href="https://www.youtube.com/channel/UC0fNM-MBQlaPG96ea0MTfyw" title="Youtube"><i class="fa fa-youtube-play" aria-hidden="true"></i></a>
                                </li>
                            </ul>
                        </div>
					</div>
				</div>
				<div class="col offset-xl-1">
					<div class="sub-title">
						<div class="footer-title">
                            <h4>Categories</h4>
                        </div>
                        <div class="footer-contant">
                            <ul>
                                <li><a href="/categories/stamps">Stamps</a></li>
                                <li><a href="/categories/collectables">Collectables</a></li>
                                <li><a href="/categories/coins">Coins</a></li>
                                <li><a href="/categories/bank-note">Bank Notes</a></li>
                                <li><a href="/categories/accessories">Accessories</a></li>
                                <li><a href="/categories/books">Books</a></li>
                                <li><a href="/categories/Below_Rs50">Below Rs50</a></li>
                                <!-- <li><a href="javascript:void(0);">Occassion Notes</a></li> -->
                            </ul>
                        </div>
					</div>
				</div>
				<div class="col">
					<div class="sub-title">
						<div class="footer-title">
                <h4>Our Policy</h4>
              </div>
              <div class="footer-contant">
                <ul>
                  <li><a href="/cms/about-us">About Us</a></li>
                  <li><a href="/contact-us">Contact Us</a></li>
                  <li><a href="/cms/privacy-policy">Privacy Policy</a></li>
                  <li><a href="/cms/terms-and-conditions">Terms &amp; Conditions</a></li>
                  <li><a href="/cms/refund-and-cancellation">Refund &amp; Cancellation Policy</a></li>


                  <li><a href="https://seller.collectorbazar.com/sellers/login.php" style="color:#ff4c3b;font-size:16px;"><b>Seller Login</b></a></li>
                  <!--
                  <li><a href="https://seller.collectorbazar.com/sellers/seller-registration.php" style="color:#ff4c3b;font-size:16px;"><b>Seller Registration</b></a></li>
                  -->
                  <!-- <li><a href="javascript:void(0)">About Us</a></li>
                  <li><a href="javascript:void(0)">Shipping & Return</a></li>
                  <li><a href="javascript:void(0)">Secure Shopping</a></li>
                  <li><a href="javascript:void(0)">gallary</a></li>
                  <li><a href="javascript:void(0)">affiliates</a></li>
                  <li><a href="javascript:void(0)">contacts</a></li> -->
                </ul>
              </div>
					</div>
				</div>
				<div class="col">
					<div class="sub-title">
						<div class="footer-title">
						    <h4>information</h4>
						</div>
						<div class="footer-contant">
						    <ul class="contact-list">
						        <li><i class="fa fa-map-marker"></i>CollectorBazar, Vile Parle (East) - Mumbai </li>
						        <li><i class="fa fa-phone"></i>Call Us: 9372725590 Monday to Saturday(From 11:30AM to 7:00PM)</li>
						        <li><i class="fa fa-envelope-o"></i>Email Us: <a>info&#64;collectorbazar.com</a></li>
						    </ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	<div class="sub-footer">
		<div class="container-fluid">
			<div class="row">
                <div class="col-xl-6 col-md-6 col-sm-12">
                  <div class="footer-end">
                    <p><i class="fa fa-copyright" aria-hidden="true"></i> {{ today | date:'y'}} Hobby Tech Pvt. Ltd.</p>
                  </div>
                </div>
                <!-- <div class="col-xl-6 col-md-6 col-sm-12">
                  <div class="payment-card-bottom">
                    <ul>
                      <li>
                        <a><img src="assets/images/icon/visa.png" alt=""></a>
                      </li>
                      <li>
                        <a><img src="assets/images/icon/mastercard.png" alt=""></a>
                      </li>
                      <li>
                        <a><img src="assets/images/icon/paypal.png" alt=""></a>
                      </li>
                      <li>
                        <a><img src="assets/images/icon/american-express.png" alt=""></a>
                      </li>
                      <li>
                        <a><img src="assets/images/icon/discover.png" alt=""></a>
                      </li>
                    </ul>
                  </div>
                </div> -->
            </div>
		</div>
	</div>
</footer>
<!--footer section end -->