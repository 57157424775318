import { Injectable, HostListener } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

// Menu
export interface Menu {
	path?: string;
	title?: string;
	type?: string;
	megaMenu?: boolean;
	image?: string;
	active?: boolean;
	badge?: boolean;
	badgeText?: string;
	children?: Menu[];
}

@Injectable({
	providedIn: 'root'
})

export class NavService {

	constructor() { }

	public screenWidth: any;
	public leftMenuToggle: boolean = false;
	public mainMenuToggle: boolean = false;

	// Windows width
	@HostListener('window:resize', ['$event'])
	onResize(event?) {
		this.screenWidth = window.innerWidth;
	}

	// MENUITEMS: Menu[] = [
	// 	{
	// 		path: '/', title: 'home', type: 'sub', active: true
	// 	},
	// 	{
	// 		path: '/stamps', title: 'Stamps', type: 'sub', megaMenu: false, active: false, children: [
	// 			{
	// 				path: '/indian-stamps', title: 'Indian Stamps', type: 'sub', active: false, children: [
	// 					{ path: '/pre-independence-pre-1947-indian-stamps', title: 'Pre-independence (Pre 1947)', type: 'link' },
	// 					{ path: '/post-independence-post-1947-indian-stamps', title: 'Post-independence (Post 1947)', type: 'link' },
	// 					{ path: '/indian-stamps-sheets-sheetlets', title: 'Sheets Sheetlets', type: 'link' },
	// 					{ path: '/miniature-sheets-stamps', title: 'Miniature Sheets Stamps', type: 'link' },
	// 					{ path: '/definitives-stamps', title: 'Definitives Stamps', type: 'link' },
	// 					{ path: '/others-indian-stamps', title: 'Others Indian Stamps', type: 'link' },

	// 					{ path: '/post-card-stationery', title: 'Post Card & Stationery', type: 'bold' },
	// 					{ path: '/indian-pre-post-card', title: 'Indian - Pre Independence', type: 'link' },
	// 					{ path: '/indian-post-post-card', title: 'Indian - Post Independence', type: 'link' },
	// 					{ path: '/indian-state-post-card', title: 'Indian State Post Card', type: 'link' },
	// 					{ path: '/Picture-Post-Card', title: 'Picture Post Cards', type: 'link' },
	// 					{ path: '/bazar-post-card', title: 'Bazar Cards', type: 'link' },
	// 					{ path: '/advertisement-post-card', title: 'Advertisement Card', type: 'link' },
	// 					{ path: '/world-post-card', title: 'World Post Card', type: 'link' },
	// 				]
	// 			},
	// 			{
	// 				path: '/topical-specialty', title: 'Topical & Specialty', type: 'sub', active: false, children: [
	// 					{ path: '/cartoons-animation', title: 'Cartoons & Animation', type: 'link' },
	// 					{ path: '/monument-building-architecture', title: 'Monument Building & Architecture', type: 'link' },
	// 					{ path: '/cinema-music-entertainment', title: 'Cinema Music & Entertainment', type: 'link' },
	// 					{ path: '/sports-stamps', title: 'Sports Stamps', type: 'link' },
	// 					{ path: '/animals-wild-life', title: 'Animals & Wild Life', type: 'link' },
	// 					{ path: '/flag-national-emblems', title: 'Flag & National Emblems', type: 'link' },
	// 					{ path: '/flora-fauna-nature', title: 'Flora Fauna & Nature', type: 'link' },
	// 					{ path: '/gandhi-other-personalities', title: 'Gandhi & Other Personalities', type: 'link' },
	// 					{ path: '/transport-space', title: 'Transport & Space', type: 'link' },
	// 					{ path: '/special_events', title: 'Special Events', type: 'link' },
	// 					{ path: '/other-topical-specialty', title: 'Other Topical', type: 'link' },
	// 				]
	// 			},
	// 			{
	// 				path: '/other-country', title: 'Other Country', type: 'sub', active: false, children: [
	// 					{ path: '/us-stamps', title: 'US Stamps', type: 'link' },
	// 					{ path: '/uk-stamps', title: 'UK Stamps', type: 'link' },
	// 					{ path: '/middle-east-stamps', title: 'Middle East Stamps', type: 'link' },
	// 					{ path: '/asia-stamps', title: 'Asia Stamps', type: 'link' },
	// 					{ path: '/british-commonwealth-stamps', title: 'British Commonwealth Stamps', type: 'link' },
	// 					{ path: '/europe-stamps', title: 'Europe Stamps', type: 'link' },
	// 					{ path: '/africa', title: 'Africa Stamps', type: 'link' },
	// 					{ path: '/south-american-stamps', title: 'South American Stamps', type: 'link' },
	// 					{ path: '/others-stamps', title: 'Others Stamps', type: 'link' },
						
						
	// 					{ path: '/postal-history', title: 'Postal History', type: 'sub' },
	// 					{ path: '/indian-postal-history', title: 'Indian Postal History', type: 'link' },
	// 					{ path: '/world-postal-history', title: 'World Postal History', type: 'link' },
	// 				]
	// 			},
	// 			{
	// 				path: '/fiscals-revenues', title: 'Fiscals & Revenues', type: 'sub', active: false, children: [
	// 					{ path: '/stamp-paper', title: 'Stamp Paper', type: 'link' },
	// 					{ path: '/documents', title: 'Documents', type: 'link' },
	// 					{ path: '/court-fee', title: 'Court Fee', type: 'link' },
	// 					{ path: '/other-fiscal-items', title: 'Other Fiscal', type: 'link' },


	// 					{ path: '/fdc-special-covers-brochures', title: 'FDC Special Covers & Brochures', type: 'sub' },
	// 					{ path: '/india-fdc-special-covers-brochures', title: 'India', type: 'link' },
	// 					{ path: '/world-fdc-special-covers-brochures', title: 'World', type: 'link' },
	// 				]
	// 			},
	// 			{
	// 				path: '/india-documents', title: 'India Documents', type: 'sub', active: false, children: [
	// 					{ path: '/pre-independence-india-documents-pre-1947', title: 'Pre-independence', type: 'link' },
	// 					{ path: '/post-independence-india-documents-post-1947', title: 'Post-independence', type: 'link' },


	// 					{ path: '/errors-varieties', title: 'Errors & Varieties', type: 'sub' },
	// 					{ path: '/india-errors-varieties', title: 'India Errors & Varieties', type: 'link' },
	// 					{ path: '/world-errors-varieties', title: 'World Errors & Varieties', type: 'link' },
						
	// 					{ path: '/odd-unusuals', title: 'Odd & Unusuals', type: 'sub' },
	// 					{ path: '/india-odd-unusuals', title: 'India Odd & Unusuals', type: 'link' },
	// 					{ path: '/world-odd-unusuals', title: 'World Odd & Unusuals', type: 'link' },
	// 				]
	// 			},
	// 		]
	// 	},
	// 	{
	// 		path: '/collectables', title: 'Collectables', type: 'sub', megaMenu: false, active: false, children: [
	// 			{
	// 				path: '/labels', title: 'Labels', type: 'sub', active: false, children: [
	// 					{ path: '/matchbox-labels', title: 'Matchbox', type: 'link' },
	// 					{ path: '/products-labels', title: 'Products', type: 'link' },
	// 					{ path: '/patriotic-labels', title: 'Patriotic', type: 'link' },
	// 					{ path: '/other-labels', title: 'Others', type: 'link' },
						
	// 					{ path: '/hundis-share-certificate-bonds', title: 'Hundis Share Certificate & Bonds', type: 'sub' },
	// 					{ path: '/hundi', title: 'Hundi', type: 'link' },
	// 					{ path: '/share-certificate', title: 'Share Certificate', type: 'link' },
	// 					{ path: '/Bonds', title: 'Bonds', type: 'link' },
	// 					{ path: '/cheques', title: 'Cheques', type: 'link' },

	// 					{ path: '/calendar', title: 'Calendar', type: 'bold' },

	// 					{ path: '/comics', title: 'Comics', type: 'bold' },
	// 				]
	// 			},
	// 			{
	// 				path: '/airline-items', title: 'Airline items', type: 'sub', active: false, children: [
	// 					{ path: '/air-tickets', title: 'Air Tickets', type: 'link' },
	// 					{ path: '/first-flight-covers', title: 'First Flight Covers', type: 'link' },
						
	// 					{ path: '/photographs', title: 'Photographs', type: 'sub' },
	// 					{ path: '/indian-photographs', title: 'India', type: 'link' },
	// 					{ path: '/world-photographs', title: 'World', type: 'link' },

	// 					{ path: '/movies-films', title: 'Movies & Films', type: 'sub' },
	// 					{ path: '/bollywood-items', title: 'Bollywood Items', type: 'link' },
	// 					{ path: '/hollywood-kollywood-items', title: 'Hollywood & Kollywood Items', type: 'link' },

	// 					{ path: '/Banking-and-Insurance', title: 'Banking & Insurance', type: 'bold' },

	// 					{ path: '/entertainment-memorabilia', title: 'Entertainment Memorabilia', type: 'bold' },
	// 					{ path: '/autographs', title: 'Autographs', type: 'bold' },
	// 				]
	// 			},
	// 			{
	// 				path: '/posters', title: 'Posters', type: 'sub', active: false, children: [
	// 					{ path: '/indian-posters', title: 'India', type: 'link' },
	// 					{ path: '/world-posters', title: 'World', type: 'link' },
						
	// 					{ path: '/travel-related-memorabilia', title: 'Travel Related Memorabilia', type: 'sub' },
	// 					{ path: '/tickets-passes', title: 'Tickets & Passes', type: 'link' },
	// 					{ path: '/others', title: 'Others', type: 'link' },

	// 					{ path: '/medals-tokens', title: 'Medals & Tokens', type: 'sub' },
	// 					{ path: '/medals', title: 'Medals', type: 'link' },
	// 					{ path: '/token', title: 'Token', type: 'link' },

	// 					{ path: '/metalware-tins', title: 'Metalware & Tins', type: 'bold' },

	// 					{ path: '/art-paintings', title: 'Art & paintings', type: 'bold' },
	// 					{ path: '/ephemera', title: 'Ephemera', type: 'bold' },
	// 				]
	// 			},
	// 			{
	// 				path: '/vintage-clocks-watches', title: 'Vintage Clocks & Watches', type: 'sub', active: false, children: [
	// 					{ path: '/vintage-miniature-toys', title: 'Vintage Miniature Toys', type: 'bold' },
	// 					{ path: '/pens-writing-instruments', title: 'Pens & Writing Instruments', type: 'bold' },
	// 					{ path: '/playing-cards', title: 'Playing Cards', type: 'bold' },
	// 					{ path: '/music-memorabilia', title: 'Music Memorabilia', type: 'bold' },
	// 					{ path: '/maps-atlas-globes', title: 'Maps Atlas & Globes', type: 'bold' },
	// 					{ path: '/souvenirs-novelties-curios', title: 'Souvenirs Novelties & Curios', type: 'bold' },
	// 					{ path: '/statues-artifacts', title: 'Statues Artifacts', type: 'bold' },
	// 					{ path: '/sign-boards-and-advertising-items', title: 'Sign Boards and Advertising Items', type: 'bold' },
	// 					{ path: '/old-news-papers-books', title: 'Old News Papers & Books', type: 'bold' },
	// 					{ path: '/other-collectable-items', title: 'Other Collectable items', type: 'bold' },
	// 				]
	// 			},
	// 		]
	// 	},
	// 	{
	// 		path: '/coins', title: 'Coins', type: 'sub', megaMenu: false, active: false, children: [
	// 			{
	// 				path: '/indian-coins', title: 'Indian Coins', type: 'sub', active: false, children: [
	// 					{ path: '/ancient-coins', title: 'Ancient Coins', type: 'link' },
	// 					{ path: '/mughal-sultanate-coins', title: 'Mughal & Sultanate', type: 'link' },
	// 					{ path: '/princely-state-indian-coins', title: 'Princely State Indian Coins', type: 'link' },
	// 					{ path: '/british-india-coin-king-william', title: 'British India - King William', type: 'link' },
	// 					{ path: '/british-india-coin-queen-Victoria', title: 'British India - Queen Victoria', type: 'link' },
	// 					{ path: '/british-india-coin-king-edward', title: 'British India - King Edward', type: 'link' },
	// 					{ path: '/british-india-coin-king-edward', title: 'British India - King George V', type: 'link' },
	// 					{ path: '/british-india-coin-king-george-vi', title: 'British India - King George VI', type: 'link' },
	// 					{ path: '/commemorative-indian-coins-post-1947', title: 'Commemorative Coins (Post 1947)', type: 'link' },
	// 					{ path: '/standard_issue_coins', title: 'Standard Issue Coins', type: 'link' },
	// 					{ path: '/errors-oddities-indian-coins', title: 'Errors & Oddities Indian Coins', type: 'link' },
	// 					{ path: '/unc-proof-sets', title: 'UNC & Proof Sets', type: 'link' },
	// 					{ path: '/india-portuguese-coins', title: 'India Portuguese Coins', type: 'link' },
	// 					{ path: '/others-indian-coins', title: 'Others Indian Coins', type: 'link' },
						
	// 				]
	// 			},
	// 			{
	// 				path: '/world-coins', title: 'World Coins', type: 'sub', active: false, children: [
	// 					{ path: '/asian-coins', title: 'Asian Coins', type: 'link' },
	// 					{ path: '/african-coins', title: 'African Coins', type: 'link' },
	// 					{ path: '/australian-coins', title: 'Australian Coins', type: 'link' },
	// 					{ path: '/european-coins', title: 'European Coins', type: 'link' },
	// 					{ path: '/errors-and-oddities-coins', title: 'Errors and Oddities Coins', type: 'link' },
	// 					{ path: '/american-coins', title: 'American Coins', type: 'link' },
	// 					{ path: '/other-countries-coins', title: 'Other Countries Coins', type: 'link' },
	// 				]
	// 			},
	// 		]
	// 	},
	// 	{
	// 		path: '/bank-note', title: 'Bank Note', type: 'sub', megaMenu: false, active: false, children: [
	// 			{
	// 				path: '/indian-notes', title: 'Indian Notes', type: 'sub', active: false, children: [
	// 					{ path: '/british-india-pre-1947-bank-notes', title: 'British India Pre 1947 Bank Notes', type: 'link' },
	// 					{ path: '/republic-india-post-1947-bank-notes', title: 'Republic India Post 1947 Bank Notes', type: 'link' },
	// 					{ path: '/errors-and-oddities-bank-notes-indian', title: 'Errors and Oddities Bank Notes Indian', type: 'link' },
	// 					{ path: '/princely-state-bank-notes', title: 'Princely State Bank Notes', type: 'link' },
	// 					{ path: '/others-indian-bank-notes', title: 'Others Indian Bank Notes', type: 'link' },
						
						
	// 					{ path: '/fancy-numbers', title: 'Fancy Number Notes', type: 'bold' },
	// 				]
	// 			},
	// 			{
	// 				path: '/world-notes', title: 'World Notes', type: 'sub', active: false, children: [
	// 					{ path: '/asian-bank-notes', title: 'Asian Bank Notes', type: 'link' },
	// 					{ path: '/african-bank-notes', title: 'African Bank Notes', type: 'link' },
	// 					{ path: '/australian-bank-notes', title: 'Australian Bank Notes', type: 'link' },
	// 					{ path: '/european-bank-notes', title: 'European Bank Notes', type: 'link' },
	// 					{ path: '/errors-and-oddities-bank-notes', title: 'Errors and Oddities Bank Notes', type: 'link' },
	// 					{ path: '/american-bank-notes', title: 'American Bank Notes', type: 'link' },
	// 					{ path: '/other-countries-bank-notes', title: 'Other Countries Bank Notes', type: 'link' },
	// 				]
	// 			},
	// 		]
	// 	},
	// 	{
	// 		path: '/accessories', title: 'Accessories', type: 'sub', megaMenu: false, active: false, children: [
	// 			{path: '/albums', title: 'Albums', type: 'bold', active: false},
	// 			{path: '/covers-jackets', title: 'Covers & Jackets', type: 'bold', active: false},
	// 			{path: '/magnifying-glass', title: 'Magnifying Glass', type: 'bold', active: false},
	// 			{path: '/water-mark-reader', title: 'Water Mark Reader', type: 'bold', active: false},
	// 			{path: '/other-accessories', title: 'Other Accessories', type: 'bold', active: false},
				
	// 		]
	// 	},
	// 	{
	// 		path: '/publication', title: 'Publication', type: 'sub', megaMenu: false, active: false, children: [
	// 			{
	// 				path: '/philately', title: 'Philately', type: 'sub', active: false, children: [
	// 					{ path: '/books', title: 'Books', type: 'extTabLink' },
	// 					{ path: '/Catalogues', title: 'Catalogues', type: 'extTabLink' },
	// 				]
	// 			},
	// 			{path: '/numismatics', title: 'Numismatics', type: 'bold', active: false},
	// 			{path: '/others-publications', title: 'Others Publications', type: 'bold', active: false}
	// 		]
	// 	},
	// 	{
	// 		path: '/Below_Rs50', title: 'Below Rs.50', type: 'sub', megaMenu: false, active: false, children: [
	// 			{
	// 				path: '/Stamps_Below_Rs50', title: 'Stamps Below Rs 50', type: 'sub', active: false, children: [
	// 					{ path: '/Indian_Stamps_Below_Rs50', title: 'Indian Stamps', type: 'link' },
	// 					{ path: '/World_Stamps_Below_Rs50', title: 'World Stamps', type: 'link' },
	// 					{ path: '/FDC_Below_Rs50', title: 'First Day Covers', type: 'link' },
	// 					{ path: '/Postal_History_Stationary_Below_Rs50', title: 'Postal History & Stationary', type: 'link' },
	// 					{ path: '/CourtFees_Revenues_Below_Rs50', title: 'Court Fees & Revenues', type: 'link' },
	// 				]
	// 			},
	// 			{
	// 				path: '/Coins_Below_Rs50', title: 'Coins Below Rs 50', type: 'sub', active: false, children: [
	// 					{ path: '/Indian_Coins_Below_Rs50', title: 'Indian Coins', type: 'link' },
	// 					{ path: '/World_Coins_Below_Rs50', title: 'World Coins', type: 'link' },
	// 				]
	// 			},
	// 			{
	// 				path: '/Bank_Notes_Below_Rs50', title: 'Bank Notes Below Rs 50', type: 'sub', active: false, children: [
	// 					{ path: '/Indian_BankNotes_Below_Rs50', title: 'Indian Bank Notes', type: 'link' },
	// 					{ path: '/World_BankNotes_Below_Rs50', title: 'World Bank Notes', type: 'link' },
	// 				]
	// 			},
	// 			{ path: '/Collectables_Below_Rs50', title: 'Collectables Below Rs 50', type: 'bold', active: false },
	// 		]
	// 	},

	// 	{path: 'https://auction.collectorbazar.com/OnlineAuction/index', title: 'Auction', type: 'extTabLink', megaMenu: false},
		
	// ];

	MENUITEMS: Menu[] = [
		// {
		// 	path: '', title: 'home', type: 'sub', active: true
		// },
		{
			path: '/categories/stamps', title: 'Stamps', type: 'sub', megaMenu: false, active: false, children: [
				{
					path: '/categories/indian-stamps', title: 'Indian Stamps', type: 'sub', active: false, children: [
						{ path: '/categories/pre-independence-pre-1947-indian-stamps', title: 'Pre-independence (Pre 1947)', type: 'link' },
						{ path: '/categories/pre-independence-states-indian-stamps', title: 'Pre-independence - States', type: 'link' },
						{ path: '/categories/post-independence-post-1947-indian-stamps', title: 'Post-independence (Post 1947)', type: 'link' },
						{ path: '/categories/indian-stamps-sheets-sheetlets', title: 'Sheets Sheetlets', type: 'link' },
						{ path: '/categories/miniature-sheets-stamps', title: 'Miniature Sheets', type: 'link' },
						{ path: '/categories/se-tenant-tete-beches', title: 'Se-tenant & Tete-Beches', type: 'link' },
						{ path: '/categories/definitives-stamps', title: 'Definitives Stamps', type: 'link' },
						{ path: '/categories/others-indian-stamps', title: 'Others Indian Stamps', type: 'link' },
					]
				},
				{
					path: '/categories/post-card-stationery', title: 'Post Card & Stationery', type: 'sub', active: false, children: [
						{ path: '/categories/indian-pre-post-card', title: 'Indian - Pre Independence', type: 'link' },
						{ path: '/categories/indian-post-post-card', title: 'Indian - Post Independence', type: 'link' },
						{ path: '/categories/indian-state-post-card', title: 'Indian State Post Card', type: 'link' },
						{ path: '/categories/crest-covers', title: 'Crest Covers', type: 'link' },
						{ path: '/categories/Picture-Post-Card', title: 'Picture Post Cards', type: 'link' },
						{ path: '/categories/bazar-post-card', title: 'Bazar Cards', type: 'link' },
						{ path: '/categories/advertisement-post-card', title: 'Advertisement Card', type: 'link' },
						{ path: '/categories/world-post-card', title: 'World Post Card', type: 'link' },
					]
				},
				{
					path: '/categories/topical-specialty', title: 'Topical & Specialty', type: 'sub', active: false, children: [
						{ path: '/categories/cartoons-animation', title: 'Cartoons & Animation', type: 'link' },
						{ path: '/categories/monument-building-architecture', title: 'Monument Building & Architecture', type: 'link' },
						{ path: '/categories/cinema-music-entertainment', title: 'Cinema Music & Entertainment', type: 'link' },
						{ path: '/categories/sports-stamps', title: 'Sports Stamps', type: 'link' },
						{ path: '/categories/animals-wild-life', title: 'Animals & Wild Life', type: 'link' },
						{ path: '/categories/flag-national-emblems', title: 'Flag & National Emblems', type: 'link' },
						{ path: '/categories/flora-fauna-nature', title: 'Flora Fauna & Nature', type: 'link' },
						{ path: '/categories/gandhi-other-personalities', title: 'Gandhi & Other Personalities', type: 'link' },
						{ path: '/categories/transport-space', title: 'Transport & Space', type: 'link' },
						{ path: '/categories/special_events', title: 'Special Events', type: 'link' },
						{ path: '/categories/other-topical-specialty', title: 'Other Topical', type: 'link' },
					]
				},
				{
					path: '/categories/other-country', title: 'Other Country', type: 'sub', active: false, children: [
						{ path: '/categories/us-stamps', title: 'US Stamps', type: 'link' },
						{ path: '/categories/uk-stamps', title: 'UK Stamps', type: 'link' },
						{ path: '/categories/middle-east-stamps', title: 'Middle East Stamps', type: 'link' },
						{ path: '/categories/asia-stamps', title: 'Asia Stamps', type: 'link' },
						{ path: '/categories/british-commonwealth-stamps', title: 'British Commonwealth Stamps', type: 'link' },
						{ path: '/categories/europe-stamps', title: 'Europe Stamps', type: 'link' },
						{ path: '/categories/africa', title: 'Africa Stamps', type: 'link' },
						{ path: '/categories/south-american-stamps', title: 'South American Stamps', type: 'link' },
						{ path: '/categories/others-stamps', title: 'Others Stamps', type: 'link' },
					]
				},
				{
					path: '/categories/postal-history', title: 'Postal History', type: 'sub', active: false, children: [
						{ path: '/categories/indian-postal-history', title: 'Indian Postal History', type: 'link' },
						{ path: '/categories/world-postal-history', title: 'World Postal History', type: 'link' },
					]
				},
				{
					path: '/categories/fiscals-revenues', title: 'Fiscals & Revenues', type: 'sub', active: false, children: [
						{ path: '/categories/stamp-paper', title: 'Stamp Paper', type: 'link' },
						{ path: '/categories/documents', title: 'Documents', type: 'link' },
						{ path: '/categories/court-fee', title: 'Court Fee', type: 'link' },
						{ path: '/categories/other-fiscal-items', title: 'Other Fiscal', type: 'link' },
					]
				},
				{
					path: '/categories/fdc-special-covers-brochures', title: 'FDC & Brochures', type: 'sub', active: false, children: [
						{ path: '/categories/india-fdc-special-covers-brochures', title: 'India', type: 'link' },
						{ path: '/categories/world-fdc-special-covers-brochures', title: 'World', type: 'link' },
					]
				},
				{
					path: '/categories/special-covers-maxim-event-cover', title: 'Special Covers Maxim Cards & Event Cover', type: 'sub', active: false, children: [
						{ path: '/categories/india-special-covers-maxim-event-cover', title: 'India Special Covers Maxim Cards & Event Cover', type: 'link' },
						{ path: '/categories/world-special-covers-maxim-event-cover', title: 'World Special Covers Maxim Cards & Event Cover', type: 'link' },
					]
				},
				{
					path: '/categories/india-documents', title: 'India Documents', type: 'sub', active: false, children: [
						{ path: '/categories/pre-independence-india-documents-pre-1947', title: 'Pre-independence', type: 'link' },
						{ path: '/categories/post-independence-india-documents-post-1947', title: 'Post-independence', type: 'link' },
					]
				},
				{
					path: '/categories/errors-varieties', title: 'Errors & Varieties', type: 'sub', active: false, children: [
						{ path: '/categories/india-errors-varieties', title: 'India Errors & Varieties', type: 'link' },
						{ path: '/categories/world-errors-varieties', title: 'World Errors & Varieties', type: 'link' },
					]
				},
				{
					path: '/categories/odd-unusuals', title: 'Odd & Unusuals', type: 'sub', active: false, children: [
						{ path: '/categories/india-odd-unusuals', title: 'India Odd & Unusuals', type: 'link' },
						{ path: '/categories/world-odd-unusuals', title: 'World Odd & Unusuals', type: 'link' },
					]
				},
			]
		},
		{
			path: '/categories/collectables', title: 'Collectables', type: 'sub', megaMenu: true, active: false, children: [
				{
					path: '/categories/labels', title: 'Labels', type: 'sub', active: false, children: [
						{ path: '/categories/matchbox-labels', title: 'Matchbox', type: 'link' },
						{ path: '/categories/products-labels', title: 'Products', type: 'link' },
						{ path: '/categories/patriotic-labels', title: 'Patriotic', type: 'link' },
						{ path: '/categories/other-labels', title: 'Others', type: 'link' },
					]
				},
				{
					path: '/categories/hundis-share-certificate-bonds', title: 'Hundis Share Certificate & Bonds', type: 'sub', active: false, children: [
						{ path: '/categories/hundi', title: 'Hundi', type: 'link' },
						{ path: '/categories/share-certificate', title: 'Share Certificate', type: 'link' },
						{ path: '/categories/Bonds', title: 'Bonds', type: 'link' },
						{ path: '/categories/cheques', title: 'Cheques', type: 'link' },
					]
				},
				{
					path: '/categories/airline-items', title: 'Airline items', type: 'sub', active: false, children: [
						{ path: '/categories/air-tickets', title: 'Air Tickets', type: 'link' },
						{ path: '/categories/first-flight-covers', title: 'First Flight Covers', type: 'link' },
					]
				},
				{
					path: '/categories/photographs', title: 'Photographs', type: 'sub', active: false, children: [
						{ path: '/categories/indian-photographs', title: 'India Photographs', type: 'link' },
						{ path: '/categories/world-photographs', title: 'World Photographs', type: 'link' },
					]
				},
				{
					path: '/categories/movies-films', title: 'Movies & Films', type: 'sub', active: false, children: [
						{ path: '/categories/bollywood-items', title: 'Bollywood Items', type: 'link' },
						{ path: '/categories/hollywood-kollywood-items', title: 'Hollywood & Kollywood Items', type: 'link' },
					]
				},
				{
					path: '/categories/posters', title: 'Posters', type: 'sub', active: false, children: [
						{ path: '/categories/indian-posters', title: 'India', type: 'link' },
						{ path: '/categories/world-posters', title: 'World', type: 'link' },
					]
				},
				{
					path: '/categories/travel-related-memorabilia', title: 'Travel Related Memorabilia', type: 'sub', active: false, children: [
						{ path: '/categories/tickets-passes', title: 'Tickets & Passes', type: 'link' },
						{ path: '/categories/others', title: 'Others', type: 'link' },
					]
				},
				{
					path: '/categories/medals-tokens', title: 'Medals & Tokens', type: 'sub', active: false, children: [
						{ path: '/categories/medals', title: 'Medals', type: 'link' },
						{ path: '/categories/token', title: 'Token', type: 'link' },
					]
				},
				{ path: '/categories/calendar', title: 'Calendar', type: 'link' },
				{ path: '/categories/comics', title: 'Comics', type: 'link' },
				{ path: '/categories/Banking-and-Insurance', title: 'Banking & Insurance', type: 'link' },
				{ path: '/categories/entertainment-memorabilia', title: 'Entertainment Memorabilia', type: 'link' },
				{ path: '/categories/autographs', title: 'Autographs', type: 'link' },
				{ path: '/categories/metalware-tins', title: 'Metalware & Tins', type: 'link' },
				{ path: '/categories/art-paintings', title: 'Art & paintings', type: 'link' },
				{ path: '/categories/ephemera', title: 'Ephemera', type: 'link' },
				{ path: '/categories/vintage-clocks-watches', title: 'Vintage Clocks & Watches', type: 'link' },
				{ path: '/categories/vintage-miniature-toys', title: 'Vintage Miniature Toys', type: 'link' },
				{ path: '/categories/pens-writing-instruments', title: 'Pens & Writing Instruments', type: 'link' },
				{ path: '/categories/playing-cards', title: 'Playing Cards', type: 'link' },
				{ path: '/categories/music-memorabilia', title: 'Music Memorabilia', type: 'link' },
				{ path: '/categories/maps-atlas-globes', title: 'Maps Atlas & Globes', type: 'link' },
				{ path: '/categories/souvenirs-novelties-curios', title: 'Souvenirs Novelties & Curios', type: 'link' },
				{ path: '/categories/statues-artifacts', title: 'Statues Artifacts', type: 'link' },
				{ path: '/categories/sign-boards-and-advertising-items', title: 'Sign Boards and Advertising Items', type: 'link' },
				{ path: '/categories/old-news-papers-books', title: 'Old News Papers & Books', type: 'link' },
				{ path: '/categories/other-collectable-items', title: 'Other Collectable items', type: 'link' },
			]
		},
		{
			path: '/categories/coins', title: 'Coins', type: 'sub', megaMenu: false, active: false, children: [
				{
					path: '/categories/indian-coins', title: 'Indian Coins', type: 'sub', active: false, children: [
						{ path: '/categories/ancient-coins', title: 'Ancient Coins', type: 'link' },
						{ path: '/categories/mughal-sultanate-coins', title: 'Mughal & Sultanate', type: 'link' },
						{ path: '/categories/princely-state-indian-coins', title: 'Princely State Indian Coins', type: 'link' },
						{ path: '/categories/british-india-coin-king-william', title: 'British India - King William', type: 'link' },
						{ path: '/categories/british-india-coin-queen-Victoria', title: 'British India - Queen Victoria', type: 'link' },
						{ path: '/categories/british-india-coin-king-edward', title: 'British India - King Edward', type: 'link' },
						{ path: 'categories/british-india-coin-king-george-v', title: 'British India - King George V', type: 'link' },
						{ path: '/categories/british-india-coin-king-george-vi', title: 'British India - King George VI', type: 'link' },
						{ path: '/categories/colonial-presidency-coins', title: 'Colonial & Presidency', type: 'link' },
						{ path: '/categories/commemorative-indian-coins-post-1947', title: 'Commemorative Coins (Post 1947)', type: 'link' },
						{ path: '/categories/standard_issue_coins', title: 'Standard Issue Coins', type: 'link' },
						{ path: '/categories/errors-oddities-indian-coins', title: 'Errors & Oddities Indian Coins', type: 'link' },
						{ path: '/categories/unc-proof-sets', title: 'UNC & Proof Sets', type: 'link' },
						// { path: '/categories/india-portuguese-coins', title: 'India Portuguese Coins', type: 'link' },
						{ path: '/categories/others-indian-coins', title: 'Others Indian Coins', type: 'link' },
						
					]
				},
				{
					path: '/categories/world-coins', title: 'World Coins', type: 'sub', active: false, children: [
						{ path: '/categories/asian-coins', title: 'Asian Coins', type: 'link' },
						{ path: '/categories/african-coins', title: 'African Coins', type: 'link' },
						{ path: '/categories/australian-coins', title: 'Australian Coins', type: 'link' },
						{ path: '/categories/european-coins', title: 'European Coins', type: 'link' },
						{ path: '/categories/errors-and-oddities-coins', title: 'Errors and Oddities Coins', type: 'link' },
						{ path: '/categories/american-coins', title: 'American Coins', type: 'link' },
						{ path: '/categories/other-countries-coins', title: 'Other Countries Coins', type: 'link' },
					]
				},
			]
		},
		{
			path: '/categories/bank-note', title: 'Bank Note', type: 'sub', megaMenu: false, active: false, children: [
				{
					path: '/categories/indian-notes', title: 'Indian Notes', type: 'sub', active: false, children: [
						{ path: '/categories/british-india-pre-1947-bank-notes', title: 'British India Pre 1947 Bank Notes', type: 'link' },
						{ path: '/categories/republic-india-post-1947-bank-notes', title: 'Republic India Post 1947 Bank Notes', type: 'link' },
						{ path: '/categories/errors-and-oddities-bank-notes-indian', title: 'Errors and Oddities Bank Notes Indian', type: 'link' },
						{ path: '/categories/princely-state-bank-notes', title: 'Princely State Bank Notes', type: 'link' },
						{ path: '/categories/others-indian-bank-notes', title: 'Others Indian Bank Notes', type: 'link' },
						{ path: '/categories/indian_bundles', title: 'Indian Bundles', type: 'link' },
						
						
						// { path: '/categories/fancy-numbers', title: 'Fancy Number Notes', type: 'link' },
					]
				},
				{
					path: '/categories/world-notes', title: 'World Notes', type: 'sub', active: false, children: [
						{ path: '/categories/asian-bank-notes', title: 'Asian Bank Notes', type: 'link' },
						{ path: '/categories/african-bank-notes', title: 'African Bank Notes', type: 'link' },
						{ path: '/categories/australian-bank-notes', title: 'Australian Bank Notes', type: 'link' },
						{ path: '/categories/european-bank-notes', title: 'European Bank Notes', type: 'link' },
						{ path: '/categories/errors-and-oddities-bank-notes', title: 'Errors and Oddities Bank Notes', type: 'link' },
						{ path: '/categories/american-bank-notes', title: 'American Bank Notes', type: 'link' },
						{ path: '/categories/other-countries-bank-notes', title: 'Other Countries Bank Notes', type: 'link' },
					]
				},
				{
					path: '/categories/fancy-numbers', title: 'Fancy Numbers Notes', type: 'link'
				},
			]
		},
		{
			path: '/categories/accessories', title: 'Accessories', type: 'sub', megaMenu: false, active: false, children: [
				{ path: '/categories/albums', title: 'Albums', type: 'link' },
				{ path: '/categories/covers-jackets', title: 'Covers & Jackets', type: 'link' },
				{ path: '/categories/magnifying-glass', title: 'Magnifying Glass', type: 'link' },
				{ path: '/categories/water-mark-reader', title: 'Water Mark Reader', type: 'link' },
				{ path: '/categories/other-accessories', title: 'Other Accessories', type: 'link' },
			]
		},
		// {
		// 	path: '/categories/publication', title: 'Publication', type: 'sub', megaMenu: false, active: false, children: [
		// 		{
		// 			path: '/categories/philately', title: 'Philately', type: 'sub', active: false, children: [
		// 				{ path: '/categories/books', title: 'Books', type: 'link' },
		// 				{ path: '/categories/Catalogues', title: 'Catalogues', type: 'link' }
		// 			]
		// 		},
		// 		{
		// 			path: '/categories/numismatics', title: 'Numismatics', type: 'link'
		// 		},
		// 		{
		// 			path: '/categories/others-publications', title: 'Others Publications', type: 'link'
		// 		},
		// 	]
		// },
		{
			path: '/categories/books', title: 'Books', type: 'sub', megaMenu: false, active: false, children: [
				{
					path: '/categories/philately', title: 'Philately', type: 'sub', active: false, children: [
						{ path: '/categories/philately-books', title: 'Books', type: 'link' },
						{ path: '/categories/philately-catalogue', title: 'Catalogues', type: 'link' },
						{ path: '/categories/philately-journals', title: 'Journals', type: 'link' }
					]
				},
				{
					path: '/categories/numismatics', title: 'Numismatics', type: 'sub', active: false, children: [
						{ path: '/categories/numismatic-books', title: 'Books', type: 'link' },
						{ path: '/categories/numismatic-catalogue', title: 'Catalogues', type: 'link' },
						{ path: '/categories/numismatic-journals', title: 'Journals', type: 'link' }
					]
				},
				{
					path: '/categories/architecture-monument-books', title: 'Architecture & Monument Building', type: 'link'
				},
				{
					path: '/categories/art-paintings-books', title: 'Art and Paintings', type: 'link'
				},
				{
					path: '/categories/sports-books', title: 'Sports', type: 'link'
				},
				{
					path: '/categories/historical-books', title: 'Historical', type: 'sub', active: false, children: [
						{ path: '/categories/indian-historical-books', title: 'Indian Historical Books', type: 'link' },
						{ path: '/categories/world-historical-books', title: 'World Historical Books', type: 'link' }
					]
				},
				{
					path: '/categories/royalties-maharajas-books', title: 'Royalties & Maharajas', type: 'link'
				},
				{
					path: '/categories/animals-birds-books', title: 'Animals & Birds', type: 'link'
				},
				{
					path: '/categories/other-books', title: 'Other Books', type: 'link'
				},
			]
		},
		{
			path: '/categories/Below_Rs50', title: 'Below Rs.50', type: 'sub', megaMenu: false, active: false, children: [
				{
					path: '/categories/Stamps_Below_Rs50', title: 'Stamps Below Rs 50', type: 'sub', active: false, children: [
						{ path: '/categories/Indian_Stamps_Below_Rs50', title: 'Indian Stamps', type: 'link' },
						{ path: '/categories/World_Stamps_Below_Rs50', title: 'World Stamps', type: 'link' },
						{ path: '/categories/FDC_Below_Rs50', title: 'First Day Covers', type: 'link' },
						{ path: '/categories/Postal_History_Stationary_Below_Rs50', title: 'Postal History & Stationary', type: 'link' },
						{ path: '/categories/CourtFees_Revenues_Below_Rs50', title: 'Court Fees & Revenues', type: 'link' },
					]
				},
				{
					path: '/categories/Coins_Below_Rs50', title: 'Coins Below Rs 50', type: 'sub', active: false, children: [
						{ path: '/categories/Indian_Coins_Below_Rs50', title: 'Indian Coins', type: 'link' },
						{ path: '/categories/World_Coins_Below_Rs50', title: 'World Coins', type: 'link' },
					]
				},
				{
					path: '/categories/Bank_Notes_Below_Rs50', title: 'Bank Notes Below Rs 50', type: 'sub', active: false, children: [
						{ path: '/categories/Indian_BankNotes_Below_Rs50', title: 'Indian Bank Notes', type: 'link' },
						{ path: '/categories/World_BankNotes_Below_Rs50', title: 'World Bank Notes', type: 'link' },
					]
				},
				{ path: '/categories/Collectables_Below_Rs50', title: 'Collectables Below Rs 50', type: 'link', active: false },
			]
		},
		// {path: '/occasion-notes', title: 'Occasion Notes', type: 'extTabLink', megaMenu: false},
		{path: 'https://auction.collectorbazar.com/OnlineAuction/index', title: 'Auction', type: 'extTabLink', megaMenu: false},
		
		{
			path: 'https://resources.collectorbazar.com/', title: 'Resources', type: 'sub', megaMenu: false, active: false, children: [
				{ path: 'https://resources.collectorbazar.com/collections-organiser.php?category=Special Covers', title: 'Special Covers', type: 'link' },
				{ path: 'https://resources.collectorbazar.com/knowledge.php', title: 'Knowledge Center', type: 'link' },
				{ path: 'https://resources.collectorbazar.com/my_collections.php', title: 'Organiser', type: 'link' },
				{ path: 'https://resources.collectorbazar.com/exhibitions.php', title: 'Exhibitions', type: 'link' },
				{ path: 'https://resources.collectorbazar.com/Exhibits/exhibits.php', title: 'Exhibits', type: 'link' },
			]
		},
		// {path: 'https://resources.collectorbazar.com/', title: 'Resources', type: 'extTabLink', megaMenu: false},
		
	];

	// MENUITEMS: Menu[] = [
	// 	{
	// 		path: '/', title: 'home', type: 'sub', active: true
	// 	},
	// 	{
	// 		path: '/stamps', title: 'Stamps', type: 'sub', megaMenu: false, active: false, children: [
	// 			{
	// 				path: '/indian-stamps', title: 'Indian Stamps', type: 'sub', active: false, children: [
	// 					{ path: '/pre-independence-pre-1947-indian-stamps', title: 'Pre-independence (Pre 1947)', type: 'link' },
	// 					{ path: '/post-independence-post-1947-indian-stamps', title: 'Post-independence (Post 1947)', type: 'link' },
	// 					{ path: '/indian-stamps-sheets-sheetlets', title: 'Sheets Sheetlets', type: 'link' },
	// 					{ path: '/miniature-sheets-stamps', title: 'Miniature Sheets Stamps', type: 'link' },
	// 					{ path: '/definitives-stamps', title: 'Definitives Stamps', type: 'link' },
	// 					{ path: '/others-indian-stamps', title: 'Others Indian Stamps', type: 'link' },
	// 				]
	// 			},
	// 			{
	// 				path: '/post-card-stationery', title: 'Post Card & Stationery', type: 'sub', active: false, children: [
	// 					{ path: '/indian-pre-post-card', title: 'Indian - Pre Independence', type: 'link' },
	// 					{ path: '/indian-post-post-card', title: 'Indian - Post Independence', type: 'link' },
	// 					{ path: '/indian-state-post-card', title: 'Indian State Post Card', type: 'link' },
	// 					{ path: '/crest-covers', title: 'Crest Covers', type: 'link' },
	// 					{ path: '/Picture-Post-Card', title: 'Picture Post Cards', type: 'link' },
	// 					{ path: '/bazar-post-card', title: 'Bazar Cards', type: 'link' },
	// 					{ path: '/advertisement-post-card', title: 'Advertisement Card', type: 'link' },
	// 					{ path: '/world-post-card', title: 'World Post Card', type: 'link' },
	// 				]
	// 			},
	// 			{
	// 				path: '/topical-specialty', title: 'Topical & Specialty', type: 'sub', active: false, children: [
	// 					{ path: '/cartoons-animation', title: 'Cartoons & Animation', type: 'link' },
	// 					{ path: '/monument-building-architecture', title: 'Monument Building & Architecture', type: 'link' },
	// 					{ path: '/cinema-music-entertainment', title: 'Cinema Music & Entertainment', type: 'link' },
	// 					{ path: '/sports-stamps', title: 'Sports Stamps', type: 'link' },
	// 					{ path: '/animals-wild-life', title: 'Animals & Wild Life', type: 'link' },
	// 					{ path: '/flag-national-emblems', title: 'Flag & National Emblems', type: 'link' },
	// 					{ path: '/flora-fauna-nature', title: 'Flora Fauna & Nature', type: 'link' },
	// 					{ path: '/gandhi-other-personalities', title: 'Gandhi & Other Personalities', type: 'link' },
	// 					{ path: '/transport-space', title: 'Transport & Space', type: 'link' },
	// 					{ path: '/special_events', title: 'Special Events', type: 'link' },
	// 					{ path: '/other-topical-specialty', title: 'Other Topical', type: 'link' },
	// 				]
	// 			},
	// 			{
	// 				path: '/other-country', title: 'Other Country', type: 'sub', active: false, children: [
	// 					{ path: '/us-stamps', title: 'US Stamps', type: 'link' },
	// 					{ path: '/uk-stamps', title: 'UK Stamps', type: 'link' },
	// 					{ path: '/middle-east-stamps', title: 'Middle East Stamps', type: 'link' },
	// 					{ path: '/asia-stamps', title: 'Asia Stamps', type: 'link' },
	// 					{ path: '/british-commonwealth-stamps', title: 'British Commonwealth Stamps', type: 'link' },
	// 					{ path: '/europe-stamps', title: 'Europe Stamps', type: 'link' },
	// 					{ path: '/africa', title: 'Africa Stamps', type: 'link' },
	// 					{ path: '/south-american-stamps', title: 'South American Stamps', type: 'link' },
	// 					{ path: '/others-stamps', title: 'Others Stamps', type: 'link' },
	// 				]
	// 			},
	// 			{
	// 				path: '/postal-history', title: 'Postal History', type: 'sub', active: false, children: [
	// 					{ path: '/indian-postal-history', title: 'Indian Postal History', type: 'link' },
	// 					{ path: '/world-postal-history', title: 'World Postal History', type: 'link' },
	// 				]
	// 			},
	// 			{
	// 				path: '/fiscals-revenues', title: 'Fiscals & Revenues', type: 'sub', active: false, children: [
	// 					{ path: '/stamp-paper', title: 'Stamp Paper', type: 'link' },
	// 					{ path: '/documents', title: 'Documents', type: 'link' },
	// 					{ path: '/court-fee', title: 'Court Fee', type: 'link' },
	// 					{ path: '/other-fiscal-items', title: 'Other Fiscal', type: 'link' },
	// 				]
	// 			},
	// 			{
	// 				path: '/fdc-special-covers-brochures', title: 'FDC & Brochures', type: 'sub', active: false, children: [
	// 					{ path: '/india-fdc-special-covers-brochures', title: 'India', type: 'link' },
	// 					{ path: '/world-fdc-special-covers-brochures', title: 'World', type: 'link' },
	// 				]
	// 			},
	// 			{
	// 				path: '/special-covers-maxim-event-cover', title: 'Special Covers Maxim Cards & Event Cover', type: 'sub', active: false, children: [
	// 					{ path: '/india-special-covers-maxim-event-cover', title: 'India Special Covers Maxim Cards & Event Cover', type: 'link' },
	// 					{ path: '/world-special-covers-maxim-event-cover', title: 'World Special Covers Maxim Cards & Event Cover', type: 'link' },
	// 				]
	// 			},
	// 			{
	// 				path: '/india-documents', title: 'India Documents', type: 'sub', active: false, children: [
	// 					{ path: '/pre-independence-india-documents-pre-1947', title: 'Pre-independence', type: 'link' },
	// 					{ path: '/post-independence-india-documents-post-1947', title: 'Post-independence', type: 'link' },
	// 				]
	// 			},
	// 			{
	// 				path: '/errors-varieties', title: 'Errors & Varieties', type: 'sub', active: false, children: [
	// 					{ path: '/india-errors-varieties', title: 'India Errors & Varieties', type: 'link' },
	// 					{ path: '/world-errors-varieties', title: 'World Errors & Varieties', type: 'link' },
	// 				]
	// 			},
	// 			{
	// 				path: '/odd-unusuals', title: 'Odd & Unusuals', type: 'sub', active: false, children: [
	// 					{ path: '/india-odd-unusuals', title: 'India Odd & Unusuals', type: 'link' },
	// 					{ path: '/world-odd-unusuals', title: 'World Odd & Unusuals', type: 'link' },
	// 				]
	// 			},
	// 		]
	// 	},
	// 	{
	// 		path: '/collectables', title: 'Collectables', type: 'sub', megaMenu: true, active: false, children: [
	// 			{
	// 				path: '/labels', title: 'Labels', type: 'sub', active: false, children: [
	// 					{ path: '/matchbox-labels', title: 'Matchbox', type: 'link' },
	// 					{ path: '/products-labels', title: 'Products', type: 'link' },
	// 					{ path: '/patriotic-labels', title: 'Patriotic', type: 'link' },
	// 					{ path: '/other-labels', title: 'Others', type: 'link' },
	// 				]
	// 			},
	// 			{
	// 				path: '/hundis-share-certificate-bonds', title: 'Hundis Share Certificate & Bonds', type: 'sub', active: false, children: [
	// 					{ path: '/hundi', title: 'Hundi', type: 'link' },
	// 					{ path: '/share-certificate', title: 'Share Certificate', type: 'link' },
	// 					{ path: '/Bonds', title: 'Bonds', type: 'link' },
	// 					{ path: '/cheques', title: 'Cheques', type: 'link' },
	// 				]
	// 			},
	// 			{
	// 				path: '/airline-items', title: 'Airline items', type: 'sub', active: false, children: [
	// 					{ path: '/air-tickets', title: 'Air Tickets', type: 'link' },
	// 					{ path: '/first-flight-covers', title: 'First Flight Covers', type: 'link' },
	// 				]
	// 			},
	// 			{
	// 				path: '/photographs', title: 'Photographs', type: 'sub', active: false, children: [
	// 					{ path: '/indian-photographs', title: 'India Photographs', type: 'link' },
	// 					{ path: '/world-photographs', title: 'World Photographs', type: 'link' },
	// 				]
	// 			},
	// 			{
	// 				path: '/movies-films', title: 'Movies & Films', type: 'sub', active: false, children: [
	// 					{ path: '/bollywood-items', title: 'Bollywood Items', type: 'link' },
	// 					{ path: '/hollywood-kollywood-items', title: 'Hollywood & Kollywood Items', type: 'link' },
	// 				]
	// 			},
	// 			{
	// 				path: '/posters', title: 'Posters', type: 'sub', active: false, children: [
	// 					{ path: '/indian-posters', title: 'India', type: 'link' },
	// 					{ path: '/world-posters', title: 'World', type: 'link' },
	// 				]
	// 			},
	// 			{
	// 				path: '/travel-related-memorabilia', title: 'Travel Related Memorabilia', type: 'sub', active: false, children: [
	// 					{ path: '/tickets-passes', title: 'Tickets & Passes', type: 'link' },
	// 					{ path: '/others', title: 'Others', type: 'link' },
	// 				]
	// 			},
	// 			{
	// 				path: '/medals-tokens', title: 'Medals & Tokens', type: 'sub', active: false, children: [
	// 					{ path: '/medals', title: 'Medals', type: 'link' },
	// 					{ path: '/token', title: 'Token', type: 'link' },
	// 				]
	// 			},
	// 			{ path: '/calendar', title: 'Calendar', type: 'link' },
	// 			{ path: '/comics', title: 'Comics', type: 'link' },
	// 			{ path: '/Banking-and-Insurance', title: 'Banking & Insurance', type: 'link' },
	// 			{ path: '/entertainment-memorabilia', title: 'Entertainment Memorabilia', type: 'link' },
	// 			{ path: '/autographs', title: 'Autographs', type: 'link' },
	// 			{ path: '/metalware-tins', title: 'Metalware & Tins', type: 'link' },
	// 			{ path: '/art-paintings', title: 'Art & paintings', type: 'link' },
	// 			{ path: '/ephemera', title: 'Ephemera', type: 'link' },
	// 			{ path: '/vintage-clocks-watches', title: 'Vintage Clocks & Watches', type: 'link' },
	// 			{ path: '/vintage-miniature-toys', title: 'Vintage Miniature Toys', type: 'link' },
	// 			{ path: '/pens-writing-instruments', title: 'Pens & Writing Instruments', type: 'link' },
	// 			{ path: '/playing-cards', title: 'Playing Cards', type: 'link' },
	// 			{ path: '/music-memorabilia', title: 'Music Memorabilia', type: 'link' },
	// 			{ path: '/maps-atlas-globes', title: 'Maps Atlas & Globes', type: 'link' },
	// 			{ path: '/souvenirs-novelties-curios', title: 'Souvenirs Novelties & Curios', type: 'link' },
	// 			{ path: '/statues-artifacts', title: 'Statues Artifacts', type: 'link' },
	// 			{ path: '/sign-boards-and-advertising-items', title: 'Sign Boards and Advertising Items', type: 'link' },
	// 			{ path: '/old-news-papers-books', title: 'Old News Papers & Books', type: 'link' },
	// 			{ path: '/other-collectable-items', title: 'Other Collectable items', type: 'link' },
	// 		]
	// 	},
	// 	{
	// 		path: '/coins', title: 'Coins', type: 'sub', megaMenu: false, active: false, children: [
	// 			{
	// 				path: '/indian-coins', title: 'Indian Coins', type: 'sub', active: false, children: [
	// 					{ path: '/ancient-coins', title: 'Ancient Coins', type: 'link' },
	// 					{ path: '/mughal-sultanate-coins', title: 'Mughal & Sultanate', type: 'link' },
	// 					{ path: '/princely-state-indian-coins', title: 'Princely State Indian Coins', type: 'link' },
	// 					{ path: '/british-india-coin-king-william', title: 'British India - King William', type: 'link' },
	// 					{ path: '/british-india-coin-queen-Victoria', title: 'British India - Queen Victoria', type: 'link' },
	// 					{ path: '/british-india-coin-king-edward', title: 'British India - King Edward', type: 'link' },
	// 					{ path: '/british-india-coin-king-edward', title: 'British India - King George V', type: 'link' },
	// 					{ path: '/british-india-coin-king-george-vi', title: 'British India - King George VI', type: 'link' },
	// 					{ path: '/commemorative-indian-coins-post-1947', title: 'Commemorative Coins (Post 1947)', type: 'link' },
	// 					{ path: '/standard_issue_coins', title: 'Standard Issue Coins', type: 'link' },
	// 					{ path: '/errors-oddities-indian-coins', title: 'Errors & Oddities Indian Coins', type: 'link' },
	// 					{ path: '/unc-proof-sets', title: 'UNC & Proof Sets', type: 'link' },
	// 					{ path: '/india-portuguese-coins', title: 'India Portuguese Coins', type: 'link' },
	// 					{ path: '/others-indian-coins', title: 'Others Indian Coins', type: 'link' },
						
	// 				]
	// 			},
	// 			{
	// 				path: '/world-coins', title: 'World Coins', type: 'sub', active: false, children: [
	// 					{ path: '/asian-coins', title: 'Asian Coins', type: 'link' },
	// 					{ path: '/african-coins', title: 'African Coins', type: 'link' },
	// 					{ path: '/australian-coins', title: 'Australian Coins', type: 'link' },
	// 					{ path: '/european-coins', title: 'European Coins', type: 'link' },
	// 					{ path: '/errors-and-oddities-coins', title: 'Errors and Oddities Coins', type: 'link' },
	// 					{ path: '/american-coins', title: 'American Coins', type: 'link' },
	// 					{ path: '/other-countries-coins', title: 'Other Countries Coins', type: 'link' },
	// 				]
	// 			},
	// 		]
	// 	},
	// 	{
	// 		path: '/bank-note', title: 'Bank Note', type: 'sub', megaMenu: false, active: false, children: [
	// 			{
	// 				path: '/indian-notes', title: 'Indian Notes', type: 'sub', active: false, children: [
	// 					{ path: '/british-india-pre-1947-bank-notes', title: 'British India Pre 1947 Bank Notes', type: 'link' },
	// 					{ path: '/republic-india-post-1947-bank-notes', title: 'Republic India Post 1947 Bank Notes', type: 'link' },
	// 					{ path: '/errors-and-oddities-bank-notes-indian', title: 'Errors and Oddities Bank Notes Indian', type: 'link' },
	// 					{ path: '/princely-state-bank-notes', title: 'Princely State Bank Notes', type: 'link' },
	// 					{ path: '/others-indian-bank-notes', title: 'Others Indian Bank Notes', type: 'link' },
						
						
	// 					{ path: '/fancy-numbers', title: 'Fancy Number Notes', type: 'link' },
	// 				]
	// 			},
	// 			{
	// 				path: '/world-notes', title: 'World Notes', type: 'sub', active: false, children: [
	// 					{ path: '/asian-bank-notes', title: 'Asian Bank Notes', type: 'link' },
	// 					{ path: '/african-bank-notes', title: 'African Bank Notes', type: 'link' },
	// 					{ path: '/australian-bank-notes', title: 'Australian Bank Notes', type: 'link' },
	// 					{ path: '/european-bank-notes', title: 'European Bank Notes', type: 'link' },
	// 					{ path: '/errors-and-oddities-bank-notes', title: 'Errors and Oddities Bank Notes', type: 'link' },
	// 					{ path: '/american-bank-notes', title: 'American Bank Notes', type: 'link' },
	// 					{ path: '/other-countries-bank-notes', title: 'Other Countries Bank Notes', type: 'link' },
	// 				]
	// 			},
	// 		]
	// 	},
	// 	{
	// 		path: '/accessories', title: 'Accessories', type: 'sub', megaMenu: false, active: false, children: [
	// 			{ path: '/albums', title: 'Albums', type: 'link' },
	// 			{ path: '/covers-jackets', title: 'Covers & Jackets', type: 'link' },
	// 			{ path: '/magnifying-glass', title: 'Magnifying Glass', type: 'link' },
	// 			{ path: '/water-mark-reader', title: 'Water Mark Reader', type: 'link' },
	// 			{ path: '/other-accessories', title: 'Other Accessories', type: 'link' },
	// 		]
	// 	},
	// 	// {
	// 	// 	path: '/publication', title: 'Publication', type: 'sub', megaMenu: false, active: false, children: [
	// 	// 		{
	// 	// 			path: '/philately', title: 'Philately', type: 'sub', active: false, children: [
	// 	// 				{ path: '/books', title: 'Books', type: 'link' },
	// 	// 				{ path: '/Catalogues', title: 'Catalogues', type: 'link' }
	// 	// 			]
	// 	// 		},
	// 	// 		{
	// 	// 			path: '/numismatics', title: 'Numismatics', type: 'link'
	// 	// 		},
	// 	// 		{
	// 	// 			path: '/others-publications', title: 'Others Publications', type: 'link'
	// 	// 		},
	// 	// 	]
	// 	// },
	// 	{
	// 		path: '/books', title: 'Books', type: 'sub', megaMenu: false, active: false, children: [
	// 			{
	// 				path: '/philately', title: 'Philately', type: 'sub', active: false, children: [
	// 					{ path: '/philately-books', title: 'Books', type: 'link' },
	// 					{ path: '/philately-catalogue', title: 'Catalogues', type: 'link' },
	// 					{ path: '/philately-journals', title: 'Journals', type: 'link' }
	// 				]
	// 			},
	// 			{
	// 				path: '/numismatic', title: 'Numismatic', type: 'sub', active: false, children: [
	// 					{ path: '/numismatic-books', title: 'Books', type: 'link' },
	// 					{ path: '/numismatic-catalogue', title: 'Catalogues', type: 'link' },
	// 					{ path: '/numismatic-journals', title: 'Journals', type: 'link' }
	// 				]
	// 			},
	// 			{
	// 				path: '/architecture-monument-books', title: 'Architecture & Monument Building', type: 'link'
	// 			},
	// 			{
	// 				path: '/art-paintings-books', title: 'Art and Paintings', type: 'link'
	// 			},
	// 			{
	// 				path: '/sports-books', title: 'Sports', type: 'link'
	// 			},
	// 			{
	// 				path: '/historical-books', title: 'Historical', type: 'sub', active: false, children: [
	// 					{ path: '/indian-historical-books', title: 'Indian Historical Books', type: 'link' },
	// 					{ path: '/world-historical-books', title: 'World Historical Books', type: 'link' }
	// 				]
	// 			},
	// 			{
	// 				path: '/royalties-maharajas-books', title: 'Royalties & Maharajas', type: 'link'
	// 			},
	// 			{
	// 				path: '/animals-birds-books', title: 'Animals & Birds', type: 'link'
	// 			},
	// 			{
	// 				path: '/other-books', title: 'Other Books', type: 'link'
	// 			},
	// 		]
	// 	},
	// 	{
	// 		path: '/Below_Rs50', title: 'Below Rs.50', type: 'sub', megaMenu: false, active: false, children: [
	// 			{
	// 				path: '/Stamps_Below_Rs50', title: 'Stamps Below Rs 50', type: 'sub', active: false, children: [
	// 					{ path: '/Indian_Stamps_Below_Rs50', title: 'Indian Stamps', type: 'link' },
	// 					{ path: '/World_Stamps_Below_Rs50', title: 'World Stamps', type: 'link' },
	// 					{ path: '/FDC_Below_Rs50', title: 'First Day Covers', type: 'link' },
	// 					{ path: '/Postal_History_Stationary_Below_Rs50', title: 'Postal History & Stationary', type: 'link' },
	// 					{ path: '/CourtFees_Revenues_Below_Rs50', title: 'Court Fees & Revenues', type: 'link' },
	// 				]
	// 			},
	// 			{
	// 				path: '/Coins_Below_Rs50', title: 'Coins Below Rs 50', type: 'sub', active: false, children: [
	// 					{ path: '/Indian_Coins_Below_Rs50', title: 'Indian Coins', type: 'link' },
	// 					{ path: '/World_Coins_Below_Rs50', title: 'World Coins', type: 'link' },
	// 				]
	// 			},
	// 			{
	// 				path: '/Bank_Notes_Below_Rs50', title: 'Bank Notes Below Rs 50', type: 'sub', active: false, children: [
	// 					{ path: '/Indian_BankNotes_Below_Rs50', title: 'Indian Bank Notes', type: 'link' },
	// 					{ path: '/World_BankNotes_Below_Rs50', title: 'World Bank Notes', type: 'link' },
	// 				]
	// 			},
	// 			{ path: '/Collectables_Below_Rs50', title: 'Collectables Below Rs 50', type: 'link', active: false },
	// 		]
	// 	},

	// 	{path: 'https://auction.collectorbazar.com/OnlineAuction/index', title: 'Auction', type: 'extTabLink', megaMenu: false},
		
	// ];





	LEFTMENUITEMS: Menu[] = [
		{
			title: 'clothing', type: 'sub', megaMenu: false, active: false, children: [
			  {
				  title: 'mens fashion',  type: 'link', active: false, children: [
					  { path: '/home/fashion', title: 'sports wear',  type: 'link' },
					  { path: '/home/fashion', title: 'top',  type: 'link' },
					  { path: '/home/fashion', title: 'bottom',  type: 'link' },
					  { path: '/home/fashion', title: 'ethic wear',  type: 'link' },
					  { path: '/home/fashion', title: 'sports wear',  type: 'link' },
					  { path: '/home/fashion', title: 'shirts',  type: 'link' },
					  { path: '/home/fashion', title: 'bottom',  type: 'link' },
					  { path: '/home/fashion', title: 'ethic wear',  type: 'link' },
					  { path: '/home/fashion', title: 'sports wear',  type: 'link' }
				  ]
			  },
			  {
				  title: 'women fashion',  type: 'link', active: false, children: [
					  { path: '/home/fashion', title: 'dresses',  type: 'link' },
					  { path: '/home/fashion', title: 'skirts',  type: 'link' },
					  { path: '/home/fashion', title: 'westarn wear',  type: 'link' },
					  { path: '/home/fashion', title: 'ethic wear',  type: 'link' },
					  { path: '/home/fashion', title: 'bottom',  type: 'link' },
					  { path: '/home/fashion', title: 'ethic wear',  type: 'link' },
					  { path: '/home/fashion', title: 'sports wear',  type: 'link' },
					  { path: '/home/fashion', title: 'sports wear',  type: 'link' },
					  { path: '/home/fashion', title: 'bottom wear',  type: 'link' }
				  ]
			  },
			]
		},
		{
			title: 'bags', type: 'sub', active: false, children: [
			  { path: '/home/fashion', title: 'shopper bags', type: 'link' },
			  { path: '/home/fashion', title: 'laptop bags', type: 'link' },
			  { path: '/home/fashion', title: 'clutches', type: 'link' },
			  {
				  path: '/home/fashion', title: 'purses', type: 'link', active: false, children: [
					  { path: '/home/fashion', title: 'purses',  type: 'link' },
					  { path: '/home/fashion', title: 'wallets',  type: 'link' },
					  { path: '/home/fashion', title: 'leathers',  type: 'link' },
					  { path: '/home/fashion', title: 'satchels',  type: 'link' }
				  ]
			  },
			]
		},
		{
			title: 'footwear', type: 'sub', active: false, children: [
			  { path: '/home/fashion', title: 'sport shoes', type: 'link' },
			  { path: '/home/fashion', title: 'formal shoes', type: 'link' },
			  { path: '/home/fashion', title: 'casual shoes', type: 'link' }
			]
		},
		{
			path: '/home/fashion', title: 'watches', type: 'link'
		},
		{
			title: 'Accessories', type: 'sub', active: false, children: [
			  { path: '/home/fashion', title: 'fashion jewellery', type: 'link' },
			  { path: '/home/fashion', title: 'caps and hats', type: 'link' },
			  { path: '/home/fashion', title: 'precious jewellery', type: 'link' },
			  {
				  path: '/home/fashion', title: 'more..', type: 'link', active: false, children: [
					  { path: '/home/fashion', title: 'necklaces',  type: 'link' },
					  { path: '/home/fashion', title: 'earrings',  type: 'link' },
					  { path: '/home/fashion', title: 'rings & wrist wear',  type: 'link' },
					  {
						  path: '/home/fashion', title: 'more...',  type: 'link', active: false, children: [
							  { path: '/home/fashion', title: 'ties',  type: 'link' },
							  { path: '/home/fashion', title: 'cufflinks',  type: 'link' },
							  { path: '/home/fashion', title: 'pockets squares',  type: 'link' },
							  { path: '/home/fashion', title: 'helmets',  type: 'link' },
							  { path: '/home/fashion', title: 'scarves',  type: 'link' },
							  {
								  path: '/home/fashion', title: 'more...',  type: 'link', active: false, children: [
									  { path: '/home/fashion', title: 'accessory gift sets',  type: 'link' },
									  { path: '/home/fashion', title: 'travel accessories',  type: 'link' },
									  { path: '/home/fashion', title: 'phone cases',  type: 'link' }
								  ]
							  },
						]
					  }
				  ]
			  },
			]
		},
		{
			path: '/home/fashion', title: 'house of design', type: 'link'
		},
		{
			title: 'beauty & personal care', type: 'sub', active: false, children: [
			  { path: '/home/fashion', title: 'makeup', type: 'link' },
			  { path: '/home/fashion', title: 'skincare', type: 'link' },
			  { path: '/home/fashion', title: 'premium beaty', type: 'link' },
			  {
				  path: '/home/fashion', title: 'more..', type: 'link', active: false, children: [
					  { path: '/home/fashion', title: 'fragrances',  type: 'link' },
					  { path: '/home/fashion', title: 'luxury beauty',  type: 'link' },
					  { path: '/home/fashion', title: 'hair care',  type: 'link' },
					  { path: '/home/fashion', title: 'tools & brushes',  type: 'link' }
				  ]
			  },
			]
		},
		{
			path: '/home/fashion', title: 'home & decor', type: 'link'
		},
		{
			path: '/home/fashion', title: 'kitchen', type: 'link'
		}
	];

	// Array
	items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
	leftMenuItems = new BehaviorSubject<Menu[]>(this.LEFTMENUITEMS);

}
