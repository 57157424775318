import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ShopComponent } from './shop/shop.component';
import { PagesComponent } from './pages/pages.component';
import { ElementsComponent } from './elements/elements.component';


const routes: Routes = [
  // {
  //   path: '',
  //   redirectTo: 'home',
  //   pathMatch: 'full'
  // },
  // {
  //   path: '',
  //   loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
  // },
  // {
  //   path: 'shop',
  //   component: ShopComponent,
  //   loadChildren: () => import('./shop/shop.module').then(m => m.ShopModule)
  // },
  // { 
  //   path: 'pages',
  //   component: PagesComponent,
  //   loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule) 
  // },
  // { 
  //   path: 'elements', 
  //   component: ElementsComponent,
  //   loadChildren: () => import('./elements/elements.module').then(m => m.ElementsModule) 
  // },
  // {
  //   path: '**', // Navigate to Home Page if not found any page
  //   redirectTo: '',
  // },


  // redirect old seo links
  // sellers links
  {path: 'CollectorBazar',redirectTo: '/sellers/CollectorBazar',},
  {path: 'Plan Your Hobby',redirectTo: '/sellers/Plan Your Hobby',},
  {path: 'Newshistory',redirectTo: '/sellers/Newshistory',},
  {path: 'Pawan Jalani',redirectTo: '/sellers/Pawan Jalani',},
  {path: 'samsshopping',redirectTo: '/sellers/samsshopping',},
  {path: 'BUYITNOWITEM',redirectTo: '/sellers/BUYITNOWITEM',},
  {path: 'RoyalPHILATELY',redirectTo: '/sellers/RoyalPHILATELY',},
  {path: 'AntiqueWorld',redirectTo: '/sellers/AntiqueWorld',},
  {path: 'PappuPremCollection',redirectTo: '/sellers/PappuPremCollection',},
  {path: 'jigarjdesai1977',redirectTo: '/sellers/jigarjdesai1977',},
  {path: 'Coingallery',redirectTo: '/sellers/Coingallery',},
  {path: 'Kamal',redirectTo: '/sellers/Kamal',},
  {path: 'Aayushmaan',redirectTo: '/sellers/Aayushmaan',},
  {path: 'Facelift',redirectTo: '/sellers/Facelift',},
  {path: 'Durga',redirectTo: '/sellers/Durga',},
  {path: 'eaglefila',redirectTo: '/sellers/eaglefila',},
  {path: 'philaindiaking',redirectTo: '/sellers/philaindiaking',},
  {path: 'Jaycollections',redirectTo: '/sellers/Jaycollections',},
  {path: 'PradipPhila',redirectTo: '/sellers/PradipPhila',},
  {path: 'SNSCoins',redirectTo: '/sellers/SNSCoins',},
  {path: 'COLLECTIONHUB',redirectTo: '/sellers/COLLECTIONHUB',},
  {path: 'STAMPSQUARE',redirectTo: '/sellers/STAMPSQUARE',},
  {path: 'Antqstore',redirectTo: '/sellers/Antqstore',},
  {path: 'anidotnet',redirectTo: '/sellers/anidotnet',},
  {path: 'Stampdeals',redirectTo: '/sellers/Stampdeals',},
  {path: 'druzba',redirectTo: '/sellers/druzba',},
  {path: 'DhirenShah',redirectTo: '/sellers/DhirenShah',},
  {path: 'JECEE',redirectTo: '/sellers/JECEE',},
  {path: 'Kshitiz',redirectTo: '/sellers/Kshitiz',},
  {path: 'amruta1988',redirectTo: '/sellers/amruta1988',},
  {path: 'stampexindia',redirectTo: '/sellers/stampexindia',},
  {path: 'PhilIndiaStamps',redirectTo: '/sellers/PhilIndiaStamps',},
  {path: 'Philcent2019',redirectTo: '/sellers/Philcent2019',},
  {path: 'Coin_Navneet',redirectTo: '/sellers/Coin_Navneet',},
  {path: 'AggarwalTraders',redirectTo: '/sellers/AggarwalTraders',},
  {path: 'hobbycurrencyworld',redirectTo: '/sellers/hobbycurrencyworld',},
  {path: 'samraatstamps',redirectTo: '/sellers/samraatstamps',},
  {path: 'FarokhSTodywalla',redirectTo: '/sellers/FarokhSTodywalla',},
  {path: 'environsolutions17',redirectTo: '/sellers/environsolutions17',},
  {path: 'mittalstamp',redirectTo: '/sellers/mittalstamp',},
  {path: 'SanjayShah',redirectTo: '/sellers/SanjayShah',},
  {path: 'banknotecoinstamp',redirectTo: '/sellers/banknotecoinstamp',},
  {path: 'Rephila',redirectTo: '/sellers/Rephila',},
  {path: 'rathaphilately',redirectTo: '/sellers/rathaphilately',},
  {path: 'mumbaiphilatelist52',redirectTo: '/sellers/mumbaiphilatelist52',},
  {path: 'WorldwideMint',redirectTo: '/sellers/WorldwideMint',},
  {path: 'Safi',redirectTo: '/sellers/Safi',},
  {path: 'FortuneStamps',redirectTo: '/sellers/FortuneStamps',},
  {path: 'AARVMEDIA',redirectTo: '/sellers/AARVMEDIA',},
  {path: 'indiacollect',redirectTo: '/sellers/indiacollect',},
  {path: 'INDPHILA',redirectTo: '/sellers/INDPHILA',},
  {path: 'ABHI',redirectTo: '/sellers/ABHI',},
  {path: 'Rohansharma',redirectTo: '/sellers/Rohansharma',},
  {path: 'test',redirectTo: '/sellers/test',},
  {path: 'jainamantiques',redirectTo: '/sellers/jainamantiques',},
  {path: 'AntiqueCollections',redirectTo: '/sellers/AntiqueCollections',},
  {path: 'karthik_sss',redirectTo: '/sellers/karthik_sss',},
  {path: 'kABIR',redirectTo: '/sellers/kABIR',},
  {path: 'Vivekphilately',redirectTo: '/sellers/Vivekphilately',},
  {path: 'ArunAgarwal',redirectTo: '/sellers/ArunAgarwal',},
  {path: 'WorldHobbyStore',redirectTo: '/sellers/WorldHobbyStore',},
  {path: 'COLLECTIBLEITEM',redirectTo: '/sellers/COLLECTIBLEITEM',},
  {path: 'Silicon',redirectTo: '/sellers/Silicon',},
  {path: 'kwikphila2020',redirectTo: '/sellers/kwikphila2020',},
  {path: 'shabista_123',redirectTo: '/sellers/shabista_123',},
  {path: 'BlueFalcon',redirectTo: '/sellers/BlueFalcon',},
  {path: 'A2ZKollection',redirectTo: '/sellers/A2ZKollection',},
  {path: 'mumbaiheritage',redirectTo: '/sellers/mumbaiheritage',},
  {path: 'Debu_numis',redirectTo: '/sellers/Debu_numis',},
  {path: 'KAY',redirectTo: '/sellers/KAY',},
  {path: 'devantiques',redirectTo: '/sellers/devantiques',},
  {path: 'pankajpuri786',redirectTo: '/sellers/pankajpuri786',},
  {path: 'Yashvinny',redirectTo: '/sellers/Yashvinny',},
  {path: 'NUMISWORLD',redirectTo: '/sellers/NUMISWORLD',},
  {path: 'SushilMehra',redirectTo: '/sellers/SushilMehra',},
  {path: 'yathika',redirectTo: '/sellers/yathika',},
  {path: 'mfgandmart',redirectTo: '/sellers/mfgandmart',},
  {path: 'MKCOINS',redirectTo: '/sellers/MKCOINS',},
  {path: 'Ramya',redirectTo: '/sellers/Ramya',},
  {path: 'COLLECTWITHKAPOOR',redirectTo: '/sellers/COLLECTWITHKAPOOR',},
  {path: 'KamalThematic',redirectTo: '/sellers/KamalThematic',},
  {path: 'FalconCoinsGallery',redirectTo: '/sellers/FalconCoinsGallery',},
  {path: 'Saigandhi',redirectTo: '/sellers/Saigandhi',},
  {path: 'Moneypedia',redirectTo: '/sellers/Moneypedia',},
  {path: 'ANTIQUARIAN',redirectTo: '/sellers/ANTIQUARIAN',},
  {path: 'CreativePhila',redirectTo: '/sellers/CreativePhila',},
  {path: 'Gscollectionshop',redirectTo: '/sellers/Gscollectionshop',},
  {path: 'lionphila',redirectTo: '/sellers/lionphila',},
  {path: 'Special_Occasion_Notes',redirectTo: '/sellers/Special_Occasion_Notes',},
  {path: 'ehook',redirectTo: '/sellers/ehook',},
  {path: 'SHIVKALI07',redirectTo: '/sellers/SHIVKALI07',},
  {path: 'AssetCreator',redirectTo: '/sellers/AssetCreator',},
  {path: 'KanhaCollections',redirectTo: '/sellers/KanhaCollections',},
  {path: 'DHAWANS',redirectTo: '/sellers/DHAWANS',},
  {path: 'PKKULSHRESTHA',redirectTo: '/sellers/PKKULSHRESTHA',},
  {path: 'Princely_States_of_India',redirectTo: '/sellers/Princely_States_of_India',},
  {path: 'DIVI',redirectTo: '/sellers/DIVI',},
  {path: 'RANG',redirectTo: '/sellers/RANG',},
  {path: 'indiancoinsandnotes',redirectTo: '/sellers/indiancoinsandnotes',},
  {path: 'Pradeep_Kumar_Agrawal',redirectTo: '/sellers/Pradeep_Kumar_Agrawal',},
  {path: 'VJcollectiables',redirectTo: '/sellers/VJcollectiables',},
  {path: 'GEETACOLLECTION',redirectTo: '/sellers/GEETACOLLECTION',},
  {path: 'Bathina_Collections',redirectTo: '/sellers/Bathina_Collections',},
  {path: 'sankhcoin',redirectTo: '/sellers/sankhcoin',},
  {path: 'CurrencyCollector',redirectTo: '/sellers/CurrencyCollector',},
  {path: 'sastampco',redirectTo: '/sellers/sastampco',},
  {path: 'Lav4coins',redirectTo: '/sellers/Lav4coins',},
  {path: 'BrightStamps',redirectTo: '/sellers/BrightStamps',},
  {path: 'Philaworld',redirectTo: '/sellers/Philaworld',},
  {path: 'numisphil',redirectTo: '/sellers/numisphil',},
  {path: 'paccollectibles',redirectTo: '/sellers/paccollectibles',},
  {path: 'IndianHobbyHouse',redirectTo: '/sellers/IndianHobbyHouse',},

  // categories link
  {path: 'stamps',redirectTo: '/categories/stamps',},
  {path: 'coins',redirectTo: '/categories/coins',},
  {path: 'bank-note',redirectTo: '/categories/bank-note',},
  {path: 'collectables',redirectTo: '/categories/collectables',},
  {path: 'accessories',redirectTo: '/categories/accessories',},
  {path: 'indian-stamps',redirectTo: '/categories/indian-stamps',},
  {path: 'other-country',redirectTo: '/categories/other-country',},
  {path: 'fdc-special-covers-brochures',redirectTo: '/categories/fdc-special-covers-brochures',},
  {path: 'fiscals-revenues',redirectTo: '/categories/fiscals-revenues',},
  {path: 'postal-history',redirectTo: '/categories/postal-history',},
  {path: 'post-card-stationery',redirectTo: '/categories/post-card-stationery',},
  {path: 'india-documents',redirectTo: '/categories/india-documents',},
  {path: 'topical-specialty',redirectTo: '/categories/topical-specialty',},
  {path: 'indian-coins',redirectTo: '/categories/indian-coins',},
  {path: 'world-coins',redirectTo: '/categories/world-coins',},
  {path: 'indian-notes',redirectTo: '/categories/indian-notes',},
  {path: 'world-notes',redirectTo: '/categories/world-notes',},
  {path: 'metalware-tins',redirectTo: '/categories/metalware-tins',},
  {path: 'vintage-clocks-watches',redirectTo: '/categories/vintage-clocks-watches',},
  {path: 'art-paintings',redirectTo: '/categories/art-paintings',},
  {path: 'comics',redirectTo: '/categories/comics',},
  {path: 'ephemera',redirectTo: '/categories/ephemera',},
  {path: 'vintage-miniature-toys',redirectTo: '/categories/vintage-miniature-toys',},
  {path: 'pens-writing-instruments',redirectTo: '/categories/pens-writing-instruments',},
  {path: 'playing-cards',redirectTo: '/categories/playing-cards',},
  {path: 'calendar',redirectTo: '/categories/calendar',},
  {path: 'entertainment-memorabilia',redirectTo: '/categories/entertainment-memorabilia',},
  {path: 'autographs',redirectTo: '/categories/autographs',},
  {path: 'movies-films',redirectTo: '/categories/movies-films',},
  {path: 'photographs',redirectTo: '/categories/photographs',},
  {path: 'labels',redirectTo: '/categories/labels',},
  {path: 'music-memorabilia',redirectTo: '/categories/music-memorabilia',},
  {path: 'posters',redirectTo: '/categories/posters',},
  {path: 'maps-atlas-globes',redirectTo: '/categories/maps-atlas-globes',},
  {path: 'souvenirs-novelties-curios',redirectTo: '/categories/souvenirs-novelties-curios',},
  {path: 'statues-artifacts',redirectTo: '/categories/statues-artifacts',},
  {path: 'travel-related-memorabilia',redirectTo: '/categories/travel-related-memorabilia',},
  {path: 'medals-tokens',redirectTo: '/categories/medals-tokens',},
  {path: 'hundis-share-certificate-bonds',redirectTo: '/categories/hundis-share-certificate-bonds',},
  {path: 'sign-boards-and-advertising-items',redirectTo: '/categories/sign-boards-and-advertising-items',},
  {path: 'old-news-papers-books',redirectTo: '/categories/old-news-papers-books',},
  {path: 'airline-items',redirectTo: '/categories/airline-items',},
  {path: 'other-collectable-items',redirectTo: '/categories/other-collectable-items',},
  {path: 'albums',redirectTo: '/categories/albums',},
  {path: 'covers-jackets',redirectTo: '/categories/covers-jackets',},
  {path: 'magnifying-glass',redirectTo: '/categories/magnifying-glass',},
  {path: 'water-mark-reader',redirectTo: '/categories/water-mark-reader',},
  {path: 'other-accessories',redirectTo: '/categories/other-accessories',},
  {path: 'miniature-sheets-stamps',redirectTo: '/categories/miniature-sheets-stamps',},
  {path: 'others-indian-stamps',redirectTo: '/categories/others-indian-stamps',},
  {path: 'us-stamps',redirectTo: '/categories/us-stamps',},
  {path: 'uk-stamps',redirectTo: '/categories/uk-stamps',},
  {path: 'middle-east-stamps',redirectTo: '/categories/middle-east-stamps',},
  {path: 'asia-stamps',redirectTo: '/categories/asia-stamps',},
  {path: 'british-commonwealth-stamps',redirectTo: '/categories/british-commonwealth-stamps',},
  {path: 'europe-stamps',redirectTo: '/categories/europe-stamps',},
  {path: 'others-stamps',redirectTo: '/categories/others-stamps',},
  {path: 'india-fdc-special-covers-brochures',redirectTo: '/categories/india-fdc-special-covers-brochures',},
  {path: 'World FDC & Brochures',redirectTo: '/categories/World FDC & Brochures',},
  {path: 'stamp-paper',redirectTo: '/categories/stamp-paper',},
  {path: 'documents',redirectTo: '/categories/documents',},
  {path: 'court-fee',redirectTo: '/categories/court-fee',},
  {path: 'indian-postal-history',redirectTo: '/categories/indian-postal-history',},
  {path: 'world-postal-history',redirectTo: '/categories/world-postal-history',},
  {path: 'indian-post-card',redirectTo: '/categories/indian-post-card',},
  {path: 'indian-state-post-card',redirectTo: '/categories/indian-state-post-card',},
  {path: 'world-post-card',redirectTo: '/categories/world-post-card',},
  {path: 'pre-independence-india-documents-pre-1947',redirectTo: '/categories/pre-independence-india-documents-pre-1947',},
  {path: 'post-independence-india-documents-post-1947',redirectTo: '/categories/post-independence-india-documents-post-1947',},
  {path: 'cartoons-animation',redirectTo: '/categories/cartoons-animation',},
  {path: 'monument-building-architecture',redirectTo: '/categories/monument-building-architecture',},
  {path: 'cinema-music-entertainment',redirectTo: '/categories/cinema-music-entertainment',},
  {path: 'sports-stamps',redirectTo: '/categories/sports-stamps',},
  {path: 'animals-wild-life',redirectTo: '/categories/animals-wild-life',},
  {path: 'flag-national-emblems',redirectTo: '/categories/flag-national-emblems',},
  {path: 'flora-fauna-nature',redirectTo: '/categories/flora-fauna-nature',},
  {path: 'gandhi-other-personalities',redirectTo: '/categories/gandhi-other-personalities',},
  {path: 'transport-space',redirectTo: '/categories/transport-space',},
  {path: 'british-india-coins-pre-1947-',redirectTo: '/categories/british-india-coins-pre-1947-',},
  {path: 'commemorative-indian-coins-post-1947',redirectTo: '/categories/commemorative-indian-coins-post-1947',},
  {path: 'errors-oddities-indian-coins',redirectTo: '/categories/errors-oddities-indian-coins',},
  {path: 'princely-state-indian-coins',redirectTo: '/categories/princely-state-indian-coins',},
  {path: 'others-indian-coins',redirectTo: '/categories/others-indian-coins',},
  {path: 'asian-coins',redirectTo: '/categories/asian-coins',},
  {path: 'african-coins',redirectTo: '/categories/african-coins',},
  {path: 'australian-coins',redirectTo: '/categories/australian-coins',},
  {path: 'european-coins',redirectTo: '/categories/european-coins',},
  {path: 'errors-and-oddities-coins',redirectTo: '/categories/errors-and-oddities-coins',},
  {path: 'american-coins',redirectTo: '/categories/american-coins',},
  {path: 'other-countries-coins',redirectTo: '/categories/other-countries-coins',},
  {path: 'british-india-pre-1947-bank-notes',redirectTo: '/categories/british-india-pre-1947-bank-notes',},
  {path: 'republic-india-post-1947-bank-notes',redirectTo: '/categories/republic-india-post-1947-bank-notes',},
  {path: 'errors-and-oddities-bank-notes-indian',redirectTo: '/categories/errors-and-oddities-bank-notes-indian',},
  {path: 'princely-state-bank-notes',redirectTo: '/categories/princely-state-bank-notes',},
  {path: 'others-indian-bank-notes',redirectTo: '/categories/others-indian-bank-notes',},
  {path: 'asian-bank-notes',redirectTo: '/categories/asian-bank-notes',},
  {path: 'african-bank-notes',redirectTo: '/categories/african-bank-notes',},
  {path: 'australian-bank-notes',redirectTo: '/categories/australian-bank-notes',},
  {path: 'european-bank-notes',redirectTo: '/categories/european-bank-notes',},
  {path: 'errors-and-oddities-bank-notes',redirectTo: '/categories/errors-and-oddities-bank-notes',},
  {path: 'american-bank-notes',redirectTo: '/categories/american-bank-notes',},
  {path: 'other-countries-bank-notes',redirectTo: '/categories/other-countries-bank-notes',},
  {path: 'bollywood-items',redirectTo: '/categories/bollywood-items',},
  {path: 'hollywood-kollywood-items',redirectTo: '/categories/hollywood-kollywood-items',},
  {path: 'indian-photographs',redirectTo: '/categories/indian-photographs',},
  {path: 'world-photographs',redirectTo: '/categories/world-photographs',},
  {path: 'matchbox-labels',redirectTo: '/categories/matchbox-labels',},
  {path: 'products-labels',redirectTo: '/categories/products-labels',},
  {path: 'patriotic-labels',redirectTo: '/categories/patriotic-labels',},
  {path: 'other-labels',redirectTo: '/categories/other-labels',},
  {path: 'indian-posters',redirectTo: '/categories/indian-posters',},
  {path: 'world-posters',redirectTo: '/categories/world-posters',},
  {path: 'tickets-passes',redirectTo: '/categories/tickets-passes',},
  {path: 'others',redirectTo: '/categories/others',},
  {path: 'medals',redirectTo: '/categories/medals',},
  {path: 'token',redirectTo: '/categories/token',},
  {path: 'hundi',redirectTo: '/categories/hundi',},
  {path: 'share-certificate',redirectTo: '/categories/share-certificate',},
  {path: 'bonds',redirectTo: '/categories/bonds',},
  {path: 'cheques',redirectTo: '/categories/cheques',},
  {path: 'Other-airline-items',redirectTo: '/categories/Other-airline-items',},
  {path: 'air-tickets',redirectTo: '/categories/air-tickets',},
  {path: 'pre-independence-pre-1947-indian-stamps',redirectTo: '/categories/pre-independence-pre-1947-indian-stamps',},
  {path: 'post-independence-post-1947-indian-stamps',redirectTo: '/categories/post-independence-post-1947-indian-stamps',},
  {path: 'indian-stamps-sheets-sheetlets',redirectTo: '/categories/indian-stamps-sheets-sheetlets',},
  {path: 'other-topical-specialty',redirectTo: '/categories/other-topical-specialty',},
  {path: 'other-fiscal-items',redirectTo: '/categories/other-fiscal-items',},
  {path: 'special_events',redirectTo: '/categories/special_events',},
  {path: 'publication',redirectTo: '/categories/publication',},
  {path: 'Philately-del',redirectTo: '/categories/Philately-del',},
  {path: 'Numismatics',redirectTo: '/categories/Numismatics',},
  {path: 'Others-publications',redirectTo: '/categories/Others-publications',},
  {path: 'Book',redirectTo: '/categories/Book',},
  {path: 'Catalogues',redirectTo: '/categories/Catalogues',},
  {path: 'Banking-and-Insurance',redirectTo: '/categories/Banking-and-Insurance',},
  {path: 'Africa',redirectTo: '/categories/Africa',},
  {path: 'first-flight-covers',redirectTo: '/categories/first-flight-covers',},
  {path: 'errors-varieties',redirectTo: '/categories/errors-varieties',},
  {path: 'india-errors-varieties',redirectTo: '/categories/india-errors-varieties',},
  {path: 'world-errors-varieties',redirectTo: '/categories/world-errors-varieties',},
  {path: 'odd-unusuals',redirectTo: '/categories/odd-unusuals',},
  {path: 'india-odd-unusuals',redirectTo: '/categories/india-odd-unusuals',},
  {path: 'world-odd-unusuals',redirectTo: '/categories/world-odd-unusuals',},
  {path: 'definitives-stamps',redirectTo: '/categories/definitives-stamps',},
  {path: 'fancy-numbers',redirectTo: '/categories/fancy-numbers',},
  {path: 'south-american-stamps',redirectTo: '/categories/south-american-stamps',},
  {path: 'prizm-accessories',redirectTo: '/categories/prizm-accessories',},
  {path: 'prizm-album',redirectTo: '/categories/prizm-album',},
  {path: 'prizm-refills',redirectTo: '/categories/prizm-refills',},
  {path: 'prizm-bopp',redirectTo: '/categories/prizm-bopp',},
  {path: 'ancient-coins',redirectTo: '/categories/ancient-coins',},
  {path: 'mughal-sultanate-coins',redirectTo: '/categories/mughal-sultanate-coins',},
  {path: 'standard_issue_coins',redirectTo: '/categories/standard_issue_coins',},
  {path: 'Indian_Bundles',redirectTo: '/categories/Indian_Bundles',},
  {path: 'Special-Occasion-Notes',redirectTo: '/categories/Special-Occasion-Notes',},
  {path: 'Special-Occasion-Covers',redirectTo: '/categories/Special-Occasion-Covers',},
  {path: 'Single-Occasion-Notes',redirectTo: '/categories/Single-Occasion-Notes',},
  {path: 'Single-Occasion-Cover',redirectTo: '/categories/Single-Occasion-Cover',},
  {path: 'Set-Occasion-Notes',redirectTo: '/categories/Set-Occasion-Notes',},
  {path: 'Set-Occasion-Cover',redirectTo: '/categories/Set-Occasion-Cover',},
  {path: 'Below_Rs50',redirectTo: '/categories/Below_Rs50',},
  {path: 'Stamps_Below_Rs50',redirectTo: '/categories/Stamps_Below_Rs50',},
  {path: 'Coins_Below_Rs50',redirectTo: '/categories/Coins_Below_Rs50',},
  {path: 'Bank_Notes_Below_Rs50',redirectTo: '/categories/Bank_Notes_Below_Rs50',},
  {path: 'Collectables_Below_Rs50',redirectTo: '/categories/Collectables_Below_Rs50',},
  {path: 'Indian_Stamps_Below_Rs50',redirectTo: '/categories/Indian_Stamps_Below_Rs50',},
  {path: 'World_Stamps_Below_Rs50',redirectTo: '/categories/World_Stamps_Below_Rs50',},
  {path: 'FDC_Below_Rs50',redirectTo: '/categories/FDC_Below_Rs50',},
  {path: 'Postal_History_Stationary_Below_Rs50',redirectTo: '/categories/Postal_History_Stationary_Below_Rs50',},
  {path: 'Indian_Coins_Below_Rs50',redirectTo: '/categories/Indian_Coins_Below_Rs50',},
  {path: 'World_Coins_Below_Rs50',redirectTo: '/categories/World_Coins_Below_Rs50',},
  {path: 'Indian_BankNotes_Below_Rs50',redirectTo: '/categories/Indian_BankNotes_Below_Rs50',},
  {path: 'World_BankNotes_Below_Rs50',redirectTo: '/categories/World_BankNotes_Below_Rs50',},
  {path: 'Picture-Post-Card',redirectTo: '/categories/Picture-Post-Card',},
  {path: 'CourtFees_Revenues_Below_Rs50',redirectTo: '/categories/CourtFees_Revenues_Below_Rs50',},
  {path: 'british-india-coin-queen-victoria',redirectTo: '/categories/british-india-coin-queen-victoria',},
  {path: 'british-india-coin-king-edward',redirectTo: '/categories/british-india-coin-king-edward',},
  {path: 'british-india-coin-king-george-v',redirectTo: '/categories/british-india-coin-king-george-v',},
  {path: 'british-india-coin-king-george-vi',redirectTo: '/categories/british-india-coin-king-george-vi',},
  {path: 'british-india-coin-king-william',redirectTo: '/categories/british-india-coin-king-william',},
  {path: 'unc-proof-sets',redirectTo: '/categories/unc-proof-sets',},
  {path: 'india-portuguese-coins',redirectTo: '/categories/india-portuguese-coins',},
  {path: 'indian-pre-post-card',redirectTo: '/categories/indian-pre-post-card',},
  {path: 'indian-post-post-card',redirectTo: '/categories/indian-post-post-card',},
  {path: 'bazar-post-card',redirectTo: '/categories/bazar-post-card',},
  {path: 'advertisement-post-card',redirectTo: '/categories/advertisement-post-card',},
  {path: 'Se-tenant-tete-beches',redirectTo: '/categories/Se-tenant-tete-beches',},
  {path: 'pre-independence-states-indian-stamps',redirectTo: '/categories/pre-independence-states-indian-stamps',},
  {path: 'crest-covers',redirectTo: '/categories/crest-covers',},
  {path: 'special-covers-maxim-event-cover',redirectTo: '/categories/special-covers-maxim-event-cover',},
  {path: 'india-special-covers-maxim-event-cover',redirectTo: '/categories/india-special-covers-maxim-event-cover',},
  {path: 'world-special-covers-maxim-event-cover',redirectTo: '/categories/world-special-covers-maxim-event-cover',},
  {path: 'Colonial-Presidency-Coins',redirectTo: '/categories/Colonial-Presidency-Coins',},
  {path: 'Books',redirectTo: '/categories/Books',},
  {path: 'numismatic',redirectTo: '/categories/numismatic',},
  {path: 'philately',redirectTo: '/categories/philately',},
  {path: 'Phila-Dump',redirectTo: '/categories/Phila-Dump',},
  {path: 'Numis-Dump',redirectTo: '/categories/Numis-Dump',},
  {path: 'Misc-Dump',redirectTo: '/categories/Misc-Dump',},
  {path: 'architecture-monument-books',redirectTo: '/categories/architecture-monument-books',},
  {path: 'art-paintings-books',redirectTo: '/categories/art-paintings-books',},
  {path: 'sports-books',redirectTo: '/categories/sports-books',},
  {path: 'animals-birds-books',redirectTo: '/categories/animals-birds-books',},
  {path: 'other-books',redirectTo: '/categories/other-books',},
  {path: 'historical-books',redirectTo: '/categories/historical-books',},
  {path: 'indian-historical-books',redirectTo: '/categories/indian-historical-books',},
  {path: 'world-historical-books',redirectTo: '/categories/world-historical-books',},
  {path: 'philately-books',redirectTo: '/categories/philately-books',},
  {path: 'philately-journals',redirectTo: '/categories/philately-journals',},
  {path: 'numismatic-books',redirectTo: '/categories/numismatic-books',},
  {path: 'numismatic-catalogue',redirectTo: '/categories/numismatic-catalogue',},
  {path: 'numismatic-journals',redirectTo: '/categories/numismatic-journals',},
  {path: 'philately-catalogue',redirectTo: '/categories/philately-catalogue',},
  {path: 'categories/india-portuguese-coins',redirectTo: '/categories/colonial-presidency-coins',},
  {path: 'india-portuguese-coins',redirectTo: '/categories/colonial-presidency-coins',},
  {path: 'index', redirectTo: '',},
  {path: 'home', redirectTo: '',},
  {path: 'seller', redirectTo: '/sellers-shop', pathMatch: 'full'},
  {path: 'sellers', redirectTo: '/sellers-shop', pathMatch: 'full'},


  {path: 'exhibitions.php',redirectTo: '/resources/exhibitions'},
  {path: 'knowledge.php',redirectTo: '/resources/knowledge'},
  {path: 'my_collections.php',redirectTo: '/resources/my_collections'},
  {path: 'Exhibits/exhibits.php',redirectTo: '/resources/exhibits'},

  {path: 'collectionhub',redirectTo: '/sellers/COLLECTIONHUB'},



  {
    path: '',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
  },
  {
    path: '',
    component: ShopComponent,
    loadChildren: () => import('./shop/shop.module').then(m => m.ShopModule)
  },
  { 
    path: '',
    component: PagesComponent,
    loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule) 
  },
  { 
    path: '', 
    component: ElementsComponent,
    loadChildren: () => import('./elements/elements.module').then(m => m.ElementsModule) 
  },
  {
    path: '**', // Navigate to Home Page if not found any page
    redirectTo: '404',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledNonBlocking',
      useHash: false,
      anchorScrolling: 'enabled',
      scrollPositionRestoration: 'enabled',
      // relativeLinkResolution: 'legacy'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
