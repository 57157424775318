import { Component, OnInit, Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ProductService } from "../../services/products.service";
import { Products } from "../../classes/product";
import { ActivatedRoute, ParamMap } from '@angular/router';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  public CustomerID = this.productService.getCustomerID();
  public products: Products[] = [];
  public search: boolean = false;
  public isActiveSearch: boolean = false;
  public search_input_sq: string = "";
  public search_input_cat: string = "All";
  public searchSuggestionList : any[] = [];
  public cart_count = 0;
  public languages = [{ 
    name: 'English',
    code: 'en'
  }];

  // public currencies = [{
  //   name: 'Euro',
  //   currency: 'EUR',
  //   price: 90 // price of euro
  // }, {
  //   name: 'Rupees',
  //   currency: 'INR',
  //   price: 1 // price of inr
  // }, {
  //   name: 'Pound',
  //   currency: 'GBP',
  //   price: 78 // price of euro
  // }, {
  //   name: 'Dollar',
  //   currency: 'USD',
  //   price: 70 // price of usd
  // }];
  
  public categories = [{
    id: '0',
    name: 'All'
  }, {
    id: '1',
    name: 'Stamps'
  }, {
    id: '2',
    name: 'Coins'
  }, {
    id: '3',
    name: 'Bank Note'
  }, {
    id: '4',
    name: 'Collectables'
  }, {
    id: '5',
    name: 'Accessories'
  }, {
    id: '202',
    name: 'Books'
  }, {
    id: '169',
    name: 'Below Rs 50'
  }];

  constructor(@Inject(PLATFORM_ID) private platformId: Object,private translate: TranslateService,public productService: ProductService) {
    // this.productService.cartItems.subscribe(response => this.products = response);
    // this.products.forEach(element => {
    //   if(element.articleno == null) {
    //     this.productService.removeCartItem(element);
    //   }
    // });

    this.productService.getAllCartData(this.CustomerID).subscribe((jsonCartDataResponse: any) => {
      if(jsonCartDataResponse.status == true) {
        let CartData = jsonCartDataResponse.result;
        this.cart_count = CartData.length;
        this.products = CartData;
        this.products.forEach(element => {
          if(element.articleno == null) {
            this.productService.removeCartItem(element);
          }
        });
      }
    });
  }

  ngOnInit(): void {
    var full_url = window.location.href;
    var url_array = full_url.split('/');
    var arr_last_length_value = url_array.length - 1;
    var arr_page_length_value = url_array.length - 3;
    var is_search_page = decodeURIComponent(url_array[arr_page_length_value]);
    if(is_search_page == "search") {
      this.search_input_sq = decodeURIComponent(this.productService.jsDecode(decodeURIComponent(url_array[arr_last_length_value])));
    }
  }

  getThumbnailImagePathLink(name: string) {
    return this.productService.getThumbnailImagePathLink(name);
  }
  getActualImagePathLink(name: string) {
    return this.productService.getActualImagePathLink(name);
  }
  
  searchToggle(){
    this.isActiveSearch = !this.isActiveSearch;
    this.search = !this.search;
  }

  changeLanguage(code){
    if (isPlatformBrowser(this.platformId)) {
      this.translate.use(code)
    }
  }

  get getTotal(): Observable<number> {
    return this.productService.cartTotalAmount();
  }

  removeItem(product: any) {
    this.productService.removeCartItem(product);
  }

  changeCurrency(currency: any) {
    this.productService.Currency = currency
  }

  // jsEncode(param: string){
  //   return encodeURIComponent(param);
  // }

  searchThisKeywords() {
    if(this.search_input_sq.length > 0) {
      var origin_text = this.search_input_sq;
      this.search_input_sq = this.search_input_sq;
      var CustomerID = this.productService.getCustomerID();
      let saveCustomerSearchInputs = {
        customerId: CustomerID,
        SearchCategory: this.search_input_cat,
        SearchText: this.search_input_sq
      };
      var encoded_text = this.productService.jsEncode(this.search_input_sq);
      // console.log("encoded_text=", encoded_text);
      this.productService.saveCustomerSearchData(saveCustomerSearchInputs).subscribe((jsonResponse: any) => {
        this.productService.getSearchLinkData(this.search_input_sq).subscribe((jsonResponse: any) => {
          if(jsonResponse.status == true) {
            var SearchLinkData = jsonResponse.result[0];
            // console.log(SearchLinkData);
            window.location.href = SearchLinkData.RedirectedUrl;
            // if(PageType == "seller") {
            //   window.location.href = '/search/'+this.search_input_cat+'/'+this.search_input_sq;
            // }
            // else if(PageType == "category") {
  
            // }
            // else {
  
            // }
          }
          else {
            window.location.href = '/search/'+this.search_input_cat+'/'+encoded_text;
          }
        },(error: any) => {
          console.log(error);
          window.location.href = '/search/'+this.search_input_cat+'/'+encoded_text;
        });
      },(error: any) => {
        console.log(error);
        window.location.href = '/search/'+this.search_input_cat+'/'+encoded_text;
      });
    }
  }

  onSearchChange(e: any)
  {
    // console.log(e.target.value);
    if(e.target.value != "")
    {
      var search_sq = encodeURIComponent(String(e.target.value));
      this.productService.showMainSearchSuggestion(this.search_input_cat, this.search_input_sq).subscribe((response: any) => {
        // console.log(response);
        this.searchSuggestionList = response.result;
      });
    }
  }
}
