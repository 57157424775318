<!-- breadcrumb start -->
<div class="breadcrumb-section">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-8">
        <div class="page-title">
          <p [innerHTML]="title"></p>
          <!-- <h2 [innerHTML]="title"></h2> -->
        </div>
      </div>
      <div class="col-sm-4">
        <nav aria-label="breadcrumb" class="theme-breadcrumb">
          <ol class="breadcrumb" style="padding: 4px 2px;">
            <li class="breadcrumb-item"><a [routerLink]="'/'">Home</a></li>
            <li class="breadcrumb-item active" aria-current="page">{{ breadcrumb }}</li>
          </ol>
        </nav>
      </div>
    </div>
  </div>
</div>
<!-- breadcrumb End -->